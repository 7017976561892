/**
 * Converts a date string to the format DD/MM/YYYY
 * @param {string} dateString - The date string to convert
 * @returns {string} The formatted date string
 */
export const formatDateToDayMonthYearString = (dateString: string): string => {
  const date = new Date(dateString+'T00:00:00');
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();

  if (!(day && month && year)) return dateString;
  return `${day}/${month}/${year}`;
};

export const formatDateToTableFormat = (isoDateStr: string) => {
  const date = new Date(isoDateStr);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = date.getHours() < 12 ? 'AM' : 'PM';
  return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
};
