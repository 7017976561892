import React from 'react';
import DefaultNavbar from 'components/Navbar';
import RegulationsFiles from './RegulationsFile';
import KeepMeInformed from 'components/home/KeepMeInformed/index';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import DefaultFooter from 'components/Footer';
import styles from './Regulations.module.scss';
import { regulations } from './regulations.constants';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { GetRegulation } from './regulation.api';
import { useQuery } from '@tanstack/react-query';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { BsGrid3X3Gap } from 'react-icons/bs';
import RegulationsPdfSection from './RegulationPdfSection';
import { FiDownload } from 'react-icons/fi';

const Tab = styled(TabUnstyled)`
  cursor: pointer;
  padding: 12px 16px 12px 0px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid;

  &:hover {
    font-family: 'themixarabicBold', sans-serif;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 767px) {
    text-align: center;
    padding: 9px 20px;
    border: solid 1px #bbb;
    font-family: 'themixarabicPlain', sans-serif;
    border-radius: 45px;
    font-size: 16px;
    margin-right: 15px;
    margin-bottom: 12px;
    text-transform: none;
    width: auto;
    letter-spacing: 0;
    box-shadow: none;
    color: #000;
  }

  &.${tabUnstyledClasses.selected} {
    color: #48a2b8;
    display: flex;

    font-family: 'themixarabicBold', sans-serif;

    @media (max-width: 767px) {
      border-color: #2d6a80;
      background: #2d6a80;
      color: #fff;
    }
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(TabsListUnstyled)`
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
`;

export default function Regulations() {
  const { t, i18n } = useTranslation();
  const [iDNumber, setIDNumber] = useState('');
  const [listView, setListView] = React.useState(false);

  React.useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);

  const { data } = useQuery(
    [
      'regulations',
      {
        locale: i18n.language,
      },
    ],
    () => GetRegulation(i18n.language),
    { keepPreviousData: true },
  );

  const handleMediaClicked = (item) => {
    setIDNumber(item);
    console.log('ID', iDNumber);
  };

  return (
    <>
      <DefaultNavbar />
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <h1 className="page_title">{t('REGULATIONS')}</h1>
          <div className={`${styles.mediaCenterContent}`}>
            <TabsUnstyled
              orientation="vertical"
              className={`${styles.mediaContain} `}>
              <div className={`${styles.sideBar}`}>
                <TabsList>
                  <Tab
                    onClick={() => {
                      handleMediaClicked('');
                    }}>
                    {t('All regulations')}
                  </Tab>
                  {data?.data?.map((item) => {
                    return (
                      <Tab
                        key={item.attributes?.IDNumber}
                        value={item.attributes?.IDNumber}
                        role={'tabpanel'}
                        style={{ textAlign: 'start' }}
                        onClick={() => {
                          handleMediaClicked(item.attributes?.IDNumber);
                        }}>
                        {item.attributes?.Title}
                      </Tab>
                    );
                  })}
                </TabsList>
              </div>
              <div className={`${styles.sideBarTwo}`}>
                <div className={`${styles.regulationHeader}`}>
                  {data?.data?.map((item) => {
                    if (item?.attributes?.IDNumber === iDNumber)
                      return <h2> {item.attributes?.Title}</h2>;
                  })}
                  <div className={styles.regIconWrap}></div>
                </div>
                {iDNumber === '' && (
                  <div className={`${styles.tabpdf}`}>
                    {t(
                      'Everything you need to know about the new regulations is listed below',
                    )}
                  </div>
                )}
                {data?.data?.map((item) => {
                  if (item?.attributes?.IDNumber === iDNumber)
                    return (
                      <div className={`${styles.tabpdf}`}>
                        {item.attributes?.Description}
                      </div>
                    );
                })}
                <div>
                  {data?.data?.map((item) => {
                    if (item?.attributes?.IDNumber === iDNumber)
                      if (!listView) {
                        return (
                          <RegulationsPdfSection
                            heading={item.attributes?.MediaAssets?.[0]?.Title}
                            source={
                              item.attributes?.MediaAssets?.[0]?.Document?.data
                                ?.attributes?.url
                            }
                          />
                        );
                      } else if (listView) {
                        return (
                          <table className={styles.regTable}>
                            <thead>
                              <tr>
                                <th scope="col">{t('Document Name')}</th>
                                <th scope="col">{t('Release Date')}</th>
                                <th scope="col">{t('Type')}</th>
                                <th scope="col">{t('Download')}</th>
                              </tr>
                              <tr>
                                <td>
                                  {item.attributes?.MediaAssets?.[0]?.Title}
                                </td>
                                <td>{item.attributes?.PublishDate}</td>
                                <td>PDF</td>
                                <td>
                                  {' '}
                                  <a
                                    className={styles.pdfDownloadIcon}
                                    href={
                                      item.attributes?.MediaAssets?.[0]
                                        ?.Document?.data?.attributes?.url
                                    }
                                    target="_blank">
                                    <FiDownload />
                                  </a>
                                </td>
                              </tr>
                            </thead>
                          </table>
                        );
                      }
                  })}
                  {console.log(data)}
                  <div className={styles.pdfSectionContainer}>
                    {iDNumber === '' &&
                      data?.data?.map((item) => {
                        return (
                          <RegulationsPdfSection
                            heading={item.attributes?.MediaAssets?.[0]?.Title}
                            source={
                              item.attributes?.MediaAssets?.[0]?.Document?.data
                                ?.attributes?.url
                            }
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
              <div>
                {data?.data?.map((item) => {
                  if (item?.attributes?.IDNumber === iDNumber)
                    return (
                      <div>
                        <div className={styles.regIconWrap}>
                          <AiOutlineUnorderedList
                            className={
                              styles.regIcon + ' ' + styles.regIconList
                            }
                            onClick={() => setListView(true)}
                          />
                          <BsGrid3X3Gap
                            className={styles.regIcon}
                            onClick={() => setListView(false)}
                          />
                        </div>
                        <div className={`${styles.tabContent}`}>
                          {item.attributes?.PublishDate}
                        </div>
                      </div>
                    );
                })}
              </div>
            </TabsUnstyled>
          </div>
        </div>
      </div>
      {/* <KeepMeInformed /> */}
      <Information />
      <Subscribe />
      <DefaultFooter />
    </>
  );
}
