import styles from './Information.module.scss';
import { useTranslation } from 'react-i18next';

import facebook from './../../../../src/assets/img/icons/facebook.svg';
import linkedin from './../../../../src/assets/img/icons/linkedin.svg';
import twitter from './../../../../src/assets/img/icons/twitter.svg';
import instagram from './../../../../src/assets/img/icons/instagram.svg';
import youtube from './../../../../src/assets/img/icons/youtube.svg';
import tiktok from './../../../../src/assets/img/icons/tiktok.svg';

export default function SocialLinks() {
  const { t } = useTranslation();
  return (
    <div className={styles.infoTilesSocial}>
      <div className={styles.infoTile}>
        <h3>{t('Follow Mawani')}</h3>
        <div className={styles.infoSocial}>
          <a href="https://www.facebook.com/MawaniKSA" target="_blank">
            <img src={facebook} alt="" height={'24px'} />
          </a>
          <a href="https://www.linkedin.com/company/mawaniksa/" target="_blank">
            <img src={linkedin} alt="" height={'23px'} />
          </a>
          <a href="https://twitter.com/MawaniKSA" target="_blank">
            <img src={twitter} className="mt-1" alt="" height={'19px'} />
          </a>
          <a href="https://www.instagram.com/MawaniKSA/" target="_blank">
            <img src={instagram} className="mt-1" alt="" height={'24px'} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCHe5NmEINRO4XUL25z-ch8w"
            target="_blank">
            <img src={youtube} className="mt-1" alt="" width={'30px'} />
          </a>
          <a href="https://www.tiktok.com/@mawani.ksa" target="_blank">
            <img src={tiktok} className="mt-1" alt="" width={'22px'} />
          </a>
        </div>
      </div>
    </div>
  );
}
