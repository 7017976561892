import styles from './Regulations.module.scss';

// todo: English translations for title and description not final
export const regulations = [
  {
    name: 'All',
    nameAR: 'جميع اللوائح',
    control: 'all',
    description: null,
    descriptionAr: null,
  },

  {
    name: 'Control and Inspection of Saudi Ships',
    nameAR: 'التعليمات التنظيمية لإجراءات الرقابة والتفتيش على السفن السعودية',
    description:
      'Based on the approval of the Prime Minister by telegram No. (7/b/17161) dated 12/16/1421 AH that the General Authority for Ports carry out maritime inspection and control of all ships in the port in accordance with international rules or any other rules established by the state, these instructions and their appendices have been prepared for the procedures Control and inspection of Saudi ships in the ports, taking into account all the executive regulations of the international agreements and treaties issued by the International Maritime Organization and organized by the Kingdom and issued by the Maritime Authority, and the rules and instructions of the sea ports of the countries of the Cooperation Council for the Arab Gulf States.',
    descriptionAr:
      'بناءً على موافقة رئيس مجلس الوزراء بالبرقية رقم ( 7 / ب /17161) وتاريخ  16 / 12 / 1421هـ على قيام الهيئة العامة للموانئ بالتفتيش البحري والرقابة على جميع السفن بالميناء وفقا للقواعد الدولية أو أي قواعد أخرى تضعها الدولة، فقد تم إعداد هذه التعليمات وملحقاتها لإجراءات الرقابة والتفتيش على السفن السعودية في الموانئ، مع الأخذ بالاعتبار جميع اللوائح التنفيذية للاتفاقيات والمعاهدات الدولية الصادرة من المنظمة البحرية الدولية والمنظمة لها المملكة والصادرة من السلطة البحرية وقواعد وتعليمات الموانئ البحرية لدول مجلس التعاون لدول الخليج العربي.',
    // background: activeBtn.controlAndInseptionOfSaudiShips,
    // color:activeBtn.controlAndInseptionOfSaudiShips,
    // border:activeBtn.controlAndInseptionOfSaudiShips,
    control: 'controlAndInseptionOfSaudiShips',
  },

  {
    name: 'General Regulation of Licensing',
    nameAR: 'اللائحة العامة للتراخيص والتصاريح',
    description:
      'The General Regulations for Licenses and Permits govern the activities that fall within the competence of the General Authority for Ports, according to which the authority grants licenses to persons of natural or legal capacity who benefit from port operation services, based on the regulation of the General Authority for Ports issued by Cabinet Resolution No. (299) dated 11/6/ 1439 AH.',
    descriptionAr:
      'تحكم اللائحة العامة للتراخيص والتصاريح الأنشطة التي تندرج ضمن اختصاصات الهيئة العامة للموانئ، وتمنح الهيئة بموجبها التراخيص للأشخاص ذوو الصفة الطبيعية أو الاعتبارية المستفيدين من خدمات تشغيل الموانئ، وذلك بناء على تنظيم الهيئة العامة للموانئ الصادر بقرار مجلس الوزراء رقم (299) وتاريخ 11/6/1439هـ.',
    dataAR:
      '<br/><p>بناء علي تنظيم الهيئة العامة للموانئ الصادر بقرار مجلس الوزراء رقم (399) وتاريخ 11 هـ ، الذي أعطى للهيئة صلاحية إعداد لائحة التراخيص للأشخاص ذوو الصفة الطبيعية أو الإعتبارية المستفيدين من خدمات تشغيل الموانئ واعتمادها من قبل مجلس ادارة الهيئة ، فقد تم إعداد هذه اللائحة والقواعد التي تحكم الأنشطة التي تندرج ضمن اختصاصات الهيئة.</p>',
    // background: activeBtn.generalRegulationOfLicensing,
    // color:activeBtn.generalRegulationOfLicensing,
    // border:activeBtn.generalRegulationOfLicensing,
    control: 'generalRegulationOfLicensing',
  },
  {
    name: 'Regulation for Ship Chandlers (except for all types of fuel)',
    nameAR: 'اللائحة التنظيمية لمموني السفن (ما عدا الوقود بجميع أشكاله)',
    description:
      'The regulation of ship suppliers (except for fuel in all its forms) governs all ship supply business except for fuel to regulate and ration it to ensure that the activity is practiced efficiently and with high quality, based on the general regulations for licenses issued by the decision of the Board of Directors of the General Authority for Ports No. (5/C1/D1) dated 10 3/1440 AH.',
    descriptionAr:
      'تحكم اللائحة التنظيمية لمموني السفن (ما عدا الوقود بجميع أشكاله) جميع أعمال تموين السفن باستثناء الوقود لتنظيمها وتقنينها لضمان مزاولة النشاط بكفاءة وجودة عالية، وذلك بناءً على اللائحة العامة للتراخيص الصادرة بقرار مجلس إدارة الهيئة العامة للموانئ رقم (5/ج1/د1) وتاريخ 10/3/1440هـ.',
    // background: activeBtn.regulationForShipChandler,
    // color:activeBtn.regulationForShipChandler,
    // border:activeBtn.regulationForShipChandler,
    control: 'regulationForShipChandler',
  },

  {
    name: 'Regulations For Shipping Agents',
    nameAR: 'لائحة مزاولة نشاط الوكلاء الملاحيين',
    description:
      'The regulation of practicing the activity of shipping agents regulates all activities of shipping agents to ensure that the activity is practiced in accordance with the highest standards of quality and efficiency, based on the general regulations for licenses and permits issued by the decision of the Board of Directors of the General Authority for Ports No. (143/C15/D2) dated (07/11/1443 AH). This regulation is complementary to the responsibilities contained in the Commercial Maritime Law issued by Royal Decree No. (M/33) dated 5/4/1440 AH.',
    descriptionAr:
      'تنظم لائحة مزاولة نشاط الوكلاء الملاحيين جميع أنشطة الوكلاء الملاحيين لضمان مزاولة النشاط وفق أعلى معايير الجودة والكفاءة، وذلك بناءً على اللائحة العامة للتراخيص والتصاريح الصادرة بقرار مجلس إدارة الهيئة العامة للموانئ رقم ­­­­(143/ج15/د2) وتاريخ (07/11/1443هـ، وتعتبر هذه اللائحة مكملة للمسؤوليات الواردة بالنظام البحري التجاري الصادر بالمرسوم الملكي رقم (م/33) بتاريخ 5/4/1440هـ.',
    // background: activeBtn.regulationsForShippingAgents,
    // color:activeBtn.regulationsForShippingAgents,
    // border:activeBtn.regulationsForShippingAgents,
    control: 'regulationsForShippingAgents',
  },

  {
    name: 'Regulations for rental of facilities',
    nameAR: 'لائحة تأجير أملاك الهيئة العامة الموانئ',
    description:
      'The General Authority for Ports’ property rental regulation regulates the procedures for leasing the property of the General Authority for Ports, investing its real estate and properties in value-added projects, comparing applications, unifying implementation mechanisms, and providing a reference that guarantees transparency and clarity of the authority’s property rental procedures, based on the regulation of the General Authority for Ports issued by Cabinet Resolution No. (299) and dated 6/11/1439 AH.',
    descriptionAr:
      'تنظم لائحة تأجير أملاك الهيئة العامة الموانئ إجراءات تأجير أملاك الهيئة العامة للموانئ واستثمار عقاراتها وممتلكاتها في مشاريع ذات قيمة مضافة، والمفاضلة بين الطلبات وتوحيد آليات التنفيذ وتوفير مرجعية تضمن شفافية ووضوح إجراءات تأجير املاك الهيئة، وذلك بناءً على تنظيم الهيئة العامة للموانئ الصادر بقرار مجلس الوزراء رقم (299) وتاريخ 11/ 6/1439 هـ.',
    // background: activeBtn.regulationsForRentalOfFacilities,
    // color:activeBtn.regulationsForRentalOfFacilities,
    // border:activeBtn.regulationsForRentalOfFacilities,
    control: 'regulationsForRentalOfFacilities',
  },

  {
    name: 'Port Services Fees and Charges',
    nameAR: 'نظام رسوم وأجور خدمات الموانئ',
    description:
      'The system of fees and charges for port services issued by Royal Decree (M / 42) dated 02/07/1407 AH includes a list of fees and charges that are collected from the owner of the ship or its agent, and a list of fees that are collected from the owner of the goods, in addition to a schedule of fees for services of transit and distance goods. and fees for additional services.',
    descriptionAr:
      'يتضمن نظام رسوم وأجور خدمات الموانئ الصادر بالمرسوم الملكي (م/42) بتاريخ 02/07/1407هـ، قائمة الرسوم والأجور التي يتم تحصيلها من مالك السفينة أو وكيلها، وقائمة الأجور التي تحصل من صاحب البضاعة، إضافة إلى جدول أجور خدمات بضائع الترانزيت والمسافة، وأجور الخدمات الإضافية.',
    // background: activeBtn.portServicesFeesandCharges,
    // color:activeBtn.portServicesFeesandCharges,
    // border:activeBtn.portServicesFeesandCharges,
    control: 'portServicesFeesandCharges',
  },
  {
    name: 'Control and Inspection of Foreign Ships',
    nameAR: 'التعليمات التنظيمية لإجراءات الرقابة والتفتيش على السفن الأجنبية',
    description:
      'Based on the approval of the Prime Minister by telegram No. (7/b/17161) dated 12/16/1421 AH that the General Authority for Ports carry out marine inspection and control of all ships in the port in accordance with international rules or any other rules established by the state, and based on Cabinet Resolution No. ( 52) On 23/2/1423 AH, which includes the General Authority for Ports informing the General Secretariat of the Cooperation Council for the Arab States of the Gulf of the Kingdom’s position in support of the Riyadh draft memorandum of understanding regarding state control of ships for the countries bordering the Arabian Gulf. These regulatory instructions have been prepared for the control and inspection procedures on foreign ships.',
    descriptionAr:
      'بناءً على موافقة رئيس مجلس الوزراء بالبرقية رقم ( 7 / ب /17161) وتاريخ  16 / 12 / 1421هـ على قيام الهيئة العامة للموانئ بالتفتيش البحري والرقابة على جميع السفن بالميناء وفقا للقواعد الدولية أو أي قواعد أخرى تضعها الدولة، وبناءً على قرار مجلس الوزراء رقم (52)  بتاريخ 23 / 2 / 1423هـ ، المتضمن قيام الهيئة العامة للموانئ بإبلاغ الأمانة العامة لمجلس التعاون لدول الخليج العربية بموقف المملكة المؤيد لمشروع مذكرة تفاهم الرياض الخاصة برقابة الدولة على السفن للدول المطلة على الخليج العربي ، فقد تم إعداد هذه التعليمات التنظيمية لإجراءات الرقابة والتفتيش على السفن الأجنبية .',
    // background: activeBtn.controlAndInseptionOfSaudiShips,
    // color:activeBtn.controlAndInseptionOfForeignShips,
    // border:activeBtn.controlAndInseptionOfForeignShips,
    control: 'controlAndInseptionOfForeignShips',
  },
  {
    name: 'General Regulation for Control and Inspection',
    nameAR: 'اللائحة العامة للرقابة والتفتيش',
    description:
      'The General Regulation of Control and Inspection regulates the control and inspection of licensed activities that fall within the competence of the General Authority for Ports to ensure the provision of works in accordance with the highest standards of quality and efficiency, based on the General Regulations for Licenses issued by the decision of the Board of Directors of the General Authority for Ports No. (5/C1/D1) dated 10/3/1440 AH',
    descriptionAr:
      'تنظم اللائحة العامة للرقابة والتفتيش أعمال الرقابة والتفتيش على الأنشطة المرخص لها والي تندرج ضمن اختصاصات الهيئة العامة للموانئ لضمان تقديم الأعمال وفق أعلى معايير الجودة والكفاءة، وذلك بناء على اللائحة العامة للتراخيص الصادرة بقرار مجلس إدارة الهيئة العامة للموانئ رقم (5/ج1/د1) وتاريخ 10/3/1440هـ',
    // background: activeBtn.generalRegulationForControl,
    // color:activeBtn.generalRegulationForControl,
    // border:activeBtn.generalRegulationForControl,
    control: 'generalRegulationForControl',
  },
  {
    name: 'General regulations for security, safety, health and the environment',
    nameAR: 'اللائحة العامة للأمن والسلامة والصحة والبيئة',
    description:
      'The general regulations for security, safety, health, and the environment determine the rules and standards for the use, management and operation of ports and the adoption of safety, safety and environmental standards, with the aim of providing a safe and risk-free work environment, based on the regulation of the General Authority for Ports issued by Cabinet Resolution No. (299) dated 6/11/1439 AH.',
    descriptionAr:
      'تحدد اللائحة العامة للأمن والسلامة والصحة والبيئة والقواعد والمعايير الخاصة باستخدام الموانئ وإدارتها وتشغيلها واعتماد معايير الأمان والسلامة والبيئة، بهدف توفير بيئة عمل آمنة وخالية من المخاطر، وذلك بناء على  تنظيم الهيئة العامة للموانئ الصادر بقرار مجلس الوزراء رقم (299) وتاريخ 11/6/1439هـ.',
    // background: activeBtn.generalRegulationForControl,
    // color:activeBtn.generalRegulationForControl,
    // border:activeBtn.generalRegulationForControl,
    control: 'generalRegulationForSecuritySafetyHealthAndEnvironment',
  },
  {
    name: 'Security Safety Environment Standards',
    nameAR: '',
    description: '',
    descriptionAr: '',
    // background: activeBtn.generalRegulationForControl,
    // color:activeBtn.generalRegulationForControl,
    // border:activeBtn.generalRegulationForControl,
    control: 'generalRegulationForSecuritySafetyEnvironmentStandards',
  },
  {
    name: 'Operational regulations of the General Authority for Ports',
    nameAR: 'اللائحة التشغيلية للهيئة العامة للموانئ',
    description: '',
    descriptionAr:
      'كل ما تحتاج معرفته حول اللائحة التشغيلية للهيئة العامة للموانئ',
    // background: activeBtn.generalRegulationForControl,
    // color:activeBtn.generalRegulationForControl,
    // border:activeBtn.generalRegulationForControl,
    control:
      'generalRegulationForOperationalregulationsoftheGeneralAuthorityforPorts',
  },
  {
    name: 'Executive Procedures Manual for Security, Safety, Health and Environment Standards',
    nameAR: 'دليل الاجراءات التنفيذية لمعايير الأمن والسلامة والصحة والبيئة',
    description: '',
    descriptionAr:
      'كل ما تحتاج معرفته حول دليل الاجراءات التنفيذية لمعايير الأمن والسلامة والصحة والبيئة',
    // background: activeBtn.generalRegulationForControl,
    // color:activeBtn.generalRegulationForControl,
    // border:activeBtn.generalRegulationForControl,
    control:
      'generalRegulationForExecutiveProceduresManualforSecuritySafetyHealthandEnvironmentStandards',
  },
  {
    name: 'Rules and Regulations for Seaports for The Cooperatlon Council for The Arab states of The Gulf',
    nameAR:
      'قواعد وتعليمات الموانئ البحريه لدول مجلس التعاون لدول الخليج العربي',
    description: '',
    descriptionAr:
      'كل ما تحتاج معرفته حول قواعد وتعليمات الموانئ البحريه لدول مجلس التعاون لدول الخليج العربي',
    // background: activeBtn.generalRegulationForControl,
    // color:activeBtn.generalRegulationForControl,
    // border:activeBtn.generalRegulationForControl,
    control:
      'rulesandRegulationsforSeaportsforThecooperationCouncilforTheArabstatesofThegulf',
  },
  {
    name: "Investor's Guide to Licenses",
    nameAR:"دليل المستثمر للتراخيص",
    description: '',
    descriptionAr:
      'كل ما تحتاج معرفته حول دليل المستثمر للتراخيص',
    // background: activeBtn.generalRegulationForControl,
    // color:activeBtn.generalRegulationForControl,
    // border:activeBtn.generalRegulationForControl,
    control:
      'investorsGuide',
  },
];
