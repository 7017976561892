import Biddings from './Biddings';
import Invoice from './Invoice';
import License from './License';
import RentalFacilities from './RentalFacilities';
import Tracking from './Tracking';
import Statistics from './Statistics';
import UnderConstruction from './UnderConstruction';
import TransactionTrackingService from './TransactionTrackingService';
import VesselSearch from './VesselSearch';
import Order from './Order';
import ElectronicPaymentService from './ElectronicPaymentService'
import {
  FaGavel,
  FaSearch,
  FaRegFileAlt,
  FaFileInvoiceDollar,
  FaExternalLinkAlt,
  FaQuestionCircle,
  FaCompressAlt,
} from 'react-icons/fa';
import styles from './EServices.module.scss';
import search from '../../../src/assets/img/eservices/search.svg';
import license from '../../../src/assets/img/eservices/license.svg';
import ctracking from '../../../src/assets/img/eservices/container-tracking.svg';
import invoice from '../../../src/assets/img/eservices/invoice.svg';
import request from '../../../src/assets/img/eservices/request.svg';
import ttracking from '../../../src/assets/img/eservices/tracking-service.svg';
import statistics from '../../../src/assets/img/eservices/statistics.svg';
import sla from '../../../src/assets/img/eservices/timer.svg';
import chat from '../../../src/assets/img/eservices/Icon-01.png';
import { Biddings2 } from './biddings2';
import { Biddings3 } from './biddings3';
import { BiddingsAnnouncments } from './biddingsAnnouncment';
import { SLAComponent } from './SLA/SLA';
import { Chat } from './Chat/LiveChat';

export const components = [
  {
    title: <strong className={styles.tabTitle}>Biddings</strong>,
    component: Biddings,
    icon: <img src={ttracking} className={styles.serviceIcon} />,
    id: 1,
  },
  {
    title: <strong className={styles.tabTitle}>Vessel Search</strong>,
    //description: 'Allows beneficiaries to enquire about the vessels’ arrival/departure schedule...',
    component: VesselSearch,
    //link: '#',
    icon: <img src={search} className={styles.serviceIcon} />,
    id: 2,
  },
  {
    title: <strong className={styles.tabTitle}>License</strong>,
    //description: 'This service provides you with the necessary license to practice shipping agents and ship...',
    component: License,
    //link: '#',
    icon: <img src={license} className={styles.serviceIcon} />,
    id: 3,
  },
  {
    title: <strong className={styles.tabTitle}>Container Tracking</strong>,
    //description: 'E-service which allows beneficiaries to find the current location of containers...',
    component: Tracking,
    //link: '#',
    icon: <img src={ctracking} className={styles.serviceIcon} />,
    id: 4,
  },
  {
    title: (
      <strong className={styles.tabTitle}>
        Invoices of Owners of Goods Service
      </strong>
    ),
    //description: 'Allows owners of goods and beneficiaries to issue invoices from Saudi Ports Authority’s website through commercial ports invoices system... ',
    component: Invoice,
    //link: '#',
    icon: <img src={invoice} className={styles.serviceIcon} />,
    id: 5,
  },
  {
    title: (
      <strong className={styles.tabTitle}>
        Request for Rental of Facilities
      </strong>
    ),
    //description: 'Submit a request for rental of facilities in ports affiliated to Saudi Ports Authority...',
    component: RentalFacilities,
    //link: '#',
    icon: <img src={request} className={styles.serviceIcon} />,
    id: 6,
  },
  {
    title: (
      <strong className={styles.tabTitle}>Transaction Tracking Service</strong>
    ),
    //description: 'Inquire about the information and status of transactions...',
    component: TransactionTrackingService,
    //link: '#',
    icon: <img src={ttracking} className={styles.serviceIcon} />,
    id: 7,
  },
  {
    title: <strong className={styles.tabTitle}>Statistics</strong>,
    //description: 'Inquire about the information and status of transactions...',
    component: Statistics,
    //link: '#',
    icon: <img src={statistics} className={styles.serviceIcon} />,
    id: 8,
  },
  {
    title: <strong className={styles.tabTitle}>Orders</strong>,
    //description: 'Inquire about the information and status of transactions...',
    component: Order,
    //link: '#',
    icon: <img src={invoice} className={styles.serviceIcon} />,
    id: 9,
  },
  {
    title: <strong className={styles.tabTitle}>SLA</strong>,
    //description: 'Allows owners of goods and beneficiaries to issue invoices from Saudi Ports Authority’s website through commercial ports invoices system... ',
    component: SLAComponent,
    //link: '#',
    icon: <img src={sla} className={styles.serviceIcon} />,
    id: 10,
  },
  {
    title: <strong className={styles.tabTitle}>Chat</strong>,
    //description: 'Allows owners of goods and beneficiaries to issue invoices from Saudi Ports Authority’s website through commercial ports invoices system... ',
    component: Chat,
    //link: '#',
    icon: <img src={chat} className={styles.serviceIcon} />,
    id: 11,
  },
  {
    title: <strong className={styles.tabTitle}>Electronic payment service</strong>,
    //description: 'Inquire about the information and status of transactions...',
    component: ElectronicPaymentService,
    //link: '#',
    icon: <img src={invoice} className={styles.serviceIcon} />,
    id: 12,
  },
];
export const biddings = [
  {
    title: 'Biddings Announcement',
    link: 'https://mawani.gov.sa/en-us/EServices/Biddings/Pages/Qualification.aspx',
    component: BiddingsAnnouncments,
  },
  {
    title: 'Announcement of qualification Biddings',
    link: '#',
    component: Biddings2,
  },
  {
    title: 'Results Opening',
    link: 'https://mawani.gov.sa/en-us/EServices/Biddings/Pages/resultsopening.aspx',
    component: UnderConstruction,
  },
  {
    title: 'Biddings List Result',
    link: 'https://mawani.gov.sa/en-us/EServices/Biddings/Pages/BiddingsListResult.aspx',
    component: UnderConstruction,
  },
  {
    title: 'Invitation for Pre-qualification',
    link: 'https://mawani.gov.sa/en-us/eservices/biddings/pages/qualification1.aspx',
    component: Biddings3,
  },
];
