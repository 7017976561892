import { useState } from 'react';
import { Link } from 'react-router-dom';
import flagSaudi from '../../../src/assets/img/Flag_of_Saudi_Arabia.svg';
import flagUK from '../../../src/assets/img/Flag_of_the_United_Kingdom.svg';
import {
  Navbar,
  NavbarContainer,
  NavbarWrapper,
  NavbarToggler,
  NavbarCollapse,
  Nav,
  NavLink,
} from '@material-tailwind/react';
import { navbarRoutes } from 'utils/constants';
import styles from './Navbar.module.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useArticleContext } from 'contexts/ArticleContext';
import { useVideoContext } from 'contexts/VideoContext';
import { useLibraryContext } from 'contexts/LibraryContext';
import { usePortContext } from 'contexts/PortContext';
import { useAboutContext } from 'contexts/AboutContext';
import { useServiceContext } from 'contexts/eServiceContext';
import { useFacilityContext } from 'contexts/FacilityContext';
import clsx from 'clsx';

const NavItemLink = ({ item, className, style }) => {
  const { t } = useTranslation();
  return (
    <>
      {item.link.startsWith('/') ? (
        <Link
          style={style || {}}
          to={item.link}
          target={item.target}
          className={clsx(className, ' hover:bg-gray-800/10 hover:opacity-95')}>
          {t(JSON.stringify(item.title.toUpperCase()).replace(/\"/g, ''))}
        </Link>
      ) : (
        <NavLink
          href={item.link}
          target={item.target}
          className={clsx(className, ' hover:bg-gray-800/10 hover:opacity-95')}
          style={style || {}}>
          {t(JSON.stringify(item.title.toUpperCase()).replace(/\"/g, ''))}
        </NavLink>
      )}
    </>
  );
};

const NavItem = ({ navItem }) => {
  const { t, i18n } = useTranslation();
  const [navItemHover, setNavItemHover] = useState(false);

  return !navItem.items ? (
    <div className={styles.navlink}>
      <NavItemLink item={navItem} className="w-full block lg:inline" />
    </div>
  ) : (
    <div
      className={`${styles.navlink} relative cursor-pointer`}
      onMouseEnter={() => setNavItemHover(true)}
      onMouseLeave={() => setNavItemHover(false)}
      onClick={() => setNavItemHover((cur) => !cur)}>
      <div className="w-full hidden lg:block relative z-50">
        <NavLink>
          {t(JSON.stringify(navItem?.title?.toUpperCase())?.replace(/\"/g, ''))}
        </NavLink>
      </div>

      <div
        className={clsx(
          'lg:absolute flex transition-opacity flex-col gap-2 w-full bg-[#006782] lg:py-4 lg:px-2 lg:shadow',
          navItemHover
            ? 'lg:opacity-1 lg:flex'
            : 'lg:opacity-0 lg:hidden opacity-1 flex',
          styles.subItemsContainer,
        )}>
        {navItem.items.map((navItem, index) => (
          <div className={styles.navlink} key={index}>
            <NavItemLink
              item={navItem}
              className="w-full block lg:inline cursor-pointer lg:py-3 uppercase"
            // style={{ lineHeight: '100%' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default function DefaultNavbar() {
  const [openNavbar, setOpenNavbar] = useState(false);
  const [localStore, setLocalStore] = useState('');
  const { t, i18n } = useTranslation();
  const {
    GetByCategory,
    catArticles,
    GetLatest,
    GetHistory,
    GetPress,
    historyArticles,
    latestArticles,
    pressArticles,
    GetBySlug,
    slugArticle,
  } = useArticleContext();
  const { GetAllVideos } = useVideoContext();
  const { GetLibraries } = useLibraryContext();
  const { GetAllPorts } = usePortContext();
  const { GetAboutUsPage } = useAboutContext();
  const { getServices } = useServiceContext();
  const { getFacility } = useFacilityContext();

  if (localStore == null || localStore == undefined) {
    localStore = "en";
    localStorage.setItem("locale", "en");
  }
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    setLocalStore(localStoreVar);
    var pathname = window.location.pathname;
    if (pathname == '/announcement' && localStore == 'en') {
      window.open('/', '_self');
    }
  }, [localStore]);

  function ChangeLanguage() {
    if (localStore == 'en') {
      i18n.changeLanguage('ar');
      localStorage.setItem('locale', 'ar');
      setLocalStore('ar');
      document.body.dir = 'rtl';
      RefreshData('ar');
    } else if (localStore == 'ar') {
      i18n.changeLanguage('en');
      localStorage.setItem('locale', 'en');
      setLocalStore('en');
      document.body.dir = 'ltr';
      RefreshData('en');
    }
  }

  function RefreshData(locale) {
    var url = window.location.pathname;
    if (url == '/media-center') {
      GetByCategory('News', locale);
      GetByCategory('Mawani-Newsletter', locale);
      GetByCategory('Photos', locale);
      GetAllVideos(locale);
      GetLibraries(locale);
    }
    if (url == '/ports') {
      GetAllPorts(locale);
    }
    if (url == '/about-us') {
      GetAboutUsPage(locale);
    }
    if (url == '/e-services') {
      getServices(locale);
      getFacility(locale);
    }
    if (url == '/') {
      GetByCategory('Mawani-Newsletter', locale);
      GetLatest(locale);
      GetHistory(locale);
      GetPress(locale);
    }
    if (url == '/history') {
      GetByCategory('Mawani-Newsletter', locale);
    }
    if (url == '/article') {
      const urlParams = new URLSearchParams(window.location.search);
      let code = urlParams.get('a');
      GetBySlug(code, locale);
    }
  }

  return (
    <Navbar className={`!mb-0 ${styles.navbar} z-50`} navbar>
      <NavbarContainer className={styles.navbarContainer}>
        <NavbarWrapper className={styles.logoWrapper}>
          {/* <i className="fa fa-bars"></i> */}
          <Link to="/">
            <img
              src={'/logo.png'}
              alt="mawani logo"
              width={'130px'}
              height={'60px'}
            />
          </Link>
          <div className={`${styles.hamburgerMenuLangSwitcherContainer}`}>
            <div className="lg:hidden">
              <LangSwitcher
                localStore={localStore}
                ChangeLanguage={ChangeLanguage}
              />
            </div>
            <NavbarToggler
              onClick={() => setOpenNavbar(!openNavbar)}
              color="white"
            />
          </div>
        </NavbarWrapper>

        <NavbarCollapse open={openNavbar}>
          <Nav className={`${styles.navWrapper}`}>
            <div
              className={`${styles.navDrop} flex flex-col z-50 lg:flex-row lg:items-center`}>
              {navbarRoutes.map((item, index) => (
                <NavItem navItem={item} key={index} />
              ))}
            </div>
          </Nav>
        </NavbarCollapse>
        <div className="hidden lg:block">
          <LangSwitcher
            localStore={localStore}
            ChangeLanguage={ChangeLanguage}
          />
        </div>
      </NavbarContainer>
    </Navbar>
  );
}

const LangSwitcher = ({ localStore, ChangeLanguage }) => {
  const { t } = useTranslation();

  return (
    <>
      {localStore && localStore === 'en' && (
        <div className={styles.rightSide}>
          <a
            href='/customerportal'
            target='_self'
          >
            {t('Customer Portal')}
          </a>
          <div className={styles.langSwitchButton}>
            <button onClick={ChangeLanguage} className={styles.langSwitch}>
              {t('Arabic')}
            </button>
          </div>
        </div>
      )}
      {localStore && localStore === 'ar' && (
        <div className={styles.rightSide}>
          <a
            href='/customerportal'
            target='_self'
          >
            {t('Customer Portal')}
          </a>
          <div className={styles.langSwitchButton}>
            <button onClick={ChangeLanguage} className={styles.langSwitch}>
              {t('English')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
