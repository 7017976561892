import React, { createContext, useContext, useEffect, useState } from 'react';
import http from '../http';
import axios from 'axios';
const ServiceContext = createContext();

export const useServiceContext = () => {
    return useContext(ServiceContext);
};

export const ServiceProvider = ({children}) => {
    const [licenses, setLicenses] = useState("");
    const [qualificationBindings, setQualificationBindings] = useState("");
    const [preQualificationBindings, setPreQualificationBindings] = useState("");
    const [preQualificationBindingsTable, setPreQualificationBindingsTable] = useState("");
    var localStore = localStorage.getItem('locale');
    if(localStore == null || localStore == undefined){
        localStore = "ar";
    }

    const getServices = (locale) =>{
        http.get("/api/e-services?populate=*&locale="+locale)
        .then((response) => {
            setLicenses(response.data.data);
        })
        http.get("/api/qualification-bindings?populate=*&locale="+locale)
        .then((response) => {
            setQualificationBindings(response.data.data);
        })
        http.get("/api/prequalification-of-operators?populate=*&locale="+locale)
        .then((response) => {
            setPreQualificationBindings(response.data.data[0]);
        })
        http.get("/api/prequalification-of-operator-tables?populate=*&locale="+locale)
        .then((response) => {
            setPreQualificationBindingsTable(response.data.data);
        })
    }

    useEffect(()=>{
        http.get("/api/e-services?populate=*&locale="+localStore)
        .then((response) => {
            setLicenses(response.data.data);
        });
        http.get("/api/qualification-bindings?populate=*&locale="+localStore)
        .then((response) => {
            setQualificationBindings(response.data.data);
        });
        http.get("/api/prequalification-of-operators?populate=*&locale="+localStore)
        .then((response) => {
            setPreQualificationBindings(response.data.data[0]);
        })
        http.get("/api/prequalification-of-operator-tables?populate=*&locale="+localStore)
        .then((response) => {
            setPreQualificationBindingsTable(response.data.data);
        })
    }, []);

    const value = {
        licenses,
        getServices,
        qualificationBindings,
        preQualificationBindings,
        preQualificationBindingsTable
    };
    
    // context provider
    return(
        <ServiceContext.Provider value={value}>
            {children}
        </ServiceContext.Provider>
    )
}; 