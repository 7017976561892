import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import KeepMeInformed from 'components/home/KeepMeInformed/index';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import Article from 'components/Article';
export default function MediaCenter() {
    return (
        <>
          <Navbar />
          <Article />
          {/* <KeepMeInformed /> */}
          <Information />
          <Subscribe />
          <DefaultFooter />
        </>
      );
}
