import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import KeepMeInformed from 'components/home/KeepMeInformed';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import EServiceComp from 'components/EServices';
import PopUpEServices from 'components/EServices/PopUpEServices';

export default function EServices() {
  return (
    <>
      <Navbar />
      <PopUpEServices />
      <EServiceComp />
      {/* <KeepMeInformed /> */}
      <Information />
      <Subscribe />
      <DefaultFooter />
    </>
  );
}
