import { useEffect, useState } from 'react';
import styles from './Customer.module.scss';
import { useTranslation } from 'react-i18next';
import { FiDownload } from 'react-icons/fi';

const Customer = () => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);
  // function CustomerBlock() {
  //   var localStore = localStorage.getItem('locale');
  //   if (localStore == null || localStore == undefined) {
  //     localStore = "ar";
  //   }
  //   useEffect(() => {
  //     if (localStore === "ar") {
  //       window.location.replace("https://flpnwc-ln5rj7djbz.dispatcher.sa1.hana.ondemand.com/sites/portal?sap-language=AR&#Shell-home")
  //     } else if (localStore === "en") {
  //       window.location.replace("https://flpnwc-ln5rj7djbz.dispatcher.sa1.hana.ondemand.com/sites/portal#Shell-home")
  //     }
  //   }, [])
  //   return null
  // }
  return (
    <>
      <div className={`${styles.background}`}>
        {/* <div className={styles.image}>
          <img src={MawaniImage} />
        </div> */}
        <div className={`${styles.whiteBox}`}>
          <div className={`${styles.topPart}`}>
            <h1>
              {t('Welcome To Mawani Customer Portal')}
            </h1>
            <p>{t('Please log in if you are an existing customer')}</p>
            <button>
              <a
                href={(i18n.language === "en") ? ("https://flpnwc-rjtnyz9xtk.dispatcher.sa1.hana.ondemand.com/sites/Portal?hc_login&sap-language=en") : ("https://flpnwc-rjtnyz9xtk.dispatcher.sa1.hana.ondemand.com/sites/Portal?hc_login&sap-language=ar")}
                target="_blank">
                {t('Login')}
              </a>
            </button>
            <a
              href={(i18n.language === "en") ? ("/pdfs/CustomerPortal/How_to_Use_Customer_Portal_Guide.pdf") : ("/pdfs/CustomerPortal/How_to_Use_Customer_Portal_Guide_AR.pdf")}
              target="_blank"
              rel="noreferrer"
              className={styles.guide}
            >
              <div>{t('How to use Customer Portal')}</div>
              <FiDownload />
            </a>
          </div>
          <div className={styles.border}></div>
          <div className={`${styles.bottomPart}`}>
            <h1>{t('Are you new in Mawani?')}</h1>
            <button>
              <a
                href={(i18n.language === "en") ? ("https://flpnwc-rjtnyz9xtk.dispatcher.sa1.hana.ondemand.com/sites/portal?sap-language=EN&#Register-Display") : ("https://flpnwc-rjtnyz9xtk.dispatcher.sa1.hana.ondemand.com/sites/portal?sap-language=AR&#Register-Display")}
                target="_blank"
              >
                {t('Register')}
              </a>
            </button>
            <a
              href={(i18n.language === "en") ? ("/pdfs/CustomerPortal/Registration_Guide.pdf") : ("/pdfs/CustomerPortal/Registration_Guide_AR.pdf")}
              target="_blank"
              rel="noreferrer"
              className={styles.guide}
            >
              <div>{t('Registration Guide')}</div>
              <FiDownload />
            </a>
          </div>
        </div>
        {/* <div>
          <div className={`${styles.buttons}`}>
            <div className={`${styles.buttontiles}`}>
              <h1>
                {t('Welcome To Mawani Customer Portal')}
              </h1>
              <p>{t('Please log in if you are an existing customer')}</p>
              <button>
                <a
                  href="https://flpnwc-ln5rj7djbz.dispatcher.sa1.hana.ondemand.com/sites/Portal?hc_login"
                  target="_blank">
                  {t('Login')}
                </a>
              </button>
              <a
                href="/pdfs/CustomerPortal/Licenses_User_Guide.pdf"
                target="_blank"
                rel="noreferrer"
                className={styles.guide}
              >
                <div>{t('How to use Customer Portal')}</div>
                <FiDownload />
              </a>
            </div>
            <div className={`${styles.buttontiles}`}>
              <h1>{t('Are you new in Mawani?')}</h1>
              <p>{t("To get more information about")}</p>
              <button>
                <a
                  href={(language === "en") ? ("https://flpnwc-ln5rj7djbz.dispatcher.sa1.hana.ondemand.com/sites/Portal#Register-Display") : ("https://flpnwc-ln5rj7djbz.dispatcher.sa1.hana.ondemand.com/sites/portal?sap-language=AR&#Register-Display")}
                  target="_blank"
                >
                  {t('Register')}
                </a>
              </button>
              <a
                href="/pdfs/CustomerPortal/Objections_User_Guide.pdf"
                target="_blank"
                rel="noreferrer"
                className={styles.guide}
              >
                <div>{t('Registration Guide')}</div>
                <FiDownload />
              </a>
            </div>
          </div>
        </div> */}
        {/* <div className={`${styles.container_max}`}>
          <div className={`${styles.titleAndButton}`}>
            <h1 className={`${styles.pageTitle}`}>
              {t('Customer Portal')}
            </h1>
            <div className={`${styles.buttons}`}>
              <div>
                <p>{t('Already have an account')}</p>
                <button className="button-default">
                  <a
                    href="https://flpnwc-ln5rj7djbz.dispatcher.sa1.hana.ondemand.com/sites/Portal?hc_login"
                    target="_blank">
                    {t('Login')}
                  </a>
                </button>
              </div>
              <div>
                <p>{t('New User')}</p>
                <button className="button-default">
                  <a
                    href={(language === "en") ? ("https://flpnwc-ln5rj7djbz.dispatcher.sa1.hana.ondemand.com/sites/Portal#Register-Display") : ("https://flpnwc-ln5rj7djbz.dispatcher.sa1.hana.ondemand.com/sites/portal?sap-language=AR&#Register-Display")}
                    target="_blank">
                    {t('Register')}
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className={`${styles.block}`}>
            <img src={backgroundImage} className={styles.image} />
            <div className={styles.list}>
              <p> {t('The Customer Portal,')}</p>
              <div className={styles.container}>
                <div>
                  <h2>{t('My Profile')}</h2>
                  <p>{t('Profile Description')}</p>
                </div>
              </div>
              <div className={styles.container}>
                <div>
                  <h2>{t('Licenses')}</h2>
                  <p>{t('Licenses Description')}</p>
                </div>
              </div>
              <div className={styles.container}>
                <div>
                  <h2>{t('Violations')}</h2>
                  <p>{t('Violations Description')}</p>
                </div>
              </div>
              <div className={styles.container}>
                <div>
                  <h2>{t('Visits')}</h2>
                  <p>{t('Visits Description')}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={styles.userGuide}>
              <a
                href="/pdfs/CustomerPortal/Objections_User_Guide.pdf"
                target="_blank"
                rel="noreferrer"
                className={styles.guide}
              >
                {t('Objections User Guide')}
              </a>
              <a
                href="/pdfs/CustomerPortal/Licenses_User_Guide.pdf"
                target="_blank"
                rel="noreferrer"
                className={styles.guide}
              >
                {t('Licenses User Guide')}
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Customer;
