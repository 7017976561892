import styles from './Information.module.scss';
import { useTranslation } from 'react-i18next';

export default function Information() {
  const { t } = useTranslation();
  return (
    <div className={`${styles.informationWrap}`}>
      <div className="container_max">
        <div className={styles.infoTiles}>
          {/*} <a href="/ports" className={styles.infoTile}>
            <h3>{t('Invest in Mawani')}</h3>
            <p>
              {t('Learn more about investment opportunities in Mawani Saudi.')}
            </p>
          </a>*/}
          <a
            href="/contact"
            className={`${styles.infoTile} ${styles.infoTileMargin}`}>
            <h3>{t('Connect with our Team')}</h3>
            <p>{t('Get in touch with our team all weekdays')}</p>{' '}
            <p>{t('From 7am to 10pm')}</p>
            <p>
              {t('Email')} :{' '}
              <a href="mailto:mawanicare@mawani.gov.sa">
                {t('mawanicare@mawani.gov.sa')}
              </a>{' '}
              <br />
              <p>{t('Call us on: 199003')}</p>
              {/* {t('Phone')} :{' '}
              <a href={`tel:${t('4050005-11-966')}`}>{t('4050005-11-966')}</a>
              <br />
              {t('Fax')} {t('4053508-11-966')}
              <br />
              {t('po-box')} */}
            </p>
          </a>
          <a href="/media-center" className={styles.infoTile}>
            <h3>{t('Latest News')}</h3>
            <p>{t("Stay up to date with Mawani's progress in all sectors.")}</p>
          </a>
        </div>

        {/* <div className="relative ">
        <div className="grid grid-cols-3 gap-20 flex pb-20">
          <div className="flex flex-col">
            <span className="text-3xl"><strong>Invest in Mawani</strong></span>
            <div className="font-light">
              Learn more about investment opportunities in Mawani Saudi.
            </div>
          </div>
          <div className="flex flex-col">
         
            <span className="text-3xl"><strong>Connect with our Team</strong></span>
            <div className="font-light">
              Get in touch w our team of dreamers and does that are changing the
              world.
            </div>
          </div>
          <div className="flex flex-col">
          
            <span className="text-3xl"><strong>Latest News</strong></span>
            <div className="font-light">
              Stay up to date with Mawani’s progress in all sectors.
            </div>
          </div>
        </div>
      </div> */}
      </div>
    </div>
  );
}
