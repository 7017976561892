import styles from './AboutUs.module.scss';
import chartEng from '../../assets/img/OrgChartEng.jpg';
import chartArb from '../../assets/img/OrgChartArb.jpeg';
import { useEffect, useState } from 'react';
import { useAboutContext } from 'contexts/AboutContext';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from 'utils/constants';

const Org = () => {
  const [buttonText, setButtonText] = useState();
  const [chart, setChart] = useState(chartEng);
  const { organizationChart, GetOrgChartPage } = useAboutContext();
  const { t, i18n } = useTranslation();

  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }
  useEffect(() => {
    if (localStore == 'en') {
      setButtonText('Switch to Arabic Version');
    } else if (localStore == 'ar') {
      setButtonText('Switch to English Version');
    }
  }, []);
  //Handlers
  const languageHandler = () => {
    if (buttonText == 'Switch to Arabic Version') {
      setButtonText('Switch to English Version');
      localStorage.setItem('locale', 'ar');
      GetOrgChartPage('ar');
    } else if (buttonText == 'Switch to English Version') {
      setButtonText('Switch to Arabic Version');
      localStorage.setItem('locale', 'en');
      GetOrgChartPage('en');
    }
  };
  return (
    <div className={styles.aboutContent}>
      <div>
        <h2>{t('Organizational Chart')}</h2>
        <span className="float-right">
          <button
            className={`${styles.switchButton}`}
            onClick={languageHandler}>
            {buttonText}
          </button>
        </span>
      </div>
      <div className="pt-10 pb-3">
        <img
          lt="Organizational Chart"
          src={
            BASE_URL +
            organizationChart?.[0]?.attributes?.Image.data.attributes.url
          }
        />
        {/* {organizationChart != null && organizationChart[0].attributes != null &&< img lt="Organizational Chart" src={"http://localhost:1337"+organizationChart[0].attributes.Image.data.attributes.url} />} */}
        {/* <img src={chart} alt="Organizational Chart"/> */}
      </div>
    </div>
  );
};
export default Org;
