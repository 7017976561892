import ReactDatePicker from 'react-multi-date-picker';
import styles from './DatePicker.module.scss';
import { FaCalendar, FaTimes } from 'react-icons/fa';
import { DateObject } from 'react-multi-date-picker';
import { useMemo } from 'react';

const DatePicker = (props) => {
  const currentDate = useMemo(
    () =>
      new DateObject({
        calendar: props.calendar,
        locale: props.locale,
      }),
    [],
  );

  return (
    <ReactDatePicker
      {...props}
      containerClassName={styles.datePicker}
      render={
        <CustomInput
          currentDate={props.required ? currentDate.format(props.format) : ''}
        />
      }
    />
  );
};

const CustomInput = ({
  openCalendar,
  value,
  handleValueChange,
  currentDate,
}) => {
  return (
    <div className={styles.input}>
      <input
        type="text"
        onFocus={openCalendar}
        value={value}
        onChange={handleValueChange}
      />
      <button type="button" onClick={openCalendar}>
        <FaCalendar color="#2d6a80" />
      </button>
      <button
        type="button"
        onClick={() =>
          handleValueChange({
            target: {
              value: currentDate,
              selectionStart: currentDate.length,
            },
          })
        }>
        <FaTimes color="#2d6a80" />
      </button>
    </div>
  );
};

export default DatePicker;
