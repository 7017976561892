import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import KeepMeInformed from 'components/home/KeepMeInformed/index';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import JobsComp from 'components/Jobs';
import { useEffect } from 'react';

export default function Jobs() {
     
    useEffect(() =>{
      window.open('https://careers.mawani.gov.sa/','_self')
    },[]);

    return (
        <>
          {/* <Navbar />
          
          <JobsComp />
          {/* <KeepMeInformed /> 
          <Information />
          <Subscribe />
          <DefaultFooter /> */}
        </>
      );
}
