import { useEffect, useState } from 'react';
import styles from './Home.module.scss';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useArticleContext } from 'contexts/ArticleContext';
import { useVideoContext } from 'contexts/VideoContext';
import { useTranslation } from 'react-i18next';
import Video from '../MediaCenter/MediaCenter';
import News from '../MediaCenter//News';
import Pictures from '../MediaCenter/PressRelease';
import Newsletters from '../MediaCenter/Newsletters/Newsletters';
import DigitalLibrary from '../MediaCenter/Library';
import clsx from 'clsx';

export default function MediaSection() {
  const [selectQuery, setSelectQuery] = useState('');
  const { t, i18n } = useTranslation();
  const {
    GetByCategoryPage,
    catArticles,
    GetLatestPage,
    GetHistoryPage,
    GetPressPage,
    historyArticles,
    latestArticles,
    pressArticles,
  } = useArticleContext();
  const { GetAllVideosPage, videos } = useVideoContext();

  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }
  GetLatestPage(localStore);
  GetHistoryPage(localStore);
  GetPressPage(localStore);
  GetAllVideosPage(localStore);

  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);

  const [activeBtn, setActiveButton] = useState({
    all: true,
    press: false,
    videos: false,
  });

  const tabs = [
    {
      slug: 'news',
      tabName: t('NEWS'),
    },
    {
      slug: 'video',
      tabName: t('VIDEOS'),
    },
    {
      slug: 'pictures',
      tabName: t('PICTURES'),
    },
    {
      slug: 'newsletter',
      tabName: t('MAWANI NEWSLETTER'),
    },
    {
      slug: 'library',
      tabName: t('DIGITAL LIBRARY'),
    },
  ];
  const [active, setActive] = useState(tabs[0].slug);

  useEffect(() => {
    if (active === 'news') {
      setSelectQuery('News');
    } else if (active === 'pictures') {
      setSelectQuery('Photos');
    } else if (active === 'video') {
      setSelectQuery('Video');
    } else if (active === 'newsletter') {
      setSelectQuery('Mawani-Newsletter');
    } else if (active === 'library') {
      setSelectQuery('library');
    }
    console.log('active*****', active);
  }, [active]);

  return (
    <div className={`${styles.mediaSectionWrap}`}>
      <section className="media-carousel">
        <div className="container_max">
          <div className={styles.mediaSectionHeader}>
            <h2 className={styles.homeServiceTitle}>
              {t('MEDIA CENTER TITLE')}
            </h2>

            <div className={styles.homeMediaButtons}>
              {tabs.map((tab) => (
                <button
                  key={tab.slug}
                  onClick={() => {
                    setActive(tab.slug);
                    console.log('****onclick');
                  }}
                  className={clsx(styles.mediaSectionButton, 'uppercase')}
                  style={{
                    background: tab.slug === active ? '#00B2E3' : 'none',
                    color: tab.slug === active ? 'white' : '#00B2E3',
                  }}>
                  {tab.tabName}
                </button>
              ))}
            </div>
          </div>
         
          {active === 'news' && <News limit={3} />}
          {active === 'video' && <Video limit={3} />}
          {active === 'pictures' && <Pictures limit={3} />}
          {active === 'newsletter' && <Newsletters limit={6} />}
          {active === 'library' && <DigitalLibrary limit={6} />}
          <div className={styles.viewAllContainer}>
            <button
              className={clsx(styles.mediaSectionButton)}
              style={{
                color: '#00B2E3',
                // marginLeft:"1330px",
                // marginTop:"100px",
              }}
              key={selectQuery}
              onClick={() =>
                window.open('/media-center?o=' + selectQuery, '_self')
              }>
              {t('View All')}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
