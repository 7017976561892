import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import styles from './EServices.module.scss';
import { components } from './eservice.constants';
import ServiceBlock from './ServiceBlock';
import { Fragment } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useExternalContext } from 'contexts/ExternalContext';

const Tab = styled(TabUnstyled)`
  cursor: pointer;
  padding: 15px 8px;

  border: 1px solid #e3e3e3;
  font-family: 'themixarabicPlain', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  font-size: 10px;

  transition: 0.2s;

  &:hover {
    color: #fff;
    background: #00b2a9;
    border-color: #00b2a9;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(176deg)
        brightness(150%) contrast(150%);
    }
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 767px) {
    font-size: 15px;
  }

  @media (max-width: 639px) {
  }

  &.${tabUnstyledClasses.selected} {
    color: #fff;
    background: #2d6a80 !important;
    border-color: #2d6a80 !important;
    opacity: 1 !important;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(176deg)
        brightness(150%) contrast(150%);
    }
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)``;

const TabsList = styled(TabsListUnstyled)`
  margin-bottom: 55px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  @media (max-width: 1123px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default function EServiceComp() {
  const { t, i18n } = useTranslation();
  const { getCaptcha, getBiddings, getLookups } = useExternalContext();
  const [selectQuery, setSelectQuery] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    var localStoreVar = localStorage.getItem('locale');

    i18n.changeLanguage(localStoreVar);
    if (urlParams.get('o')) {
      let code = urlParams.get('o');
      console.log(code);
      if (code.toLowerCase() == 'license') {
        setSelectQuery(2);
      } else if (code == 'vessel-search') {
        setSelectQuery(1);
      } else if (code == 'request-for-rental-of-facilities') {
        setSelectQuery(5);
      }
    }
  }, []);

  useEffect(() => {
    getBiddings();
    getCaptcha();
    getLookups();
  }, []);

  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <h1 className="page_title">{t('eServices')}</h1>
          <div className={styles.eServiceWrapper}>
            <TabsUnstyled
              key={`tab-${selectQuery}`}
              defaultValue={selectQuery}
              orientation="horizontal"
              className="">
              <div className={styles.serviceContainer}>
                <TabsList>
                  {components.map((item, index) => {
                    if (
                      localStorage.getItem('locale') === 'en' &&
                      item.id === 10
                    ) {
                      return <></>;
                    }
                    return (
                      <Tab key={index}>
                        {item.icon}
                        {t(
                          JSON.stringify(item.title.props.children).replace(
                            /\"/g,
                            '',
                          ),
                        )}
                      </Tab>
                    );
                  })}
                </TabsList>
              </div>
              <div className={styles.contentContainer}>
                {components.map((item, index) => {
                  if (localStorage.getItem('locale') === 'en' && item.id === 10)
                    return <></>;
                  return (
                    <TabPanel value={index} keyindex>
                      <item.component />
                    </TabPanel>
                  );
                })}
              </div>
            </TabsUnstyled>
          </div>
        </div>
      </div>
    </>
  );
}
