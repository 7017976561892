import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './AboutUs.module.scss';
import plus from '../../../src/assets/img/icons/plus.svg';
import minus from '../../../src/assets/img/icons/minus.svg';
import { useTranslation } from 'react-i18next';
import { useAboutContext } from 'contexts/AboutContext';
import { BASE_URL } from 'utils/constants';

export default function Services() {
  const [expanded, setExpanded] = useState(false);
  const { t, i18n } = useTranslation();
  const { services } = useAboutContext();
  var count = 0;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <div className={`mb-10 ${styles.aboutContent}`}>
        <h2>{t('Services & Capabilities')}</h2>
        <div className={styles.eServiceWrapper}>
          <div>
            <div className={`${styles.accordianWrapper}`}>
              {services &&
                services.map((item) => {
                  count++;
                  return (
                    <>
                      <Accordion
                        expanded={expanded === 'panel' + count}
                        onChange={handleChange('panel' + count)}>
                        <AccordionSummary
                          aria-controls={'panel' + count + 'bh-content'}
                          id={'panel' + count + 'bh-header'}>
                          <div
                            className={`${styles.bgImage}`}
                            style={{
                              'background-image': `url("${
                                BASE_URL +
                                item.attributes.BackgroundImage.data.attributes
                                  .url
                              }")`,
                            }}>
                            <div className={styles.accordianHeader}>
                              <span className="">{item.attributes.Title}</span>
                              <span>
                                {expanded === 'panel' + count ? (
                                  <img src={minus} alt="collapse" />
                                ) : (
                                  <img src={plus} alt="expand" />
                                )}
                              </span>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className={styles.accordianCopy}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.attributes.Content,
                                }}
                              />
                            </div>
                          </Typography>
                        </AccordionDetails>
                        {/* <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                          <div className={styles.bgImage+ ' ' + styles.bgImage1}>
                          <div className={styles.accordianHeader}>
                          <span className="">Berths, Wharfs, and Multipurpose Built Terminals </span>
                              <span>{expanded === 'panel1' ? <img src={minus} alt="collapse"/> : <img src={plus} alt="expand"/>}</span>
                            </div>
                            </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            <div className={styles.accordianCopy}>
                            <p>
                            The Saudi Ports Authority (Mawani) is the government authority responsible for the efficient, professional running of Saudi Arabia’s ports. With a combined total of 290 berths, the Kingdom’s ports comprise the Middle East’s largest seaport network that not only contributes a great deal to national growth, but also showcases the Kingdom’s formidable regional and international standing.  
                            </p>
                            <p>
                            Each terminal contains one or more berths, and can readily accommodate any form of vessel, which is what drove Mawani to provide specialized terminals for various types of loads, including containers, general cargos, refrigerated and frozen cargos, bulk cargo, roll-on/roll-off cargos, and livestock.  
                            </p>
                            <p>
                            Most of the Kingdom’s ports have their own special zones, such as bonded storage and re-export, vessel repair facilities, indoor and outdoor storage, equipment depots, and service and maintenance centers.  
                            </p>
                            </div>
                            </Typography>
                          </AccordionDetails> */}
                      </Accordion>
                    </>
                  );
                })}
              {/* <Accordion
                  expanded={expanded === 'panel2'}
                  onChange={handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={() => <>+</>}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header">
                    <div className={styles.bgImage+ ' ' + styles.bgImage2}>
                      <div className={styles.accordianHeader}>
                        <span className="">Security, Safety, and the Environment </span>
                        <span>{expanded === 'panel2' ? <img src={minus} alt="collapse"/> : <img src={plus} alt="expand"/>}</span>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                    <div className={styles.accordianCopy}>
                      <p>
                    Saudi ports are safe havens to work and invest in. Mawani has redoubled its efforts to keep people, properties, the environment and operations at national ports safe. This is thanks to the implementation of state-of-the-art safety measures and information, navigation, antipollution, fire, and security systems adhered to by all operators. Mawani also complies to all International Ship and Port Facility Security Code (ISPS) rules and regulations, including the installation of security and CCTV systems, luggage scanners, and fire safety systems. Perimeter walls, controlled-access gates, and hydraulically operated blockers are standard, as are dedicated telecommunications, hydrographic surveys of seaport waters, and vessel tracking systems. Security patrol, safety, and ambulance vehicles are also supplied to all ports. 
                    </p>
                    <p>
                    Mawani also ensures a safe working and investment environment with the help of the Industrial Security Department of the Ministry of Interior, which handles the three primary domains of workplace wellbeing: security, safety, and firefighting. Mawani constantly evaluates the security status of each facility it oversees. It has also developed security and emergency plans accordingly with ideally designed and applied response and control plans, along with training for Industrial Security personnel. 
                    </p>
                    <p>
                    Mawani works to upgrade the performance of its emergency response teams with specialized training for any potential seaport accidents. 
                    </p>
                    <p>
                    As well as ensuring the competence of response teams through both training and simulation exercises. 
                    </p>
                    <p>
                    The Authority also applies global best practices to limit the environmental impact of each port and any vessels on the surrounding marine habitat.  
                    </p>
                    </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel3'}
                  onChange={handleChange('panel3')}>
                  <AccordionSummary
                    expandIcon={() => <>+</>}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header">
                    <div className={styles.bgImage+ ' ' + styles.bgImage3}>
                      <div className={styles.accordianHeader}>
                        <span className="">Passenger Terminals </span>
                        <span>{expanded === 'panel3' ? <img src={minus} alt="collapse"/> : <img src={plus} alt="expand"/>}</span>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                    <div className={styles.accordianCopy}>
                      <p>
                      It has been said that there are no second chances for first impressions, and nothing communicates people’s culture as strongly to visitors as do their passenger terminals. Mawani has spared no effort to ensure that passenger terminals are as welcoming to travelers to the Kingdom – especially pilgrims – as they can be. The warmth with which they are received reflects the extent of the Kingdom’s care for each pilgrim’s wellbeing, with every possible luxury and comfort being made available to them. 
                      </p>
                      <p>
                      As part of its efforts to support the National Transport and Logistics Strategy (NTLS), Mawani has boosted its involvement in empowering the maritime tourism sector with new and better-developed passenger terminals and dedicated berths for cruise ships. 
                      </p>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
