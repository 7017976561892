import { AxiosResponse } from 'axios';
import { SingleArticleType } from 'components/Article/article.types';
import { StrapiMetaPaginationType } from 'types';
import http from '../../http';

export const getSingleArticle = (articleSlug: string | null, locale: string) =>
  http
    .get(
      '/api/articles?filters[Slug][$eqi]=' +
        articleSlug +
        '&locale=' +
        locale +
        '&populate[Category][populate]=CategoryImage&populate[Images][populate]=*',
    )
    .then(
      (
        response: AxiosResponse<{
          data: SingleArticleType[];
          meta: StrapiMetaPaginationType;
        }>,
      ) => response.data.data,
    );
