import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './hStyles.css';
import one from './../../assets/img/history/1.jpg';
import two from './../../assets/img/history/2.jpg';
import three from './../../assets/img/history/3.jpg';
import four from './../../assets/img/history/4.jpg';
import { useArticleContext } from 'contexts/ArticleContext';
import { useTranslation } from 'react-i18next';
import styles from './Home.module.scss';
import HistoryTextBox from './HistoryTextBox';
import { BASE_URL } from 'utils/constants';

export default function HistoryCarousel() {
  const { t, i18n } = useTranslation();
  const { GetByCategoryPage, catArticles } = useArticleContext();
  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }
  GetByCategoryPage('History', localStore);
  const renderDotsItem = ({ isActive }) => {
    return isActive ? <span></span> : <span></span>;
  };

  return (
    <div className={`${styles.historyCarouselWrap}`}>
      <div className="flex history-carousel">
        {catArticles &&
          catArticles.length > 0 &&
          catArticles
            ?.sort(
              (a, b) =>
                new Date(b?.attributes?.PublishDate || -1) -
                new Date(a.attributes?.PublishDate || -1),
            )
            .slice(0, 1)
            .map((cat, i) => (
              <div
                className={`container_max ${styles.homeHistoryWrap}`}
                key={i}>
                <div className={styles.homeHistoryDetail}>
                  <h2 className={styles.homeHistoryTitle}>
                    {cat?.attributes?.Title}
                  </h2>
                  <HistoryTextBox
                    textItem={cat?.attributes?.Summary}
                    slug={cat?.attributes?.Slug}
                  />
                </div>
                <div className="overflow-hidden history-slider-container--outer">
                  <AliceCarousel
                    className={styles['alice-carousel']}
                    he
                    renderDotsItem={renderDotsItem}
                    disableButtonsControls
                    autoPlay
                    infinite
                    // autoPlayDirection={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    autoPlayInterval={5000}
                    animationDuration={1000}>
                    {cat?.attributes?.Images != null &&
                      cat?.attributes?.Images?.data != null && (
                        <img
                          className="sliderImage"
                          src={
                            BASE_URL +
                            cat?.attributes?.Images?.data[0]?.attributes.url
                          }
                        />
                      )}
                    {cat?.attributes?.Images != null &&
                      cat?.attributes?.Images?.data != null && (
                        <img
                          className="sliderImage"
                          src={
                            BASE_URL +
                            cat?.attributes?.Images?.data[1]?.attributes.url
                          }
                        />
                      )}
                    {cat?.attributes?.Images != null &&
                      cat?.attributes?.Images?.data != null && (
                        <img
                          className="sliderImage"
                          src={
                            BASE_URL +
                            cat?.attributes?.Images?.data[2]?.attributes.url
                          }
                        />
                      )}
                    {cat?.attributes?.Images != null &&
                      cat?.attributes?.Images?.data != null && (
                        <img
                          className="sliderImage"
                          src={
                            BASE_URL +
                            cat?.attributes?.Images?.data[3]?.attributes.url
                          }
                        />
                      )}
                  </AliceCarousel>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}
