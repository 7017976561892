import { AxiosResponse } from 'axios';
import { StrapiMetaPaginationType } from '../../types';
import http from '../../http';
import { RegulationType } from 'components/regulations/regulation.types';
export const GetRegulation = (
  locale: string,
) =>
  http
    .get(
      `/api/regulations?populate=deep&locale=${locale}&sort=DisplayOrder:asc`,
    )
    .then(
      (
        response: AxiosResponse<{
          data: RegulationType[];
        }>,
      ) => response.data,
    );
