import styles from './EServices.module.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Announcement from './Announcement/Announcement';

const Invoice = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);

  return (
    <div className={styles.eServiceContent}>
      <h2>{t('Invoices of owners of goods Service')}</h2>

      <div className={styles.serviceIntro}>
        <p>
          {t(
            'This service allows owners of goods and beneficiaries to issue invoices from Saudi Ports Authority’s website through commercial ports invoices system.',
          )}
        </p>

        {/*
        {i18n.language === 'ar' && (
          <div className={styles.announcement}>
            <img src={campaign} alt="Announcement" />
            <div>
              <div className={styles.title}>تنبيه</div>
              <div className={styles.description}>يوم الجمعة 21 يوليو,2023 من الساعة 1 مساء حتى 3  مساء
              </div>
            </div>
          </div>
        )*/}
        {/*remove at friday morning  */}
        <Announcement />
        <p>
          <strong>{t('Invoice issuance steps:')}</strong>
          <div className={styles.serviceStepsFive}>
            <div className={styles.step}>
              <div className={styles.number}>{t('1')}</div>
              <div className={styles.title}>
                {t('Access invoice service system')}
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.number}>{t('2')}</div>
              <div className={styles.title}>
                {t('Enter username and password')}
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.number}>{t('3')}</div>
              <div className={styles.title}>
                {t(
                  'Enquire about bills information by entering the numbers of the manifest and the bill',
                )}
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.number}>{t('4')}</div>
              <div className={styles.title}>
                {t('Enter delivery permission number and print invoice')}
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.number}>{t('5')}</div>
              <div className={styles.title}>{t('Pay bill through SADAD')}</div>
            </div>
          </div>
          <br />
          <a
            className="button-default"
            href="https://spa.mawani.gov.sa/forms/frmservlet?config=jre_broker"
            rel="noreferrer"
            target="_blank">
            {t('Access to the service')}
          </a>
          <ul className="list-disc font-light pb-10 pt-5">
            {/* <li>
            ​​To access the service directly, please click on the following
            link​{' '}
            <a
              href="https://spa.mawani.gov.sa/forms/frmservlet?config=jre_broker"
              target="_blank"
              rel="noreferrer"
              className="font-normal underline text-same text-[#2D6A80]">
              Service of invoices of owners of goods
            </a>
          </li> */}
            <li>
              <a
                href="/uploads/Rental_Invoice_Service_Manual_80f57111f0.pdf?updated_at=2023-02-14T08:57:06.081Z"
                download
                className="font-normal underline text-same text-[#2D6A80]">
                {t('Services User Manual')}
              </a>
            </li>
          </ul>
        </p>
        <p>
          <strong>{t('Instructions')}</strong>
          <ul>
            <li>
              {t(
                'For the service to function properly, it is recommended to use the (Explorer-FireFox) browser, and a Java support application must be available on the device.',
              )}
            </li>
            <li>
              {t('If the application is not available, please click ')}
              <a
                href="https://www.oracle.com/java/technologies/downloads/#java8"
                target="_blank"
                rel="noreferrer"
                className="font-normal underline text-same text-[#2D6A80]">
                {t('here')}
              </a>
              {t(' to download the application.')}
            </li>
            <li>
              {t(
                '​In case you have difficulty using the service, please click ',
              )}
              <a
                href="/pdfs/invoicestep.pdf"
                target="_blank"
                rel="noreferrer"
                className="font-normal underline text-same text-[#2D6A80]">
                {t('here.')}
              </a>
            </li>
            <li>
              <a
                href="/uploads/Rental_Invoice_Chrome_Edge_efc8f98d7a.pdf?updated_at=2023-02-14T08:57:06.175Z"
                download
                rel="noreferrer"
                className="font-normal underline text-same text-[#2D6A80]">
                {t(
                  'If you are using (Google Chrome - Microsoft Edge), click here to download the file of service activation',
                )}
              </a>
            </li>
            <li>
              {t(
                'To pay bills of the General Ports Authority through Mada-Visa-MasterCard cards via the electronic payment service at the link ',
              )}
              <a
                href="https://eservices.mawani.gov.sa/payment-gateway/"
                target="_blank"
                rel="noreferrer"
                className="font-normal underline text-same text-[#2D6A80]">
                {t('https://eservices.mawani.gov.sa/payment-gateway/.')}
              </a>
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Invoice;
