import { useState, useEffect } from 'react';
import styles from './PopUp.module.scss';
import { useTranslation } from 'react-i18next';
import http from '../../http';

export default function PopUp() {
    const { t, i18n } = useTranslation();
    const [isVisible, setIsVisible] = useState(true);
    const [announcement, setAnnouncement] = useState([]);
    const handleClose = () => setIsVisible(false);
    useEffect(() => {
        http
            .get('/api/home-page-announcements?locale=ar')
            .then((response) => {
                setAnnouncement(response.data.data);
            });
    }, []);

    function PopUpAnnouncement() {
        const currentDate = new Date();
        const day = currentDate.getUTCDate();
        const month = currentDate.getUTCMonth();
        const year = currentDate.getUTCFullYear();
        const hours = currentDate.getUTCHours();
        const minutes = currentDate.getUTCMinutes();
        const seconds = currentDate.getUTCSeconds();
        const milliseconds = currentDate.getUTCMilliseconds();

        const now = Date.UTC(
            year,
            month,
            day,
            hours,
            minutes,
            seconds,
            milliseconds,
        );
        for (let i = 0; i <= announcement?.length; i++) {
            if (
                new Date(announcement?.[i]?.attributes?.StartDate)?.getTime() <= now &&
                new Date(announcement?.[i]?.attributes?.EndDate)?.getTime() >= now
            ) {
                return (
                    <div className={isVisible ? `${styles.visible}` : `${styles.hidden}`}>
                        <div className={`${styles.content}`}>
                            <button className={`${styles.button}`} onClick={handleClose}>X</button>
                            <div className={`${styles.title}`}>
                                {t('PopUpTitle')}
                            </div>
                            <div className={`${styles.announcement}`}
                                dangerouslySetInnerHTML={{
                                    __html: announcement?.[i]?.attributes?.Announcement,
                                }}
                            />
                            <div className={`${styles.announcementReadMore}`}>
                                <a
                                    href="/announcement"
                                    target="_self"
                                    rel="noreferrer"
                                >
                                    {t('Read More')}
                                </a>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return (<div></div>)
    }
    return (
        <div>

            {/* temp */}
            {/* {i18n.language == 'ar' &&
                <div className={isVisible ? `${styles.visible}` : `${styles.hidden}`}>
                    <div className={`${styles.content}`}>
                        <button className={`${styles.button}`} onClick={handleClose}>X</button>
                        <div className={`${styles.title}`}>
                            {t('PopUpTitle')}
                        </div>
                        {/* <div className={`${styles.subTitle}`}>
                        {t('PopUpTitleTwo')}
                    </div> 
                    <div className={`${styles.announcement}`}>
                {t('PopUpAnnouncement')}
            </div>
            <div className={`${styles.afterAnnouncement}`}>
                {t('PopUpBless')}
            </div>
            <div className={`${styles.announcementReadMore}`}>
                <a
                    href="/announcement"
                    target="_self"
                    rel="noreferrer"
                >
                    {t('Read More')}
                </a>
            </div> 
            <div className={`${styles.announcementThree}`}>
                        {t('PopUpAnnouncementThree')}
                    </div>
                      <div className={`${styles.announcement}`}>
                        {t('PopUpAnnouncementFour')}
                    </div>
                    <div className={`${styles.announcementTwo}`}>
                        <a
                            href="https://business.sa/eservices/details/98bc7e54-70a4-45b2-dc1f-08dc0d1a2a49#98bc7e54-70a4-45b2-dc1f-08dc0d1a2a49"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('PopUpAnnouncementFive')}
                        </a>
                    </div> 
                     <div className={`${styles.announcementTwo}`}>
                        {t('PopUpAnnouncementSix')}
                    </div>
                     <div className={`${styles.afterAnnouncement}`}>
                            {t('PopUpBless')}
                        </div>\
                        </div> 
            </div > 
            // } * /}
            {/* temp */}

            {
                i18n.language == 'ar' && announcement?.length !== 0 && (
                    <PopUpAnnouncement />
                )
            }
        </div >
    );
}