import styles from './Article.module.scss';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from 'utils/constants';
import { Modal } from '@mui/material';
import { formatDateToDayMonthYearString } from 'utils/format.string';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getSingleArticle } from 'components/Article/article.api';
import { Loader } from 'components/Loader';

export default function Article() {
  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  let isPhotoPage = urlParams.get('photo') || "";
  let isArticlePage = urlParams.get('news') || "";
  let articleSlug = isArticlePage || isPhotoPage || urlParams.get('a');
  
  const [modalImageIndex, setModalImageIndex] = useState<number | null>(null);

  const { t, i18n } = useTranslation();

  const {
    data: articleData,
    isError,
    isLoading,
  } = useQuery(
    [
      'single.article',
      {
        locale: i18n.language,
        articleSlug,
      },
    ],

    () => getSingleArticle(articleSlug, i18n.language),
  );

  const getBackButtonUrlAndText = () => {
    if(isArticlePage) {
      return <a href={'/media-center?o=News'} className={`${styles.readLink}`}>
      {t('Back to news')}
    </a>
    }
    
    if(isPhotoPage) {
      return <a href={'/media-center?o=Photos'} className={`${styles.readLink}`}>
      {t('Back to photos')}
    </a>
    }
  }

  const allArticleImages = useMemo(
    () =>
      Array.isArray(articleData)
        ? articleData
          .map((item) => item.attributes.Images.data)
          .flat()
          .map((img) => img?.attributes.url)
        : [],
    [articleData],
  );

  if (isLoading) return <Loader />;
  else if (isError) history.push('/404');
  else if (!articleData || articleData.length <= 0) history.push('/404');
  else
    return (
      <>
        <div
          className={`${styles.bgHeaderBackground}`}
          style={
            articleData[0]?.attributes?.Category?.data?.attributes
              ?.CategoryImage?.data?.attributes?.url
              ? {
                backgroundImage: `url("${BASE_URL}${articleData[0]?.attributes?.Category?.data?.attributes?.CategoryImage?.data?.attributes?.url}")`,
              }
              : {}
          }></div>
        <div className="container_max">
          <div className="page_wrapper">
            {articleData &&
              articleData.map((item, i) => {
                return (
                  <div key={i}>
                    <div className={styles.titleContainer}>
                      <h1 className="page_title">{item.attributes.Title}</h1>
                      {item.attributes.PublishDate && !isPhotoPage && (
                        <p className={styles.dateText}>
                          {t('Published At')}{' '}
                          {formatDateToDayMonthYearString(
                            item.attributes.PublishDate,
                          )}
                        </p>
                      )}
                    </div>
                    <div
                      className={`${styles.articleWrap}`}
                      dangerouslySetInnerHTML={{ __html: item.attributes.Body }}
                    />
                    <br />
                    {item.attributes.Images != null &&
                      item.attributes.Images.data != null &&
                      (item.attributes.Images.data.length <= 1 ? (
                        <img
                          className={`${styles.imgStyle}`}
                          alt=""
                          src={
                            BASE_URL +
                            item.attributes.Images.data[0].attributes.url
                          }
                        />
                      ) : (
                        <div className={styles.imagesContainer}>
                          {item.attributes.Images.data.map((img, index) => (
                            <img
                              key={index}
                              alt=""
                              className={`${styles.imgStyle}`}
                              src={BASE_URL + img.attributes.url}
                              onClick={() => {
                                setModalImageIndex(
                                  allArticleImages.indexOf(img.attributes.url),
                                );
                              }}
                            />
                          ))}
                        </div>
                      ))}
                    <br />
                    {getBackButtonUrlAndText()}
                  </div>
                );
              })}
            {allArticleImages.length > 0 && (
              <ImageLightBox
                images={allArticleImages}
                onClose={() => setModalImageIndex(null)}
                index={modalImageIndex}
                setIndex={(i: number) => setModalImageIndex(Number(i) || null)}
              />
            )}
          </div>
        </div>
      </>
    );
}

const ImageLightBox = ({
  index,
  images,
  onClose,
  setIndex,
}: {
  index: number | null;
  images: string[];
  onClose: () => void;
  setIndex: (i: number) => void;
}) => {
  const goToNextImage = () => setIndex((index || 0) + 1);
  const goToPreviousImage = () => setIndex((index || 1) - 1);

  if (index === null) return null;

  if (!images[index]) {
    onClose();
    return null;
  }
  return (
    <Modal
      open={index !== null ? true : false}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.lightboxContainer}>
      <div className={styles.modalContentContainer}>
        <img
          alt=""
          className={`${styles.imgStyle}`}
          src={BASE_URL + images[index]}
          onClick={() => onClose()}
        />
      </div>
    </Modal>
  );
};
