module.exports = {
  name: 'arabic_ar',
  months: [
    ['محرم', 'محرم'],
    ['صفر', 'صفر'],
    ['ربیع الاول', 'ربیع الاول'],
    ['ربیع الثانی', 'ربیع الثانی'],
    ['جمادی الاول', 'جمادی الاول'],
    ['جمادی الثانی', 'جمادی الثانی'],
    ['رجب', 'رجب'],
    ['شعبان', 'شعبان'],
    ['رمضان', 'رمضان'],
    ['شوال', 'شوال'],
    ['ذیقعده ', 'ذیقعده'],
    ['ذیحجه', 'ذیحجه'],
  ],
  weekDays: [
    ['السّبت', 'سب'],
    ['الأحد', 'اح'],
    ['الإثنينِ', 'ثن'],
    ['الثلاثاء', 'ثل'],
    ['الأربعاء', 'ار'],
    ['الخميس', 'خم'],
    ['الجمعة', 'جم'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['قبل الظهر', 'ق.ظ'],
    ['بعد الظهر', 'ب.ظ'],
  ],
};
