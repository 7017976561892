import chatbot from '../../../src/assets/img/chatbot/Icon-02.png';
import chat from '../../../src/assets/img/chatbot/Icon-03.png';
import callBack from '../../../src/assets/img/chatbot/Icon-04.png';
import { useExternalContext } from 'contexts/ExternalContext';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import { Loader } from 'components/Loader';
import styles from './ChatBot.module.scss';
import { useState, useEffect, useRef, useCallback } from 'react';
// import close from '../../assets/img/close.svg';

export default function ChatBot() {
  const formRef = useRef(null);
  const { t, i18n } = useTranslation();
  var localStoreVar = localStorage.getItem('locale');

  const [clicked, setClicked] = useState(false);
  const [chatClicked, setChatClicked] = useState(false);
  const [callBackClicked, setCallBackClicked] = useState(false);
  let script = document.createElement('script');
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const { apiLoading, submitRemarksForm } = useExternalContext();
  const [success, setSuccess] = useState(null);
  useEffect(() => {
    i18n.changeLanguage(localStoreVar);
  }, [localStoreVar]);
  const buttonClicked = () => {
    setClicked((prev) => !prev);
  };
  const buttonChatClicked = () => {
    setChatClicked((prev) => !prev);
  };
  const buttonCallBackClicked = () => {
    setCallBackClicked((prev) => !prev);
  };
  const handleClose = () => {
    setClicked(false);
    setChatClicked(false);
    setCallBackClicked(false);
  };

  const load = () => {
    if (!window?.WebChat) setTimeout(load, 100);
    if (window?.WebChat)
      window?.WebChat.default({
        customData: { language: localStoreVar },
        socketUrl: 'https://uniithra.experiaapp.com',
        socketPath: '/ws',
        withRules: true,
        docViewer: true,
        initPayload: 'hello',
        title: localStoreVar === 'ar' ? 'المساعد الرقمي' : 'Digital Assistance',
        profileAvatar:
          'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axkrtyuep33x/b/ExperiaCDN/o/mawani.svg',
        openLauncherImage:
          'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axkrtyuep33x/b/ExperiaCDN/o/comment-dots.svg',
        closeImage:
          'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axkrtyuep33x/b/ExperiaCDN/o/angle-down.svg',
        showMessageDate: true,
        allowFileUpload: true,
        inputTextFieldHint:
          localStoreVar === 'ar' ? 'كيف اقدر اساعدك' : 'How can I help you',
        showCloseButton: true,
        params: {
          storage: 'session',
        },
        openOnLoad: true,
        // withRules: true,
        // docViewer: true,
        onWidgetEvent: {
          onChatClose: () => {
            document
              .getElementById('rasaWebchatPro')
              ?.setAttribute('style', 'display:none');
            setChatClicked(false);
            setClicked(false);
            setCallBackClicked(false);
          },
        },
        // mainColor:
      });
    document
      .getElementById('rasaWebchatPro')
      ?.setAttribute('style', 'display:block');
  };

  useEffect(() => {
    if (chatClicked) {
      const src =
        'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axkrtyuep33x/b/ExperiaCDN/o/index.js';
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      setTimeout(load, 200);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [localStoreVar, chatClicked]);

  const handleMin = () => {
    setClicked(true);
    setCallBackClicked(false);
    setChatClicked(false);
  };
  const onSubmit = async (data) => {
    setSuccess(null);

    console.log(data);

    const request = {
      name: data.name,
      phoneNumber: data.mobileNumber,
      details: data.details,
    };

    const resp = await submitRemarksForm(request);

    if (resp?.isSuccess === true) {
      reset();
      setSuccess(true);
      formRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      setSuccess(false);
    }
  };
  return (
    <>
      <div className={styles.chatbot}>
        {clicked === false &&
          chatClicked === false &&
          callBackClicked === false && (
            <button className={styles.firstButton} onClick={buttonClicked}>
              <img src={chatbot} />
            </button>
          )}
        {clicked === true &&
          chatClicked === false &&
          callBackClicked === false && (
            <div className={styles.secondButton}>
              <button className={styles.chat} onClick={buttonChatClicked}>
                <img src={chat} />
              </button>
              <button
                className={styles.callBack}
                onClick={buttonCallBackClicked}>
                <img src={callBack} />
              </button>
            </div>
          )}

        {callBackClicked === true && (
          <div className={styles.callBackList}>
            <div className={styles.callBackFormTitle}>
              <h2>{t('Callback')}</h2>
              <button onClick={handleClose} className={`${styles.closeIcon}`}>
                {/* <img src={close} /> */}X
              </button>
              <button onClick={handleMin} className={`${styles.minIcon}`}>
                _
              </button>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={styles.callBackForm}>
              {success && (
                <Alert severity="success" className="mb-4">
                  {t('Message Sent')}
                </Alert>
              )}
              {success === false && (
                <Alert severity="error" className="mb-4">
                  {t('Something went wrong')}
                </Alert>
              )}
              <div className={styles.callBackQOne}>
                <input
                  id="name"
                  type="text"
                  name="name"
                  placeholder={t('Remarks FullName')}
                  {...register('name', {
                    required: true,
                  })}></input>
                {errors.name && (
                  <span
                    style={{
                      color: 'red',
                      fontSize: '12px',
                    }}>
                    {t('ERROR NAME')}
                  </span>
                )}
              </div>

              <div className={styles.callBackQTwo}>
                <input
                  id="mobileNumber"
                  type="text"
                  placeholder={t('Remarks Mobile')}
                  maxLength={12}
                  {...register('mobileNumber', {
                    required: true,
                    pattern: /^05[0-9]+$/i,
                    maxLength: 10,
                    minLength: 10,
                  })}></input>
                {errors.mobileNumber && (
                  <span
                    style={{
                      color: 'red',
                      fontSize: '12px',
                    }}>
                    {t('ERROR MOBILE')}
                  </span>
                )}
              </div>
              <div className={styles.callBackQThree}>
                {/* <div className="form-field"> */}
                <textarea
                  rows={3}
                  placeholder={t('Remarks')}
                  cols={5}
                  {...register('details', {
                    required: true,
                  })}></textarea>
                {errors.details && (
                  <span
                    style={{
                      color: 'red',
                      fontSize: '12px',
                    }}>
                    {t('ERROR DETAILS')}
                  </span>
                )}
              </div>
              {apiLoading && <Loader />}
              {!apiLoading && (
                <button className={styles.callBackSendButton} type="submit">
                  {t('Send')}
                </button>
              )}
            </form>
          </div>
        )}
      </div>
    </>
  );
}
