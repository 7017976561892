import styles from './EServices.module.scss';
const license1 = (props) => {
return (
<>
{props.detail[0] != null && props.detail[0].attributes != null && <div className={`${styles.splitForm}`} dangerouslySetInnerHTML={{ __html: props.detail[0].attributes.AgentLicense }} /> }
</>
)
}
export default license1;

