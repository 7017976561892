import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import styles from './MediaCenter.module.scss';
import { components } from './mediacenter.constants';
import { Fragment } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Video from './MediaCenter';
import News from './News';
import Pictures from './PressRelease';
import PortsBulletin from './Newsletters/Newsletters';
import DigitalLibrary from './Library';
import useEffectOnce from 'hooks/useEffectOnce';
import { useLocation } from 'react-router-dom';
const Tab = styled(TabUnstyled)`
  cursor: pointer;
  padding: 12px 16px 12px 0px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid;

  &:hover {
    font-family: 'themixarabicBold', sans-serif;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 767px) {
    text-align: center;
    padding: 9px 20px;
    border: solid 1px #bbb;
    font-family: 'themixarabicPlain', sans-serif;
    border-radius: 45px;
    font-size: 16px;
    margin-right: 15px;
    margin-bottom: 12px;
    text-transform: none;
    width: auto;
    letter-spacing: 0;
    box-shadow: none;
    color: #000;
  }

  &.${tabUnstyledClasses.selected} {
    color: #48a2b8;
    display: flex;

    font-family: 'themixarabicBold', sans-serif;

    @media (max-width: 767px) {
      border-color: #2d6a80;
      background: #2d6a80;
      color: #fff;
    }
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)``;

const TabsList = styled(TabsListUnstyled)`
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
`;

export default function MediaCenterComp() {
  const [selectQuery, setSelectQuery] = useState(0);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const tabs = [
    { label: t('News'), component: <News /> },
    { label: t('Videos'), component: <Video /> },
    { label: t('PICTURES'), component: <Pictures /> },
    {
      label: t('MAWANI NEWSLETTER'),
      component: <PortsBulletin />,
    },
    { label: t('DIGITAL LIBRARY'), component: <DigitalLibrary /> },
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get('o');
    let localStoreLocale = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreLocale);

    if (code === 'News') {
      setSelectQuery(0);
    } else if (code === 'Photos') {
      setSelectQuery(2);
    } else if (code === 'Video') {
      setSelectQuery(1);
    } else if (code === 'Mawani-Newsletter') {
      setSelectQuery(3);
    } else if (code === 'library') {
      setSelectQuery(4);
    }
  }, [location.search, i18n]);

  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <h1 className="page_title">{t('Media Center')}</h1>

          <div className={`${styles.mediaCenterContent}`}>
            <TabsUnstyled
              key={`tab-${selectQuery}`}
              defaultValue={selectQuery}
              orientation="vertical"
              className={`${styles.mediaContain}`}
              value={selectQuery}
              onChange={(e) =>
                setSelectQuery(
                  Number(e?.currentTarget?.dataset?.index || 0) || 0,
                )
              }>
              <div className={`${styles.sideBar}`}>
                <TabsList>
                  {tabs.map((tab, index) => (
                    <Tab key={index} data-index={index}>
                      {tab.label}
                    </Tab>
                  ))}
                </TabsList>
                <div className={`${styles.sideBarSub}`}>
                  <span>
                    {t(
                      JSON.stringify(
                        'Find out about the latest developments in the port sector',
                      ).replace(/\"/g, ''),
                    )}
                  </span>
                </div>
              </div>
              <div className={`${styles.tabContent}`}>
                {tabs.map((tab, index) => (
                  <TabPanel value={index} key={index}>
                    {tab.component}
                  </TabPanel>
                ))}
              </div>
            </TabsUnstyled>
          </div>
        </div>
      </div>
    </>
  );
}
