import React, { createContext, useContext, useEffect, useState } from 'react';
import http from '../http';
import axios from 'axios';
const PortContext = createContext();

export const usePortContext = () => {
  return useContext(PortContext);
};

export const PortProvider = ({ children }) => {
  const [ports, setPorts] = useState([]);
  const [catPorts, setCatPorts] = useState('');
  const [slugPort, setSlugPort] = useState('');
  const [nav_value, set_nav_value] = useState('PortList');
  const [portId, setPortId] = useState('');
  const [portval, setPort] = useState('');
  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }
  // change navigation value
  const changeNavValue = (value) => {
    set_nav_value(value);
  };
  // get port id value
  const getPortId = (id) => {
    setPortId(id);
  };

  const GetByCategory = (value, locale) => {
    useEffect(() => {
      http
        .get(
          '/api/ports?populate=deep&filters[Category][Name][$contains]=' +
            value +
            '&locale=' +
            locale,
        )
        .then((response) => {
          setCatPorts(response.data.data);
        });
    }, []);
  };
  const GetBySlug = (value, locale) => {
    useEffect(() => {
      http
        .get(
          '/api/ports?populate=*&filters[Slug][$contains]=' +
            value +
            '&locale=' +
            locale,
        )
        .then((response) => {
          setSlugPort(response.data.data[0].attributes);
        });
    }, []);
  };
  const GetByPort = (value, locale) => {
    useEffect(() => {
      http
        .get(
          '/api/ports?populate=*&filters[port][$contains]=' +
            value +
            '&locale=' +
            locale,
        )
        .then((response) => {
          setPort(response.data.data[0].attributes);
        });
    }, []);
  };
  const GetAllPorts = (locale) => {
    http.get('/api/ports?populate=*&locale=' + locale).then((response) => {
      setPorts(response.data.data);
    });
  };
  const GetAllPortsPage = (locale) => {
    useEffect(() => {
      http.get('/api/ports?populate=*&locale=' + locale).then((response) => {
        setPorts(response.data.data);
      });
    }, []);
  };

  useEffect(() => {
    http.get('/api/ports?populate=*&locale=' + localStore).then((response) => {
      setPorts(response.data.data);
    });
  }, []);

  const value = {
    ports,
    changeNavValue,
    nav_value,
    getPortId,
    portId,
    catPorts,
    GetByCategory,
    GetBySlug,
    slugPort,
    GetByPort,
    portval,
    GetAllPortsPage,
    GetAllPorts,
  };

  // context provider
  return <PortContext.Provider value={value}>{children}</PortContext.Provider>;
};
