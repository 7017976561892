import { fade } from '@material-ui/core';
import Markdown from 'markdown-to-jsx';
import React, {useEffect, useState } from 'react';
import styles from './fader.module.scss';

const Fader = ({text,interval}) => {
    const [messageIndex, setMessageIndex] = useState(0);
    const [fadeProp, setFadeProp] = useState(styles.fadeIn);
    useEffect(() => {
        const fades = setInterval(() => {
            setFadeProp(styles.fadeOut)
        },interval-1000)
        return () => { clearInterval(fades) }
    }, [fadeProp])

    useEffect(() => {
        const timeout = setInterval(() => {
            if (messageIndex >= text.length-1) {
                setMessageIndex(0);
            } else {
                setMessageIndex(messageIndex => messageIndex +1)
            }
            setFadeProp(styles.fadeIn);
        },interval)

        return () => { clearInterval(timeout) }

    }, [messageIndex])

    return (
        <div className={fadeProp + ' ' + styles.headline}>{text[messageIndex] && <Markdown>{text[messageIndex]}</Markdown>}</div>
    ) 
}
export default Fader;