import AliceCarousel from 'react-alice-carousel';
import { Player } from 'video-react';
import H6 from '@material-tailwind/react/Heading6';
import environment from '../../assets/img/icons/icn-envir.svg';
import licence from '../../assets/img/icons/icn-license.svg';
import regulations from '../../assets/img/icons/icn-reg.svg';
import safety from '../../assets/img/icons/icn-safety.svg';
import backArrow from '../../assets/img/backArrow.png';
import forwardArrow from '../../assets/img/forwardArrow.png';
import 'video-react/dist/video-react.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import styles from './Home.module.scss';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import info from '../../../src/assets/img/icons/info2.svg';
import mawaniVideo from '../../assets/videos/mawani_video.mp4';

export default function ServiceSectionTitle({ heading, children }) {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);
  return (
    <div className="container_max">
     {/* <h2 className={styles.homeServiceTitle}>
        <strong>{t('SERVICES')}</strong>
        {heading}
      </h2>

      <div style={{display:"flex",marginTop:"1rem",marginBottom:"1rem"}}>
                <Player
      playsInline
     src="https://www.youtube.com/watch?v=cMHVMG2-kjY"
    />
                </div> 
      <div className={`mb-14 ${styles.responsiveIframe}`}>
      <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/JhsdOe2xVas"
        /> 
        <video controls muted autoPlay width="100%">
          <source src={mawaniVideo} type="video/mp4" />
        </video>
      </div>*/}
      <div className={`${styles.serviceStateWrap}`}>
        <a href="/regulations" className={styles.serviceState}>
          <div className={styles.round}>
            <img src={regulations} alt="" className={styles.roundImage} />
          </div>
          <strong>{t('REGULATIONS & CIRCULARS')}</strong>
        </a>
        <a href="/e-services?o=License" className={styles.serviceState}>
          <div className={styles.round}>
            <img src={licence} alt="" className={styles.roundImage} />
          </div>
          <strong>{t('LICENCES & PERMITS')}</strong>
        </a>

        <a href="/about-us" className={styles.serviceState}>
          <div className={styles.round}>
            <img src={info} alt="" className={styles.roundImage} />
          </div>
          <strong>{t('ABOUT US')}</strong>
        </a>

        {/* <a href="/services-and-capabilities" className={styles.serviceState}> */}
        {/*   <div className={styles.round}> */}
        {/*     <img src={safety} alt="" className={styles.roundImage} /> */}
        {/*   </div> */}
        {/*   <strong>{t("SAFETY & SECURITY")}</strong> */}
        {/* </a> */}
        {/**/}
        {/* <a href="/services-and-capabilities" className={styles.serviceState}> */}
        {/*   <div className={styles.round}> */}
        {/*     <img src={environment} alt="" className={styles.roundImage} /> */}
        {/*   </div> */}
        {/*   <strong>{t("ENVIROMENT & COMMUNITY")}</strong> */}
        {/* </a> */}
        {/**/}
      </div>
    </div>
  );
}
