import React, { createContext, useContext, useEffect, useState } from 'react';
import http from '../http';
import axios from 'axios';
const LibraryContext = createContext();

export const useLibraryContext = () => {
    return useContext(LibraryContext);
};

export const LibraryProvider = ({children}) => {
    const [libraries, setLibraries] = useState("");
    const [catLibraries, setCatLibraries] = useState("");
    const [slugLibrary, setSlugLibrary] = useState("");
    const [nav_value, set_nav_value] = useState("LibraryList");
    const [libraryId, setLibraryId] = useState("");
    var localStore = localStorage.getItem('locale');
    if(localStore == null || localStore == undefined){
        localStore = "ar";
    }
    // change navigation value
    const changeNavValue = (value) => {
        set_nav_value(value);
    };
    // get library id value
    const getLibraryId = (id) => {
        setLibraryId(id);
    };

    const GetLibraries = (locale) =>{
            http.get("/api/libraries?populate=*&locale="+locale)
            .then((response) => {
              setLibraries(response.data.data);
            })
    }
    const GetBySlug = (value,locale) =>{
        useEffect(()=>{
            http.get("/api/libraries?populate=*&filters\[Slug\][$contains]="+value+"&locale="+locale)
            .then((response) => {
                setSlugLibrary(response.data.data[0].attributes);
            })
        }, []);
    }

    useEffect(()=>{
        http.get("/api/libraries?populate=*&locale="+localStore)
        .then((response) => {
          setLibraries(response.data.data);
        })
    }, []);

    const value = {
        libraries,
        changeNavValue,
        nav_value,
        getLibraryId,
        libraryId,
        catLibraries,
        GetBySlug,
        slugLibrary,
        GetLibraries
    };
    
    // context provider
    return(
        <LibraryContext.Provider value={value}>
            {children}
        </LibraryContext.Provider>
    )
}; 