import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import KeepMeInformed from 'components/home/KeepMeInformed';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import PortsComp from 'components/Ports';

export default function Ports() {
  return (
    <>
      <Navbar />
      <PortsComp />
      {/* <KeepMeInformed /> */}
      <Information />
      <Subscribe />
      <DefaultFooter />
    </>
  );
}
