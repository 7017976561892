import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
  const UnderConstruction = () => {
    const { t, i18n } = useTranslation();
    // useEffect(() => {
    //   var localStoreVar = localStorage.getItem('locale');
    //   i18n.changeLanguage(localStoreVar);
    // },[])
    return (
        <div className="under_construction">
          <h2>{t("Under Construction")}</h2>
          <p>{t("This service is coming soon.")}</p> 
        </div>
      )
    }
    export default UnderConstruction;