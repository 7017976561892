import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import KeepMeInformed from 'components/home/KeepMeInformed/index';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import AnnouncementComp from 'components/Announcement';
export default function Announcement() {
    return (
        <>
            <Navbar />
            <AnnouncementComp />
            {/* <KeepMeInformed /> */}
            <Information />
            <Subscribe />
            <DefaultFooter />
        </>
    );
}
