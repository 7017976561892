import { TextField } from '@mui/material';
import styles from './Subscribe.module.scss';

export default function Subscribe() {
  return (
    <>

    {/* <div className={styles.subscribe}>
      <div className="container_max">
        <h2>Subscribe and be the first to know</h2>
        <div className={styles.subscribeForm}>
        <input type="text" placeholder='Email Address'></input>
          <button className={styles.subscribeButton}>Subscribe</button>
          
        </div>
      </div>
    </div> */}

      
    
    </>
  );
}
