import styles from './AboutUs.module.scss';
import { useAboutContext } from 'contexts/AboutContext';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from 'utils/constants';
import { useEffect, useState } from 'react';

const Board = () => {
  const { directors } = useAboutContext();
  const { t, i18n } = useTranslation();
  const [rowOne, setRowOne] = useState();
  const [rowTwo, setRowTwo] = useState();

  useEffect(() => {
    setRowOne(directors.slice(1, 4));
    setRowTwo(directors.slice(4));
  }, [directors]);

  return (
    <div className={styles.aboutContent}>
      <h2>{t('Board of Directors')}</h2>
      <p className="mb-5">
        {t(
          'The Board of Directors of the Saudi Ports Authority “Mawani” is the supreme authority responsible for the management of its affairs. The members of the Board are:',
        )}
      </p>
      <div className={styles.boardTop}>
        {directors &&
          directors?.map(
            (
              { id, attributes: { Name, Role, About, Image, Display_Order } },
              i,
            ) => {
              if (Display_Order === 1) {
                return (
                  <div className={styles.boardMember}>
                    <div className={styles.boardIMG}>
                      {Image != null && Image.data != null && (
                        <img src={BASE_URL + Image.data.attributes.url} />
                      )}
                    </div>
                    <div>
                      <h3>{Name}</h3>
                      <strong>{Role}</strong>
                      {/* <p className="italic">{About}</p> */}
                    </div>
                  </div>
                );
              }
            },
          )}
      </div>
      {/* <div  className={styles.boardTop} >
        <div className={styles.boardMember} >
                <div className={styles.boardIMG} >
                    <img src={boardSaleh} />
                </div>
                <div>
                    <h3>H.E. Eng. Saleh bin Nasser Al-Jasser</h3>
                    <strong>Minister of Transport</strong>
                    <p className="italic">“With Vision 2030 as its guiding light, Mawani is well on its way to seeing its development plan through, vividly aware of just how vital our seaports are to the performance and quality of the marine transport sector.” </p>
                </div>
            </div>
            </div> */}
      <div className={styles.boardWrapRowOne}>
        {rowOne &&
          rowOne?.map(
            (
              { id, attributes: { Name, Role, About, Image, Display_Order } },
              i,
            ) => {
              return (
                <div className={styles.boardMember}>
                  <div className={styles.boardIMG}>
                    {Image != null && Image.data != null && (
                      <img src={BASE_URL + Image.data.attributes.url} />
                    )}
                  </div>
                  <div>
                    <h3>{Name}</h3>
                    <strong>{Role}</strong>
                    {/* <p className="italic">{About}</p> */}
                  </div>
                </div>
              );
            },
          )}
      </div>

      <div className={styles.boardWrap}>
        {rowTwo &&
          rowTwo?.map(
            (
              { id, attributes: { Name, Role, About, Image, Display_Order } },
              i,
            ) => {
              return (
                <div className={styles.boardMember}>
                  <div className={styles.boardIMG}>
                    {Image != null && Image.data != null && (
                      <img src={BASE_URL + Image.data.attributes.url} />
                    )}
                  </div>
                  <div>
                    <h3>{Name}</h3>
                    <strong>{Role}</strong>
                    {/* <p className="italic">{About}</p> */}
                  </div>
                </div>
              );
            },
          )}
      </div>
      {/* <div  className={styles.boardWrap} >
            <div  className={styles.boardMember}>
            <div className={styles.boardIMG} >
                    <img src={boardAbanmi} />
                </div>
                <div>
                    <h3>H.E. Eng. Suhail bin Mohammed Abanmi</h3>
                    <strong>Governor of Zakat, Tax and Customs Authority </strong>
                    <p className="italic">“Investing time and resources in every possible opportunity to achieve efficiencies and boost the Kingdom's competitiveness on a global level, Mawani is working on some qualitative improvements that would help build a healthy, sustainable national maritime industry.” </p>
                </div>
            </div>

            <div  className={styles.boardMember}>
                <div className={styles.boardIMG} >
                    <img src={boardBadr} />
                </div>
                <div>
                    <h3>Mr. Badr Abdullah Alsinaidi</h3>
                    <strong>Representative, Ministry of Transport </strong>
                    <p className="italic">“To take the marine transport sector to the next level, we've paved the way for more and better projects and initiatives that will help achieve the targets set by our leadership.” </p>
                </div>
            </div>

            <div  className={styles.boardMember}>
                <div className={styles.boardIMG} >
                    <img src={boardOmar} />
                </div>
                <div>
                    <h3>H.E. Omar Talal Hariri</h3>
                    <strong>President of the Saudi Ports Authority “Mawani”</strong>
                    <p className="italic">“Today, Saudi Arabia's seaports prove that they are well and truly capable of meeting the objectives and responsibilities that have been placed upon them through continuous improvement of their capabilities and services, to the point where they have now become key stops on the world's most vital shipping routes and powerhouses of international trade.” </p>
                </div>
            </div>

            <div  className={styles.boardMember}>
                <div className={styles.boardIMG} >
                    <img src={boardFaisal} />
                </div>
                <div>
                    <h3>H.E. Mr. Faisal Mohammed Alsharif</h3>
                    <strong>Representative, Ministry of Finance </strong>
                    <p className="italic">“Saudi Arabia’s seaports are vital not only to the country’s economic growth, but also to sustainable, reliable regional and international trade and the continuity of every economic or industrial concern, bar none.” </p>
                </div>
            </div>

            <div  className={styles.boardMember}>
                <div className={styles.boardIMG} >
                    <img src={boardYousef} />
                </div>
                <div>
                    <h3>H.E. Eng. Yousef Abdullah Al-Bunyan</h3>
                    <strong>Representative, the Private Sector </strong>
                    <p className="italic">“Being indispensable contributors to growth, Saudi ports are driving the marine transport sector's economic and industrial impact forward.” </p>
                </div>
            </div>

            <div  className={styles.boardMember}>
                <div className={styles.boardIMG} >
                    <img src={boardSalman} />
                </div>
                <div>
                    <h3>Mr. Salman Mohammed AlTukhaifi</h3>
                    <strong>Representative, Ministry of Commerce </strong>
                    <p className="italic">“Thanks to the Kingdom’s strategic location, investing in every capability that Saudi seaports have to offer is a surefire way to boost the country’s infinitely promising national economy.” </p>
                </div>
            </div>

            <div  className={styles.boardMember}>
                <div className={styles.boardIMG} >
                    <img src={boardAbdulrahman} />
                </div>
                <div>
                    <h3>Dr. Abdulrahman Yousef Al-Aal</h3>
                    <strong>Representative, the Private Sector</strong>
                    <p className="italic">“The economic windfall of the prosperity witnessed by Saudi seaports can only bring in even more opportunities to sustain a successful, growing national economy.” </p>
                </div>
            </div>

            <div  className={styles.boardMember}>
                <div className={styles.boardIMG} >
                    <img src={boardMajid} />
                </div>
                <div>
                    <h3>Mr. Majid Abdulaziz Al Ibrahim</h3>
                    <strong>Representative, the Private Sector</strong>
                    <p className="italic">“Thanks to the leadership’s push to make a world-class logistics hub out of the Kingdom, Saudi seaports have been witnessing the windfall of runaway global demand.” </p>
                </div>
            </div>

         </div> */}
    </div>
  );
};
export default Board;
