import ServiceSectionTitle from './ServiceSectionTitle';
import styles from "./Home.module.scss";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

export default function ServiceSection() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, [])
  return (
    <section className={`${styles.serviceSectionWrap}`} style={{ backgroundColor: "#fafafa" }}>

      <ServiceSectionTitle heading={t(" - STEERED BY THE TEAM'S EXTENSIVE EXPERIENCE")} />

    </section>
  );
}
