import { AxiosResponse } from 'axios';
import { StrapiMetaPaginationType } from '../../types';
import http from '../../http';
import { CircularType } from 'components/Circulars/circular.types';
export const GetCirculars = (
  locale: string,
  pageIndex?: number,
  pageSize?: number,
  releaseYear?: 'all' | number,
) =>
  http
    .get(
      `/api/circulars?populate=*&locale=${locale}&pagination[pageSize]=${
        pageSize || 25
      }&pagination[page]=${pageIndex}${
        releaseYear && releaseYear !== 'all'
          ? `&filters[$and][0][ReleaseDate][$gte]=${releaseYear}-01-01&filters[$and][1][ReleaseDate][$lte]=${releaseYear}-12-31`
          : '&sort=ReleaseDate:desc'
      }`,
    )
    .then(
      (
        response: AxiosResponse<{
          data: CircularType[];
          meta: StrapiMetaPaginationType;
        }>,
      ) => response.data,
    );
