import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import KeepMeInformed from 'components/home/KeepMeInformed/index';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import PortsEmployees from 'components/PortsEmployees';
import Error from 'components/Error/Error';
export default function MediaCenter() {
  return (
    <>
      <Navbar />
      <Error />
      {/* <KeepMeInformed /> */}
      <Information />
      <Subscribe />
      <DefaultFooter />
    </>
  );
}
