import H6 from '@material-tailwind/react/Heading6';
import React, { useEffect, useState } from "react";
import styles from './States.module.scss';
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import states1 from '../../assets/img/states1.png';
import states2 from '../../assets/img/states2.png';
import states3 from '../../assets/img/states3.png';
import states4 from '../../assets/img/states4.png';
import states5 from '../../assets/img/states5.png';
import { useTranslation } from 'react-i18next';

const States = ({ className, ...rest }) => {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, [])
  return (
    <div className={`container_max ${styles.stateContainer}`}>

      <div className={`flex justify-between ${styles.stateWrap}`}>





        <div className={styles.state}>
          <div className={styles.round}>
            <img src={states1} alt="" className={styles.roundImage} />
          </div>
          <CountUp end={10} {...rest} start={viewPortEntered ? null : 0}>
            {({ countUpRef, start }) => (
              <VisibilitySensor active={!viewPortEntered}
                onChange={isVisible => {
                  if (isVisible) {
                    setViewPortEntered(true);
                  }
                }}
                delayedCall>
                <strong ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
          <span>{t("PORTS")}</span>
        </div>


        <div className={styles.state}>
          <div className={styles.round}>
            <img src={states2} alt="" className={styles.roundImage} />
          </div>
          <CountUp end={291}  {...rest} start={viewPortEntered ? null : 0}>
            {({ countUpRef, start }) => (
              <VisibilitySensor active={!viewPortEntered}
                onChange={isVisible => {
                  if (isVisible) {
                    setViewPortEntered(true);
                  }
                }}
                delayedCall>
                <strong ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
          <span>{t("BERTHS")}</span>
        </div>

        <div className={styles.state}>
          <div className={styles.round}>
            <img src={states3} alt="" className={styles.roundImage} />
          </div>
          <CountUp end={15}  {...rest} start={viewPortEntered ? null : 0} suffix="K">
            {({ countUpRef, start }) => (
              <VisibilitySensor active={!viewPortEntered}
                onChange={isVisible => {
                  if (isVisible) {
                    setViewPortEntered(true);
                  }
                }}
                delayedCall>
                <strong ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
          <span>{t("SHIPS")}</span>
        </div>

        <div className={styles.state}>
          <div className={styles.round}>
            <img src={states4} alt="" className={styles.roundImage} />
          </div>
          <CountUp end={13}  {...rest} start={viewPortEntered ? null : 0} suffix="M">
            {({ countUpRef, start }) => (
              <VisibilitySensor active={!viewPortEntered}
                onChange={isVisible => {
                  if (isVisible) {
                    setViewPortEntered(true);
                  }
                }}
                delayedCall>
                <strong ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
          <span>{t("CONTAINERS")}</span>
        </div>

        <div className={styles.state}>
          <div className={styles.round}>
            <img src={states5} alt="" className={styles.roundImage} />
          </div>
          <CountUp end={1.1}  {...rest} start={viewPortEntered ? null : 0} decimals={1} suffix="B">
            {({ countUpRef, start }) => (
              <VisibilitySensor active={!viewPortEntered}
                onChange={isVisible => {
                  if (isVisible) {
                    setViewPortEntered(true);
                  }
                }}
                delayedCall>
                <strong ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
          <span>{t("TONS")}</span>
        </div>


      </div>


    </div>
  );
};
export default States;
