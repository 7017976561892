import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import styles from './AboutUs.module.scss';
import { components } from './aboutus.constants';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Tab = styled(TabUnstyled)`
  cursor: pointer;
  padding: 15px 20px;

  border: 1px solid #e3e3e3;
  font-family: 'themixarabicPlain', sans-serif;
  display: flex;
  flex-direction: column;

  align-items: center;
  font-size: 17px;

  transition: 0.2s;

  &:hover {
    color: #fff;
    background: #2d6a80;
    border-color: #2d6a80;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(176deg)
        brightness(150%) contrast(150%);
    }
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 767px) {
    font-size: 16px;

    line-height: 20px;
  }

  @media (max-width: 639px) {
  }

  &.${tabUnstyledClasses.selected} {
    color: #fff;
    background: #2d6a80;
    border-color: #2d6a80;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(176deg)
        brightness(150%) contrast(150%);
    }
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)``;

const TabsList = styled(TabsListUnstyled)`
  margin-bottom: 55px;
  display: grid;
  grid-gap: 20px;

  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 639px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`;

let defaultView = 0;
if (window.location.hash) {
  if (window.location.hash.includes('board')) {
    defaultView = 1;
  }
  if (window.location.hash.includes('chart')) {
    defaultView = 2;
  }
  if (window.location.hash.includes('services')) {
    defaultView = 3;
  }
}
export default function AboutUsComp() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);
  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>

      <div className="container_max">
        <div className="page_wrapper">
          <h1 className="page_title">{t('About Us')}</h1>
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="container_max">
          <div className="page_wrapper_sub">
            <TabsUnstyled
              defaultValue={defaultView}
              orientation="horizontal"
              className="">
              <div className="">
                <TabsList>
                  {components.map((item, index) => (
                    <Tab key={index} value={index}>
                      {item.icon}
                      {/* {item.title} */}
                      {t(
                        JSON.stringify(item.title.props.children).replace(
                          /\"/g,
                          '',
                        ),
                      )}
                    </Tab>
                  ))}
                </TabsList>
              </div>
              <div className="">
                {components.map((item, index) => (
                  <TabPanel value={index} keyindex>
                    <item.component />
                  </TabPanel>
                ))}
              </div>
            </TabsUnstyled>
          </div>
        </div>
      </div>
    </>
  );
}
