import styles from './EServices.module.scss';
import { useServiceContext } from 'contexts/eServiceContext';
import { useTranslation } from 'react-i18next';

export const Biddings3 = () => {
  const { preQualificationBindings, preQualificationBindingsTable } =
    useServiceContext();
  const { t } = useTranslation();
  return (
    <>
      <h2>{t('An Invitation for Prequalification of the Operators')}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: preQualificationBindings.attributes.Description,
        }}
      />
      <br />
      <table className={`${styles.tablePlain}`}>
        <tbody>
          {preQualificationBindingsTable &&
            preQualificationBindingsTable.map((item) => {
              return (
                <tr>
                  <td>{item.attributes.Label}</td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.attributes.Date }}
                    />
                  </td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.attributes.Content,
                      }}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <span className={`${styles.Note} text-[red]`}>
        {t(
          "Note: Companies that have been pre-qualified for providing marine services in Saudi ports will be considered pre-qualified and don't need to submit a pre-qualification request unless otherwise announced.",
        )}
        ​
      </span>
    </>
  );
};
