import React from 'react';
import styles from './Home.module.scss';
import { useTranslation } from 'react-i18next';

export default function HistoryTextBox({ textItem, slug }) {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.h_textfullbox}>
      <p
        className={styles.h_textbox}
        style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
        {textItem}
      </p>
      <a className={styles.h_readMore} href={'/article?a=' + slug}>
        {t('Read More')}
      </a>
      {/* todo: use article page instead */}
    </div>
  );
}
