import styles from './MediaCenter.module.scss';
import { useVideoContext } from 'contexts/VideoContext';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from 'utils/constants';

const MediaCenter = ({ limit = null }) => {
  const { t, i18n } = useTranslation();
  const { GetAllVideosPage, videos } = useVideoContext();
  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }
  GetAllVideosPage(localStore);
  return (
    <div>
      <div className={`${styles.mediaCenterGrid}`}>
        {videos &&
          videos
            ?.sort(
              (a, b) =>
                new Date(b?.attributes?.PublishDate || -1) -
                new Date(a.attributes?.PublishDate || -1),
            )
            ?.slice(0, limit || videos.length)
            .map(({ id, attributes: { Summary, URL, Thumbnail } }, i) => (
              <a
                key={id}
                href={URL}
                target="_blank"
                className={`${styles.videoCard}`}
                rel="noreferrer">
                {Thumbnail != null && Thumbnail.data != null && (
                  <img
                    src={BASE_URL + Thumbnail.data.attributes.url}
                    className="mb-3"
                  />
                )}
                <strong className={`caption text-primary ${styles.videoCard}`}>
                  {Summary}
                </strong>
              </a>
            ))}
      </div>
    </div>
  );
};
export default MediaCenter;
