import React, { createContext, useContext, useEffect, useState } from 'react';
import http from '../http';
import axios from 'axios';
const AboutContext = createContext();

export const useAboutContext = () => {
  return useContext(AboutContext);
};

export const AboutProvider = ({ children }) => {
  const [abouts, setAbouts] = useState('');
  const [directors, setDirectors] = useState('');
  const [services, setServices] = useState('');
  const [organizationChart, setOrganizationalChart] = useState('');
  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }

  const GetOrgChartPage = (locale) => {
    http
      .get('/api/organizational-charts?populate=*&locale=' + locale)
      .then((response) => {
        setOrganizationalChart(response.data.data);
      });
  };
  const GetAboutUsPage = (locale) => {
    http
      .get('/api/organizational-charts?populate=*&locale=' + locale)
      .then((response) => {
        setOrganizationalChart(response.data.data);
      });
    http.get('/api/abouts?populate=*&locale=' + locale).then((response) => {
      setAbouts(response.data.data);
    });
    http
      .get('/api/directors?sort=Display_Order:asc&populate=*&locale=' + locale)
      .then((response) => {
        setDirectors(response.data.data);
      });
    http
      .get('/api/organizational-charts?populate=*&locale=' + locale)
      .then((response) => {
        setOrganizationalChart(response.data.data);
      });
    http.get('/api/services?populate=*&locale=' + locale).then((response) => {
      setServices(response.data.data);
    });
  };

  useEffect(() => {
    http.get('/api/abouts?populate=*&locale=' + localStore).then((response) => {
      setAbouts(response.data.data);
    });
    http
      .get(
        '/api/directors?sort=Display_Order:asc&populate=*&locale=' + localStore,
      )
      .then((response) => {
        setDirectors(response.data.data);
      });
    http
      .get('/api/organizational-charts?populate=*&locale=' + localStore)
      .then((response) => {
        setOrganizationalChart(response.data.data);
      });
    http
      .get('/api/services?populate=*&locale=' + localStore)
      .then((response) => {
        setServices(response.data.data);
      });
  }, []);

  const value = {
    abouts,
    directors,
    organizationChart,
    GetAboutUsPage,
    GetOrgChartPage,
    services,
  };

  // context provider
  return (
    <AboutContext.Provider value={value}>{children}</AboutContext.Provider>
  );
};
