import { useEffect, useState } from 'react';
import styles from './Announcement.module.scss';
import { useTranslation } from 'react-i18next';
import http from '../../http';
import { useExternalContext } from 'contexts/ExternalContext';

const AnnouncementComp = () => {
    const { announcements, getAnnouncements } = useExternalContext();
    const [announcement, setAnnouncement] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        http
            .get('/api/home-page-announcements?locale=ar')
            .then((response) => {
                setAnnouncement(response.data.data);
            });
    }, []);
    useEffect(() => {
        getAnnouncements()
    }, []);
    function OneAnnouncement() {
        for (let i = 0; i <= announcement?.length; i++) {
            for (let j = 0; j <= announcements?.length; j++) {
                if (announcement?.[i]?.attributes?.AnnouncementId && announcements?.[j]?.id) {
                    if (announcement?.[i]?.attributes?.AnnouncementId === announcements?.[j]?.id) {
                        return (
                            <div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: announcements?.[j]?.details,
                                    }}
                                    className={`${styles.announcement}`}
                                />
                            </div>
                        )
                    }
                }
            }
        }
        return (<div></div>)
    }
    return (
        <>
            <div className={`${styles.bgHeaderBackground}`}></div>
            <div className="container_max">
                <div className="page_wrapper">
                    <h1 className="page_title">
                        {t('Announcements')}
                    </h1>
                </div>
            </div><div className="overflow-hidden">
                <div className="container_max">
                    <div className="page_wrapper_sub">
                        <div className={`${styles.title}`}>{t('PopUpTitle')}</div>

                        {/* temp */}
                        {/* <div className={`${styles.subTitle}`}>
                        {t('PopUpTitleTwo')}
                    </div> */}
                        {/* <div className={`${styles.announcement}`}>
                            {t('PopUpAnnouncement')}
                        </div>
                        <div className={`${styles.afterAnnouncement}`}>
                            {t('PopUpBless')}
                        </div>
                        <div className={`${styles.table}`}>
                            <div>
                                <h1>General information.2</h1>
                                <table>
                                    <tr>
                                        <th>VESSEL NAME</th>
                                        <td>FLOATING DOCK 19000 DWT</td>
                                    </tr>
                                    <tr>
                                        <th>TYPE OF VESSEL</th>
                                        <td>FLOATING DOCK</td>
                                    </tr>
                                    <tr>
                                        <th>CLASS</th>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <th>Length over pontoon</th>
                                        <td>215.00 m</td>
                                    </tr>
                                    <tr>
                                        <th>Length over all</th>
                                        <td>200.00</td>
                                    </tr>
                                    <tr>
                                        <th>Free inside width between dock runways</th>
                                        <td>33.50 m</td>
                                    </tr>
                                    <tr>
                                        <th>DEPTH (MLD)</th>
                                        <td>15.90 m</td>
                                    </tr>
                                    <tr>
                                        <th>DEPTH OF PONTOON (MLD)</th>
                                        <td>4.10 m</td>
                                    </tr>
                                    <tr>
                                        <th>Draft without load</th>
                                        <td>3.70 m</td>
                                    </tr>
                                    <tr>
                                        <th>MAXIMUM IMMERSION DRAUGHT</th>
                                        <td>13.4m</td>
                                    </tr>
                                    <tr>
                                        <th>LIGHTSHIP</th>
                                        <td>7149.84 Tonnes</td>
                                    </tr>
                                    <tr>
                                        <th>LCG</th>
                                        <td>100.4m-AP</td>
                                    </tr>
                                    <tr>
                                        <th>VCG</th>
                                        <td>6.85m</td>
                                    </tr>
                                </table>
                            </div>
                        </div> */}
                        {/* temp */}

                        <OneAnnouncement />
                    </div>
                </div>
            </div >
        </>
    );
};

export default AnnouncementComp;
