import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getURLQuery } from "utils/urlHelper";

export const useLanguage = () =>{
    const {i18n} = useTranslation();
    const location = useLocation();
    useEffect(() => {
        //check the query param
      
    const langParam = getURLQuery(location.search,"language");
    if(langParam !== "" &&langParam !== undefined  && langParam !== null){
        localStorage.setItem('locale', langParam);
        document.body.dir = langParam === 'ar' ? 'rtl' :'ltr';
        i18n.changeLanguage(langParam);
    }

    if (localStorage?.getItem('locale') === undefined || localStorage?.getItem('locale') === null ) {
        localStorage.setItem('locale', 'ar');
        document.body.dir = 'rtl';
        i18n.changeLanguage('ar');
    }

    if(localStorage?.getItem('locale') === 'en'){
        document.body.dir = 'ltr';
        i18n.changeLanguage('en');
    }else{
        document.body.dir = 'rtl';
        i18n.changeLanguage('ar');
    }
  }, []); //default language
}