import { useEffect, useState } from 'react';
import React from 'react';
import styles from './Ports.module.scss';
import { usePortContext } from 'contexts/PortContext';
import { useTranslation } from 'react-i18next';
import RasTanuraBackgroundImage from '../../assets/img/ports/Dammam_Port.jpg';
import { BASE_URL } from '../../utils/constants';
import { useLocation } from 'react-router-dom';

export default function Ports() {
  const { ports } = usePortContext();
  const { t, i18n } = useTranslation();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const queryPort = query.get('port');
  const queryPortIndex =
    queryPort &&
    ports.indexOf(ports.find((port) => queryPort == port.attributes.Port));

  const [active, setActive] = useState(null);

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (queryPortIndex > 0) setActive(queryPortIndex);
  }, [queryPortIndex]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [active]);

  if (!ports || ports?.length <= 0) return <div className="min-h-screen"></div>;

  return (
    <>
      <div
        className={`${styles.bgHeaderBackground}`}
        style={{
          backgroundImage: `url(${
            ports[active]?.attributes?.CoverImage?.data?.attributes?.url
              ? `${BASE_URL}${ports[active]?.attributes?.CoverImage.data.attributes.url}`
              : RasTanuraBackgroundImage
          })`,
        }}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <h1 className={`page_title ${styles.portTitle}`}>
            {t('Saudi Ports')}
          </h1>
          <a className="mt-10">
            {t(
              'Serve as vital economic and commercial streams, and they play a key role in the growth of regional and global trade movement',
            )}
          </a>
          <div className={styles.portsContainer}>
            {ports.map((port, index) => {
              let portclass = port?.attributes?.Port?.toLowerCase()?.replaceAll(
                ' ',
                '-',
              );
              let classes = `${styles.portItem}  ${styles[portclass]} ${index === active ? styles.activePort : ""}`;
              return (
                <div
                  key={index}
                  className={classes}
                  onClick={() => setActive(index)}
                  style={{
                    backgroundImage: `url(${
                      port.attributes?.CoverImage?.data?.attributes?.url
                        ? `${BASE_URL}${port.attributes?.CoverImage.data.attributes.url}`
                        : RasTanuraBackgroundImage
                    })`,
                   
                  }}>
                  <span style={{ textTransform: 'capitalize' }}>
                    {port?.attributes?.Port}
                  </span>
                </div>
              );
            })}
          </div>
          <p className="mt-10">{ports[active]?.attributes.Description}</p>

          <div className="mt-10">
            <div>
              <div
                className={'flex flex-col lg:flex-row justify-between gap-4'}>
                <div className={'max-w-3xl'}>
                  {active != null && (
                    <h2 className={'mt-0'}>
                      <strong>{t('ASSETS AND FACILITIES')}</strong>
                    </h2>
                  )}
                  <div
                    className={`${styles.articleWrap}`}
                    dangerouslySetInnerHTML={{
                      __html: ports[active]?.attributes.AssetsAndFacilities,
                    }}
                  />
                </div>
                <div className={styles.portsGrid}>
                  <div>
                    <div className="grid grid-cols-2 ">
                      {ports[active]?.attributes?.Area && (
                        <>
                          <div
                            className={`${styles.assetTableLeft} pl-5 pr-5 mb-1`}>
                            {t('Area')}
                          </div>
                          <div
                            className={`${styles.assetTableRight} pl-5 pr-5 mb-1`}>
                            {ports[active]?.attributes.Area}
                          </div>
                        </>
                      )}
                      {ports[active]?.attributes?.Berths && (
                        <>
                          <div
                            className={`${styles.assetTableLeft} pl-5 pr-5 mb-1`}>
                            {t('Berths')}
                          </div>
                          <div
                            className={`${styles.assetTableRight} pl-5 pr-5 mb-1`}>
                            {ports[active]?.attributes?.Berths}
                          </div>
                        </>
                      )}
                      {ports[active]?.attributes?.Terminals && (
                        <>
                          <div
                            className={`${styles.assetTableLeft} pl-5 pr-5 mb-1`}>
                            {t('Terminals')}
                          </div>
                          <div
                            className={`${styles.assetTableRight} pl-5 pr-5 mb-1`}>
                            {ports[active]?.attributes?.Terminals}
                          </div>
                        </>
                      )}
                      {ports[active]?.attributes?.Capacity && (
                        <>
                          <div
                            className={`${styles.assetTableLeft} pl-5 pr-5 mb-1`}>
                            {t('Capacity')}
                          </div>
                          <div
                            className={`${styles.assetTableRight} pl-5 pr-5 mb-1`}>
                            {ports[active]?.attributes?.Capacity}
                          </div>
                        </>
                      )}
                      {/* {ports[active]?.attributes?.Operators && (
                        <>
                          <div
                            className={`${styles.assetTableLeft} pl-5 pr-5 mb-1`}>
                            {t('Operators')}
                          </div>
                          <div
                            className={`${styles.assetTableRight} pl-5 pr-5 mb-1`}>
                            {ports[active]?.attributes?.Operators}
                          </div>
                        </>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {active != null && (
                <div>
                  <h3 className="text-lg">
                    <strong>{t('Ports Contact Us:')}</strong>
                  </h3>
                  <p>{ports[active]?.attributes?.ContactUsTitle}</p>
                  <p>
                    {t('Tel')} {ports[active]?.attributes?.TelNo} {t('Fax')}{' '}
                    {ports[active]?.attributes.FaxNo}
                  </p>
                  <p>{ports[active]?.attributes?.PostalAddress}</p>
                </div>
              )}
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
