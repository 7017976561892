import { useState, useEffect } from 'react';
import {
  MapContainer,
  TileLayer,
  Popup,
  Marker as LMarker,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import x from '../../assets/img/marker.png';
import { useTranslation } from 'react-i18next';
import styles from '../Ports/Ports.module.scss';
import { usePortContext } from 'contexts/PortContext';
import { MarkerLayer, Marker } from 'react-leaflet-marker';

function PortNode({ port }) {
  const { t } = useTranslation();

  if (!port || !port.Longitude || !port.Latitude) return <></>;
  return (
    <Marker
      position={[port.Latitude, port.Longitude]}
      size={[0, 0]}
      interactive
      riseOnHover={true}
      placement="center">
      <div className="flex gap-2 relative">
        <img src={''} alt="marker" style={{ width: 30, height: 30 }} />
        <p
          className="font-themixarabicLight text-white p-2 absolute"
          style={{
            minWidth: '10rem',
            left: 35,
            bottom: -2,
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderRadius: '0.3rem',
            textTransform:"capitalize"
          }}>
          {port.Port}
        </p>
      </div>
    </Marker>
  );
}

function PortDetails({ data }) {
  const { t } = useTranslation();

  const details = [
    { title: t('Area'), value: data.area },
    { title: t('Berths'), value: data.berths },
    { title: t('Terminals'), value: data.terminals },
    { title: t('Capacity'), value: data.capacity },
    // { title: t('Operators'), value: data.operators },
  ];

  return (
    <div className="grid grid-cols-2 ">
      {details.map((detail) => {
        if (!detail.value) return null;
        return (
          <>
            <div
              className={`${styles.assetTableLeft} pl-5 pr-5 rounded-l mb-1 py-1 text-white`}>
              {detail.title}
            </div>
            <div
              className={`${styles.assetTableRight} pl-5 pr-5 rounded-r mb-1 py-1`}>
              {detail.value}
            </div>
          </>
        );
      })}
    </div>
  );
}

const PortMarker = ({ port, setHover }) => {
  const { t } = useTranslation();
  const LeafIcon = L.Icon.extend({
    options: {
      iconSize: [30, 30],
      iconAnchor: [0, 0],
      popupAnchor: [15, 0],
    },
  });

  L.Icon.Default.prototype.options.iconUrl = x;

  const streamingIcon = new LeafIcon({
    iconUrl: x,
  });

  return (
    <LMarker position={[port.Latitude, port.Longitude]} icon={streamingIcon}>
      <Popup className={styles.popupContainer}>
        <div
          className={'font-themixarabicLight text-start'}
          style={{
            minWidth: '15rem',
            display: 'grid',
            gap: '0.5rem',
          }}>
          <h3 className="m-0"  style={{textTransform:"capitalize"}}>{port.Port}</h3>
          <PortDetails
            data={{
              berths: port.Berths,
              terminals: port.Terminals,
              capacity: port.Capacity,
              operators: port.Operators,
              area: port.Area,
            }}
          />
          <a
            href={`/ports${port.Port ? `?port=${port.Port}` : ''}`}
            style={{
              backgroundColor: '#3eb2e2',
              textAlign: 'center',
              width: '10rem',
              borderRadius: '1rem',
              color: 'white',
              padding: '0.4rem 0 0.3rem 0',
            }}>
            {t('Go to Details')}
          </a>
        </div>
      </Popup>
    </LMarker>
  );
};

const tiler = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';
const attribution =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>';
const position = [21.9640018805307, 46.35166734562298];
const corner1 = L.latLng(32.846342290989874, 22.593244304078738);
const corner2 = L.latLng(14.332886519780368, 64.13081367238726);
const bounds = L.latLngBounds(corner1, corner2);

export default function Map() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [zoom, setZoom] = useState(6);
  const [minZoom, setMinZoom] = useState(6);

  const BREAKPOINT = 420;

  const { ports } = usePortContext();

  useEffect(() => {
    window.addEventListener('resize', () => setScreenWidth(window.innerWidth));
    if (screenWidth < BREAKPOINT) {
      setMinZoom(5);
      setZoom(5);
    } else {
      setMinZoom(6);
      setZoom(6);
    }
  }, [screenWidth]);

  if (!ports || ports.length < 1) return <></>;

  return (
    <MapContainer
      center={position}
      zoom={zoom}
      style={{ width: '100%', height: '80vh', zIndex: 0, maxHeight: '700px' }}
      maxZoom={9}
      minZoom={minZoom}
      maxBoundsViscosity={1.0}
      maxBounds={bounds}>
      <TileLayer url={tiler} attribution={attribution} />
      <MarkerLayer>
        {ports.length > 0 &&
          ports.map((port, index) => (
            <PortNode key={index} port={port?.attributes} />
          ))}
      </MarkerLayer>
      {ports.length > 0 &&
        ports.map((port, index) => (
          <PortMarker key={index} port={port?.attributes} />
        ))}
    </MapContainer>
  );
}
