import styles from '../MediaCenter.module.scss';
import { BASE_URL } from 'utils/constants';
import { formatDateToDayMonthYearString } from 'utils/format.string';
import { useNewsletterContext } from 'contexts/NewsletterContext';

const Newsletters = ({ limit }: { limit?: number }) => {
  const { newsletters } = useNewsletterContext();
  return (
    <div className={`${styles.mediaCenterGrid}`}>
      {newsletters &&
        newsletters
          .sort(
            (a, b) =>
              new Date(b?.attributes?.PublishDate || -1).getTime() -
              new Date(a.attributes?.PublishDate || -1).getTime(),
          )
          .slice(0, limit ? limit : newsletters?.length || -1)
          .map(
            (
              {
                id,
                attributes: {
                  Title,

                  PublishDate,

                  Thumbnail,
                  NewsletterFile,
                },
              },
              i,
            ) => (
              <div key={i} className={`${styles.card}`}>
                <div className={`${styles.meta}`}>
                  {PublishDate && (
                    <div className={`${styles.date}`}>
                      {formatDateToDayMonthYearString(PublishDate)}
                    </div>
                  )}
                  <h2>{Title}</h2>
                </div>

                <a
                  target={
                    NewsletterFile?.data?.attributes?.url ? '_blank' : '_self'
                  }
                  className={styles.newsletterCardImageContainer}
                  href={
                    NewsletterFile?.data?.attributes?.url
                      ? `${BASE_URL}${NewsletterFile?.data?.attributes?.url}`
                      : ''
                  }
                  rel="noreferrer">
                  {Thumbnail != null && Thumbnail.data != null && (
                    <img
                      className={styles.newsletterCardImage}
                      src={BASE_URL + Thumbnail.data.attributes.url}
                    />
                  )}
                </a>
              </div>
            ),
          )}
    </div>
  );
};
export default Newsletters;
