import Button from '@material-tailwind/react/Button';
import styles from './History.module.scss';
import { useEffect, useState } from 'react';
import { useArticleContext } from 'contexts/ArticleContext';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from 'utils/constants';
import { formatDateToDayMonthYearString } from 'utils/format.string';
export default function History() {
  const { t, i18n } = useTranslation();
  const { GetByCategoryPage, catArticles } = useArticleContext();
  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }
  GetByCategoryPage('Mawani-Newsletter', localStore);
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);
  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="relative pb-20">
        <div className={styles.col2Wrapper}>
          <div>
            {catArticles &&
              catArticles.map(
                (
                  {
                    id,
                    attributes: {
                      Title,
                      Summary,
                      PublishDate,
                      Images,
                      Slug,
                      Body,
                    },
                  },
                  i,
                ) => (
                  <div className={`${styles.card}`}>
                    <div className={`${styles.meta}`}>
                      {PublishDate && (
                        <div className={`${styles.date}`}>
                          {formatDateToDayMonthYearString(PublishDate)}
                        </div>
                      )}
                      <h2>{Title}</h2>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: Body }} />
                    <br />
                    {Images != null && Images.data != null && (
                      <img src={BASE_URL + Images.data[0].attributes.url} />
                    )}
                    {Images != null && Images.data != null && (
                      <img src={BASE_URL + Images.data[1].attributes.url} />
                    )}
                    {Images != null && Images.data != null && (
                      <img src={BASE_URL + Images.data[2].attributes.url} />
                    )}
                    {Images != null && Images.data != null && (
                      <img src={BASE_URL + Images.data[3].attributes.url} />
                    )}
                  </div>
                ),
              )}
          </div>
        </div>
      </div>
    </>
  );
}
