import styles from './MediaCenter.module.scss';
import { useArticleContext } from 'contexts/ArticleContext';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from 'utils/constants';
import { formatDateToDayMonthYearString } from 'utils/format.string';

const PressRelease = ({ limit = null }) => {
  const { t, i18n } = useTranslation();
  const { GetByCategoryPage, catArticles } = useArticleContext();
  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }
  GetByCategoryPage('Photos', localStore);
  return (
    <div>
      <div className={`${styles.mediaCenterGrid}`}>
        {catArticles &&
          catArticles
            ?.sort(
              (a, b) =>
                new Date(b?.attributes?.PublishDate || -1) -
                new Date(a.attributes?.PublishDate || -1),
            )
            ?.slice(0, limit || catArticles.length)
            .map(
              (
                {
                  id,
                  attributes: {
                    Title,
                    Summary,
                    PublishDate,
                    Images,
                    Slug,
                    CardImage,
                  },
                },
                i,
              ) => (
                <div key={i} className={`${styles.card}`}>
                  <div className={`${styles.meta}`}>
                    {/*PublishDate && (
                      <div className={`${styles.date}`}>
                        {formatDateToDayMonthYearString(PublishDate)}
                      </div>
                    )*/}
                    <h2>{Title}</h2>
                  </div>
                  {/*<p>{Summary}</p>*/}
                  <a
                    href={'/article?photo=' + Slug}
                    className={`${styles.readLink}`}>
                    {t('Browse Photo')}
                  </a>
                  {Images != null && Images.data != null && (
                    <img
                      alt=""
                      src={
                        BASE_URL +
                        (CardImage?.data?.attributes?.url
                          ? CardImage?.data?.attributes?.url
                          : Images.data[0].attributes.url)
                      }
                    />
                  )}
                </div>
              ),
            )}
      </div>
    </div>
  );
};
export default PressRelease;
