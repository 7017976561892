import { useExternalContext } from 'contexts/ExternalContext';
import { createColumnHelper } from '@tanstack/react-table';
import { Table } from 'components/Table';
import { Loader } from 'components/Loader';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('title', {
    cell: (info) => info.getValue(),
    header: 'Bid Name',
    meta: {
      size: '20%',
    },
  }),
  columnHelper.accessor('compDocumentFeesAr', {
    cell: (info) => info.getValue(),
    header: 'Document Fees',
    meta: {
      size: '14%',
    },
  }),
  columnHelper.accessor('compSubmissionLocationAr', {
    cell: (info) => (
      <span
        dangerouslySetInnerHTML={{
          __html: info.getValue(),
        }}></span>
    ),
    header: 'Submission Location',
  }),
  columnHelper.accessor('compFinalDateAr', {
    cell: (info) => info.getValue()?.split(' ')?.[0],
    header: 'Final Date',
    meta: {
      size: '15%',
    },
  }),
  columnHelper.accessor('openEnvelopesDate', {
    cell: (info) => info.getValue()?.split(' ')?.[0],
    header: 'Open Envelopes Date',
    meta: {
      size: '12%',
    },
  }),
  columnHelper.accessor('day', {
    cell: (info) => info.getValue(),
    header: 'Day',
    meta: {
      size: '10%',
    },
  }),
  
 
 
 
];

export const BiddingsAnnouncments = () => {
  const { biddings, apiLoading } = useExternalContext();

  return (
    <>
      {apiLoading && (biddings?.length === 0 || !biddings) && <Loader />}
      {biddings && <Table data={biddings} columns={columns} />}
    </>
  );
};
