import { useQuery } from '@tanstack/react-query';
import styles from './Announcement.module.scss';
import { GetAnnouncements } from './Announcement.api';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';

const Announcement = () => {
  const { i18n, t } = useTranslation();
  const { data, isError, isLoading } = useQuery(
    [
      'announcements',
      {
        locale: i18n.language,
      },
    ],
    () => GetAnnouncements(i18n.language),
    { keepPreviousData: true },
  );

  return (
    <>
      {data?.data &&
        data?.data?.length > 0 &&
        data?.data?.map((item) => {
          const currentDate = new Date();
          const day = currentDate.getUTCDate();
          const month = currentDate.getUTCMonth();
          const year = currentDate.getUTCFullYear();
          const hours = currentDate.getUTCHours();
          const minutes = currentDate.getUTCMinutes();
          const seconds = currentDate.getUTCSeconds();
          const milliseconds = currentDate.getUTCMilliseconds();

          const now = Date.UTC(
            year,
            month,
            day,
            hours,
            minutes,
            seconds,
            milliseconds,
          );

          if (
            new Date(item?.attributes?.StartDate)?.getTime() <= now &&
            new Date(item?.attributes?.EndDate)?.getTime() >= now
          ) {
            return (
              <div className={styles.announcement}>
                <img
                  src={item.attributes?.Icon?.data?.attributes?.url}
                  alt="Announcement"
                />
                <div>
                  {item?.attributes?.Title && (
                    <div className={styles.title}>
                      {item?.attributes?.Title}
                    </div>
                  )}
                  {item.attributes?.Description && (
                    <div className={styles.description}>
                      <Markdown>{item.attributes?.Description}</Markdown>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        })}
    </>
  );
};

export default Announcement;
