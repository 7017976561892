import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import KeepMeInformed from 'components/home/KeepMeInformed/index';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import MediaCenterComp from 'components/MediaCenter';
export default function MediaCenter() {
    return (
        <>
          <Navbar />
          <MediaCenterComp />
          {/* <KeepMeInformed /> */}
          <Information />
          <Subscribe />
          <DefaultFooter />
        </>
      );
}
