import React, { useEffect } from 'react';

import {
    useQuery,
} from '@tanstack/react-query';

import {
    PaginationState,
    useReactTable,
    getCoreRowModel,
    ColumnDef,
    flexRender,
} from '@tanstack/react-table';

import { GetCirculars } from './Circulars.api';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import styles from './styles.module.scss';
import { FiDownload } from 'react-icons/fi';
import { BASE_URL } from 'utils/constants';
import clsx from 'clsx';
import { Loader } from 'components/Loader';
import { CircularType } from 'components/Circulars/circular.types';

interface Props {
    releaseYear?: 'all' | number
}

function CircularsTable({ releaseYear }: Props) {
    const { i18n, t } = useTranslation();
    const columns = React.useMemo<ColumnDef<CircularType>[]>(
        () => [
            {
                accessorKey: 'releaseDate',
                cell: (info) => <span className={`block`}>{info.getValue()}</span>,
                accessorFn: (row) =>
                    new Date(row?.attributes?.ReleaseDate)?.getUTCFullYear() || '',
                header: () => (
                    <span
                        className="whitespace-nowrap block px-2"
                        style={{ textAlign: 'start' }}>
                        {t('Release Date')}
                    </span>
                ),
            },
            {
                accessorKey: 'circularNumber',
                cell: (info) => <span className={`block`}>
                    {t('Circular Number Subtitle')} (
                    {info.getValue()})
                </span>,
                accessorFn: (row) => row.attributes.CircularNumber,
                header: () => (
                    <span
                        className="whitespace-nowrap block px-2"
                        style={{ textAlign: 'start' }}>
                        {t('Circular Number')}
                    </span>
                ),
            },
            {
                accessorKey: 'description',
                cell: (info) => <span className={`block`}>{info.getValue()}</span>,
                accessorFn: (row) => row.attributes?.Description || '',
                header: () => <span className="block w-full px-2">{t('Description')}</span>,
            },
            {
                accessorKey: 'download',
                cell: (info) => (
                    <span>
                        <a
                            className={clsx([
                                styles.pdfDownloadIcon,
                                info.getValue() ? 'cursor-pointer' : 'cursor-not-allowed',
                            ])}
                            href={
                                info.getValue()
                                    ? `${BASE_URL}${info.getValue()}`
                                    : 'javascript:void(0)'
                            }
                            target={info.getValue() ? '_blank' : ''}
                            rel="noreferrer">
                            <FiDownload
                                className="m-auto"
                                style={{
                                    opacity: info.getValue() ? '1' : '0.6',
                                }}
                            />
                        </a>
                    </span>
                ),
                accessorFn: (row) => row.attributes?.File?.data?.attributes?.url,
                header: () => (
                    <span className="whitespace-nowrap block px-2">
                        {t('Download Circular')}
                    </span>
                ),
            },
        ],
        [i18n.language],
    );

    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: 10,
        });

    const { data, isError, isLoading } = useQuery(
        [
            'circulars',
            {
                locale: i18n.language,
                pageIndex,
                pageSize,
                releaseYear
            },
        ],
        () => GetCirculars(i18n.language, pageIndex + 1, pageSize, releaseYear),
        { keepPreviousData: true },
    );

    const defaultData = React.useMemo(() => [], []);

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    const table = useReactTable({
        data: data?.data ?? defaultData,
        columns,
        pageCount: data?.meta.pagination.pageCount ?? -1,
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
    });

    useEffect(() => {
        // Whenever the release date or any future filters changes make sure the pageIndex gets reset
        table.setPageIndex(0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [releaseYear])

    return (
        <div>
            <table className="w-full border-separate relative text-center">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr
                            key={headerGroup.id}
                            className="bg-gray-100 border-b w-full text-base font-semibold sm:table-cell">
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <div className="py-4">
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                            </div>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {isLoading ? <Loader /> : table.getRowModel().rows.length <= 0 ? <tr className='w-full'><td colSpan={5} className="w-full table-caption text-center py-4">{t("No Circulars Found")}</td></tr> : table.getRowModel().rows.map((row, index) => {
                        return (
                            <tr key={row.id} className={`${index % 2 && 'bg-gray-100 '}`}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id} className="py-4">
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {data?.meta?.pagination?.pageCount &&
                data?.meta?.pagination?.pageCount > 0 ? (
                <div className="flex justify-between items-center mt-6">
                    <div key={0}>
                        <ReactPaginate
                            previousLabel={t('Previous')}
                            nextLabel={t('Next')}
                            pageCount={data?.meta.pagination.pageCount ?? 1}
                            onPageChange={({ selected }) => {
                                table.setPageIndex(selected);
                            }}
                            containerClassName={styles.pagination}
                            initialPage={table.getState().pagination.pageIndex}
                            forcePage={table.getState().pagination.pageIndex}
                        />
                    </div>
                    <div className="text-sm">
                        <span>
                            {t('Page Size Info', {
                                current: table.getState().pagination.pageIndex + 1,
                                total: data?.meta?.pagination?.pageCount,
                            })}
                        </span>
                    </div>
                </div>
            ) : <></>}
        </div>
    );
}
export default CircularsTable;
