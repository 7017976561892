import { useRef, useEffect, useState, useMemo } from 'react';
import styles from './EServices.module.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useExternalContext } from 'contexts/ExternalContext';
import { Alert } from '@mui/material';
import { Loader } from 'components/Loader';

const Order = () => {
  const formRef = useRef();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);

  const {
    getCaptcha,
    captchaData,
    apiLoading,
    submitOrderForm,
    mainCategoriesData,
    subCatergoriesData,
    movableData,
    getMainCategories,
    getSubCategories,
    getMovable,
  } = useExternalContext();

  const [success, setSuccess] = useState(null);
  const [classificationItems, setClassificationItems] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categorySite, setCategorySite] = useState([]);
  const [movable, setMovable] = useState([]);
  const [defaultQuantity, setDefaultQuantity] = useState([]);
  const [requiredQuantityError, setRequiredQuantityError] = useState(false);
  const [selectedSubCategoryValue, setSelectedSubCategoryValue] = useState('');
  const [captcha, setCaptcha] = useState('');

  useEffect(() => {
    if (!captchaData) {
      getCaptcha();
    }
    getMainCategories();
  }, []);

  useEffect(() => {
    setClassificationItems(mainCategoriesData);
  }, [mainCategoriesData]);

  const handleSelectChangeMainCategory = (e) => {
    const selectedMainCategory = e?.target.value;
    setMovable([]);
    setDefaultQuantity([]);
    setCategorySite([]);
    setSelectedSubCategoryValue('');
    const mainCategoryParam = mainCategoriesData?.find(
      (item) => item.title?.trim() === selectedMainCategory?.trim(),
    )?.title;
    getSubCategories({
      mainCategory: mainCategoryParam,
    });
  };

  const handleSelectChangeSubCategory = (e) => {
    const selectedSubCategory = e?.target.value;
    setSelectedSubCategoryValue(selectedSubCategory);
    const subCategoryParam = subCatergoriesData?.find(
      (item) => item.title?.trim() === selectedSubCategory?.trim(),
    )?.title;
    getMovable({
      subCategory: subCategoryParam,
    });
  };

  useEffect(() => {
    const site = movableData?.filter(
      (item) => item?.category?.trim() === selectedSubCategoryValue?.trim(),
    );
    const uniquePorts = site?.reduce((accumulator, current) => {
      if (
        !accumulator.find((item) => item.port?.trim() === current.port?.trim())
      ) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    setCategorySite(uniquePorts);

    setMovable([]);
    setDefaultQuantity([]);
  }, [selectedSubCategoryValue, movableData]);

  const handleSelectChangeSite = (e) => {
    const selectedSite = e?.target.value;

    const movableItems = movableData?.filter((item) => {
      return (
        item?.port?.trim() === selectedSite?.trim() &&
        item?.category?.trim() === selectedSubCategoryValue?.trim()
      );
    });

    setMovable(movableItems);

    setDefaultQuantity([]);
  };

  const handleSelectChangeMovable = (e) => {
    const selectedMovable = e?.target.value;

    console.log('movable', movable);

    const quantity = movable?.filter((item) => {
      return item?.title?.trim() === selectedMovable?.trim();
    });
    setDefaultQuantity(quantity);
  };

  const handleRequireQuantityChange = (e) => {
    const requireQuantity = e?.target.value;

    if (requireQuantity > defaultQuantity?.[0]?.quantity && !!requireQuantity) {
      setRequiredQuantityError(true);
      return;
    } else {
      setRequiredQuantityError(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    setSuccess(null);

    const selectedMovableData = defaultQuantity?.[0];

    console.log('*** selectedMovableData', selectedMovableData);
    console.log('*** Data', data);

    const orderFormData = {
      Title: data.name,
      IdentityNo: data.identityNumber,
      MobileNo: data.mobileNumber,
      Email: data.email,
      DirectionName: data.entity,
      DepartmentName: data.management,
      Location: data.city,
      MangulCategory: data.item,
      Port: data.site,
      AvalibleQuantity: selectedMovableData?.quantity,
      _x0627__x0644__x062d__x0627__x06: null,
      Quantity: data.requiredQuantity,
      Explain: data.reviews,
      MainCategory: data.classification,
      ManqulName: selectedMovableData?.title,
      Created: selectedMovableData?.created,
      Modified: selectedMovableData?.modified,
      Author: selectedMovableData?.author,
      Editor: selectedMovableData?.editor,
      captcha,
    };

    console.log('orderFormData', orderFormData);

    const resp = await submitOrderForm(orderFormData);
    getCaptcha();
    setCaptcha('');

    if (resp?.isSuccess === true) {
      reset();
      setSuccess(true);
      formRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (resp?.error === 'Invalid Captcha') {
      setSuccess(null);
      setError('captcha', {
        type: 'value',
        message: resp?.error,
      });
    } else {
      setSuccess(false);
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="overflow-hidden">
        <div className={`${styles.contactContain}`} >
        <h2 style={{color:'rgba(0, 0, 0, 0.8)'}}>{t('Orders')}</h2>
          <div className="">
            <div className="">
              <div className={`${styles.contactForm}`} ref={formRef}>
                <div className={styles.eServiceContent}>
                  <p>
                    {t(
                      'This is an e-service which displays the warehouse items available at all Saudi Ports and allow other government agencies to request and benefit from these items as needed, prior of offering them for sale.',
                    )}
                  </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {success && (
                    <Alert severity="success" className="mb-4">
                      {t('Order Placed')}
                    </Alert>
                  )}
                  {success === false && (
                    <Alert severity="error" className="mb-4">
                      {t('Something went wrong')}
                    </Alert>
                  )}
                  <div className="form-field">
                    <label htmlFor="name">
                      {t('Full Name')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      {...register('name', {
                        required: true,
                      })}></input>
                    {errors.name && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR NAME')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="identityNumber">
                      {t('ID Number')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="identityNumber"
                      type="text"
                      name="identityNumber"
                      maxLength={10}
                      {...register('identityNumber', {
                        required: true,
                        minLength: 10,
                        maxLength: 10,
                        pattern: /^1[0-9]+$/i,
                      })}></input>
                    {errors.identityNumber && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ID Number Error')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="mobileNumber">
                      {t('Mobile Number')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="mobileNumber"
                      type="text"
                      name="mobileNumber"
                      placeholder="966 xx xxxxxxx"
                      maxLength={12}
                      {...register('mobileNumber', {
                        required: true,
                        pattern: /^966[0-9]+$/i,
                        maxLength: 12,
                        minLength: 12,
                      })}></input>
                    {errors.mobileNumber && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('Mobile Number Error')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="email">
                      {t('Email')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      {...register('email', {
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                      })}></input>
                    {errors.email && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR EMAIL')}
                      </span>
                    )}
                  </div>

                  <div className="form-field">
                    <label htmlFor="entity">
                      {t('Entity')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="entity"
                      type="text"
                      name="entity"
                      {...register('entity', {
                        required: true,
                      })}></input>
                  </div>

                  <div className="form-field">
                    <label htmlFor="management">
                      {t('Management')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="management"
                      type="text"
                      name="management"
                      {...register('management', {
                        required: true,
                      })}></input>
                  </div>

                  <div className="form-field">
                    <label htmlFor="city">
                      {t('City')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="city"
                      type="text"
                      name="city"
                      {...register('city', {
                        required: true,
                      })}></input>
                  </div>
                  <div className="form-field">
                    <label htmlFor="classification">
                      {t('Classification')}
                      <span className="require">*</span>
                    </label>
                    <select
                      id="classification"
                      name="classification"
                      {...register('classification', {
                        required: true,
                        onChange: (e) => handleSelectChangeMainCategory(e),
                      })}>
                      <option>{t('Select')}</option>
                      {mainCategoriesData &&
                        Array.isArray(mainCategoriesData) &&
                        mainCategoriesData?.map((item) => {
                          return <option key={item.id}>{item.title}</option>;
                        })}
                    </select>
                  </div>

                  <div className="form-field">
                    <label htmlFor="item">
                      {t('Item')}
                      <span className="require">*</span>
                    </label>
                    <select
                      id="item"
                      name="item"
                      {...register('item', {
                        required: true,
                        onChange: (e) => handleSelectChangeSubCategory(e),
                      })}>
                      <option>{t('Select')}</option>
                      {subCatergoriesData &&
                        Array.isArray(subCatergoriesData) &&
                        subCatergoriesData?.map((item) => {
                          return <option key={item.id}>{item.title}</option>;
                        })}
                    </select>
                  </div>

                  <div className="form-field">
                    <label htmlFor="site">
                      {t('Site')}
                      <span className="require">*</span>
                    </label>
                    <select
                      id="site"
                      name="site"
                      {...register('site', {
                        required: true,
                        onChange: (e) => handleSelectChangeSite(e),
                      })}>
                      <option>{t('Select')}</option>
                      {categorySite &&
                        Array.isArray(categorySite) &&
                        categorySite?.map((item) => {
                          return <option key={item.id}>{item.port}</option>;
                        })}
                    </select>
                  </div>

                  <div className="form-field">
                    <label htmlFor="movable">
                      {t('Movable')}
                      <span className="require">*</span>
                    </label>
                    <select
                      id="movable"
                      name="movable"
                      {...register('movable', {
                        required: true,
                        onChange: (e) => handleSelectChangeMovable(e),
                      })}>
                      <option>{t('Select')}</option>
                      {movable &&
                        Array.isArray(movable) &&
                        movable?.map((item) => {
                          return <option key={item.id}>{item.title}</option>;
                        })}
                    </select>
                  </div>

                  <div className="form-field">
                    <label htmlFor="quantity">
                      {t('Quantity')}
                      <span className="require">*</span>
                    </label>
                    <div id="quantity" className={styles.quantityInput}>
                      {defaultQuantity?.[0]?.quantity}
                    </div>
                  </div>

                  <div className="form-field">
                    <label htmlFor="requiredQuantity">
                      {t('Required Quantity')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="requiredQuantity"
                      type="text"
                      name="requiredQuantity"
                      aria-invalid={requiredQuantityError}
                      maxLength={10}
                      {...register('requiredQuantity', {
                        required: true,
                        onChange: (e) => handleRequireQuantityChange(e),
                        validate: requiredQuantityError,
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        },
                      })}></input>
                    {errors.requiredQuantity && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('Requrie Quantity Empty Error')}
                      </span>
                    )}
                    {requiredQuantityError && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('Requrie Quantity Error')}
                      </span>
                    )}
                  </div>

                  <div className="form-field">
                    <label htmlFor="reviews">{t('Reviews')}</label>
                    <textarea
                      rows="5"
                      cols="5"
                      {...register('reviews')}></textarea>
                  </div>

                  <div className="form-field">
                    <label>
                      {t('Captcha')}
                      <span className="require">*</span>
                    </label>
                    <img
                      src={captchaData}
                      className={'pb-2'}
                      alt="captcha"
                      width={'150px'}
                      height={'60px'}
                    />
                    <input
                      type="text"
                      placeholder={t('Enter Captcha')}
                      {...register('captcha', { required: false })}
                      value={captcha}
                      onChange={(e) => setCaptcha(e.target.value)}
                    />
                    {errors.captcha && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR CAPTCHA')}
                      </span>
                    )}
                  </div>
                  {apiLoading && <Loader />}
                  {!apiLoading && (
                    <button className="button-default" type="submit">
                      {t('Send request')}
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
