import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import http from '../http';
const PolicyContext = createContext();

export const usePolicyContext = () => {
  return useContext(PolicyContext);
};

export const PolicyProvider = ({ children }) => {
  const [policy, setPolicy] = useState('');
  const [conditions, setConditions] = useState('');
  const { i18n } = useTranslation();

  const locale = i18n.language || 'en';
  useEffect(() => {
    http
      .get('/api/privacy-policy?populate=*&locale=' + locale)
      .then((response) => {
        setPolicy(response.data.data);
      });

    http.get('/api/condition?populate=*&locale=' + locale).then((response) => {
      setConditions(response.data.data);
    });
  }, [locale]);

  const value = {
    policy,
    conditions,
  };

  return (
    <PolicyContext.Provider value={value}>{children}</PolicyContext.Provider>
  );
};
