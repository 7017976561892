import styles from './Circulars.module.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularsTable from 'components/Circulars/Table';

const Circulars: React.FC = () => {
  const { t, i18n } = useTranslation();

  const firstCircularYear = 2019;
  const circularReleaseYears: Array<number> = Array(
    Math.abs(firstCircularYear - new Date().getUTCFullYear()),
  )
    .fill(null)
    .map((year, index) => firstCircularYear + year + index + 1);
  circularReleaseYears.push(firstCircularYear);
  circularReleaseYears.sort().reverse();

  const [filters, setFilters] = useState<{ year: 'all' | number }>({
    year: 'all',
  });

  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <div className="flex flex-wrap gap-2 justify-between">
            <h1 className="page_title">{t('Circulars')}</h1>
            <div
              className="px-3 py-3 text-base font-semibold"
              style={{ textAlign: 'start' }}>
              {t('Release Date')}:{' '}
              <select
                name=""
                id=""
                className=" py-2 font-semibold bg-gray-50"
                value={filters.year}
                onChange={(e) =>
                  setFilters({ year: Number(e.currentTarget.value) || 'all' })
                }>
                <option value="all" className="bg-gray-50">
                  {t('All')}
                </option>
                {circularReleaseYears.map((year) => (
                  <option key={year} value={year} className="bg-gray-50">
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-6">
            <CircularsTable releaseYear={filters.year} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Circulars;
