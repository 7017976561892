import H3 from '@material-tailwind/react/Heading3';
import { FaSistrix } from 'react-icons/fa';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import video from '../../assets/videos/video.mp4';
import home_gif from '../../assets/img/Home_Video.gif';
import styles from './Header.module.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fader from './fader';

export default function Header() {
  const [wantLink, setWantLink] = useState('#');
  const [iWantCurrent, setIWantCurrent] = useState([]);
  const [refPageCurrent, setRefPageCurrent] = useState([]);
  const { t, i18n } = useTranslation();
  const [localStore, setLocalStore] = useState('');

  var IAmList = [
    { val: 10, title: t('Investor') },
    { val: 20, title: t('Shipping Agent') },
    { val: 30, title: t('Operator') },
  ];
  var IWantListInvestor = [
    t('Invest in Mawani Port'),
    t('Explore Regulations'),
    t('Request for rental of facilities Dropdown'),
    t("Explore Mawani's E-services"),
  ];
  var IWantListInvestor = [
    { val: 10, title: t('Invest in Mawani Port') },
    { val: 20, title: t('Explore Regulations') },
    { val: 30, title: t('Request for rental of facilities Dropdown') },
    { val: 40, title: t("Explore Mawani's E-services") },
  ];
  var IWantListShipping = [t('Obtain a new License'), t('Explore Regulations')];
  var IWantListShipping = [
    { val: 10, title: t('Obtain a new License') },
    { val: 20, title: t('Explore Regulations') },
  ];
  var IWantListOperator = [{ val: 10, title: t('Explore Regulations') }];
  var refPageList = [
    '/ports',
    '/regulations',
    '/e-services?o=license',
    '/e-services?o=request-for-rental-of-facilities',
    '/e-services',
  ];
  var messagesEN = [
    'WELCOME TO <br/> SAUDI PORTS AUTHORITY',
    'LEADING THE MARITIME TRANSPORT INDUSTRY TOWARDS OPPORTUNITIES',
    'POSITIONING SAUDI ARABIA AS A GLOBAL LOGISTICS HUB',
  ];
  var messagesAR = [
    'مرحبا بكم<br/> في الهيئة العامة للموانئ',
    'نُرسخ مكانة المملكة كمركز لوجستي عالمي  ',
    'نقود صناعة النقل البحري نحو محيط زاخر بالفرص',
  ];
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    setLocalStore(localStoreVar);
    if (localStoreVar == 'en') {
      document.body.dir = 'ltr';
      i18n.changeLanguage('en');
    } else if (localStoreVar == 'ar') {
      document.body.dir = 'rtl';
      i18n.changeLanguage('ar');
    } else {
      document.body.dir = 'ltr';
      i18n.changeLanguage('en');
    }
  }, [localStorage.getItem('locale')]);
  const wantHandler = (event) => {
    const value = event.target.value;
    if (value == 10) {
      setIWantCurrent(IWantListInvestor);
    } else if (value == 20) {
      setIWantCurrent(IWantListShipping);
    } else if (value == 30) {
      setIWantCurrent(IWantListOperator);
    }
  };
  const linkHandler = (event) => {
    const value = event.target.value;
    console.log(value);
    var link = '';
    if (value == t('Invest in Mawani Port')) {
      link = refPageList[0];
    } else if (value == t('Explore Regulations')) {
      link = refPageList[1];
    } else if (value == t('Obtain a new License')) {
      link = refPageList[2];
    } else if (value == t('Request for rental of facilities Dropdown')) {
      link = refPageList[3];
    } else if (value == t("Explore Mawani's E-services")) {
      link = refPageList[4];
    }
    setWantLink(link);
  };

  return (
    <div
      className={` relative flex items-center justify-center ${styles.videoContain}`}>
      <div className={` w-full ${styles.videoWrapper}`}>
        <video className={styles.video} autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
        </video>
        {/*<img src={home_gif} className={styles.video} alt={"Saudi Ports Authority"}/>*/}
      </div>
      <div
        className={`container_max absolute bottom-20 max-w-8xl relative mx-aut max-w-full ${styles.videoContent}`}
        style={{ top: 0, border: 0, display: 'flex' }}>
        <div className="items-center flex flex-wrap  w-full">
          <div className={`w-full m-auto text-center ${styles.mainBannerWrap}`}>
            <h1 className={`text-white mb-10 ${styles.bannerTitle}`}>
              <strong>
                <Fader
                  text={
                    (localStore == 'en' && messagesEN) ||
                    (localStore == 'ar' && messagesAR) || 
                    messagesEN
                  }
                  interval={6000}
                />
              </strong>
            </h1>
            <fieldset className={styles.searchWrapper}>
              <legend className={styles.title}>{t('HOW CAN WE HELP')}</legend>
              <div className={`flex w-full items-end gap-4 ${styles.form}`}>
                <div className={`${styles.formFieldWrap}`}>
                  <FormControl
                    variant="standard"
                    className={`w-full ${styles.mainFieldWrap}`}>
                    <InputLabel id="test-select-label">{t('I am')}</InputLabel>
                    <Select
                      onChange={wantHandler}
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Age"
                      className={styles.select}
                      style={{ fontFamily: 'theMixArabicPlain' }}>
                      {IAmList.map((item, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={item.val}
                            className={`${styles.selectItem}`}
                            style={{ fontFamily: 'theMixArabicPlain' }}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    className={`w-full ${styles.mainFieldWrap}`}>
                    <InputLabel id="test-select-label">
                      {t('I want to')}
                    </InputLabel>
                    <Select
                      onChange={linkHandler}
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      className={styles.select}
                      label="Age">
                      {iWantCurrent.map((item) => {
                        return (
                          <MenuItem
                            value={item.title}
                            className={`${styles.selectItem}`}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <a href={wantLink}>
                  <FaSistrix className={`${styles.mainBannerSearch}`} />
                </a>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
}
