import styles from './EServices.module.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Invoice = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        var localStoreVar = localStorage.getItem('locale');
        i18n.changeLanguage(localStoreVar);
    }, []);

    return (
        <div className={styles.eServiceContent}>
            <h2>{t('Electronic payment service')}</h2>

            <div className={styles.serviceIntro}>
                <p>
                    {t(
                        'This service allows payment of Saudi Ports Authority bills through Mada, Visa, MasterCard cards.',
                    )}
                </p>

                <p>
                    <br />
                    <a
                        className="button-default"
                        href="https://eservices.mawani.gov.sa/payment-gateway/"
                        rel="noreferrer"
                        target="_blank">
                        {t('Access to the service')}
                    </a>
                    <ul className="list-disc font-light pb-10 pt-5">
                        <li>
                            <a
                                href="/uploads/Payment_Gateway_User_Guide_496db1126c.pdf"
                                download
                                className="font-normal underline text-same text-[#2D6A80]">
                                {t('Services User Manual')}
                            </a>
                        </li>
                    </ul>
                </p>
            </div>
        </div>
    );
};

export default Invoice;
