import React, { createContext, useContext, useEffect, useState } from 'react';
import http from '../http';
import axios from 'axios';
const VideoContext = createContext();

export const useVideoContext = () => {
    return useContext(VideoContext);
};

export const VideoProvider = ({children}) => {
    const [videos, setVideos] = useState("");
    const [catVideos, setCatVideos] = useState("");
    const [slugVideo, setSlugVideo] = useState("");
    const [nav_value, set_nav_value] = useState("VideoList");
    const [videoId, setVideoId] = useState("");
    var localStore = localStorage.getItem('locale');
    if(localStore == null || localStore == undefined){
        localStore = "ar";
    }

    // change navigation value
    const changeNavValue = (value) => {
        set_nav_value(value);
    };
    // get video id value
    const getVideoId = (id) => {
        setVideoId(id);
    };
    const GetAllVideos = (locale) =>{
            http.get("/api/videos?populate=*&locale="+locale)
            .then((response) => {
                setVideos([...response.data.data]);
            })
    }
    const GetAllVideosPage = (locale) =>{
        useEffect(()=>{
            http.get("/api/videos?populate=*&locale="+locale)
            .then((response) => {
                setVideos([...response.data.data]);
            })
        }, []);
    }
    useEffect(()=>{
        localStore = localStorage.getItem('locale');
        http.get("/api/videos?locale="+localStore+"&populate=*")
        .then((response) => {
          setVideos([...response.data.data]);
        })
    }, []);
    const value = {
        videos,
        changeNavValue,
        nav_value,
        getVideoId,
        videoId,
        catVideos,
        slugVideo,
        GetAllVideos,
        GetAllVideosPage
    };
    // context provider
    return(
        <VideoContext.Provider value={value}>
            {children}
        </VideoContext.Provider>
    )
}; 