import styles from './EServices.module.scss';
import serviceImage from "../../assets/img/service-license-flow.png";
import serviceImage2 from "../../assets/img/service-license-flow2.png";
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { useEffect, useState } from 'react';
import license1 from './license1'
import license2 from './license2'
import { useTranslation } from 'react-i18next';
import { useServiceContext } from 'contexts/eServiceContext';

const License = () => {
  const { licenses, } = useServiceContext();
  console.log(licenses)
  const { t, i18n } = useTranslation();
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
},[])
  const [selectQuery,setSelectQuery] = useState(0);
  const Tab = styled(TabUnstyled)`
  cursor: pointer;
  padding: 12px 16px 12px 0px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid;
  text-align: left;
  &:hover {
    font-family: "themixarabicBold", sans-serif;
  }

  &:focus {
    outline: none;
  }

  @media(max-width: 767px) {
   text-align: center;
    padding: 9px 20px;
    border: solid 1px #bbb;
    font-family: "themixarabicPlain", sans-serif;
    border-radius: 45px;
    font-size: 16px;
    margin-right: 15px;
    margin-bottom: 12px;
    text-transform: none;
    width: auto;
    letter-spacing: 0;
    box-shadow: none;
    color: #000;
  }

  &.${tabUnstyledClasses.selected} {
    color: #48a2b8;
    display: flex;
   
    font-family: "themixarabicBold", sans-serif;
    
    @media(max-width: 767px) {
      border-color: #2D6A80;
      background: #2D6A80;
      color: #fff;
    }
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
 
`;

const TabsList = styled(TabsListUnstyled)`


  @media(max-width:767px) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
`;
const components = [
  {
    title: t("Shipping agent license"),
    component: license1, 
  },
{
    title: t("Ship chandelling license"),
    component: license2, 
  },

]
  return (
    <div className={styles.eServiceContent}>
      <h2>{t("License System")}</h2>
      <div className={styles.serviceIntro}>
<p>
{t("This service provides you with the necessary license to practice shipping agents and ship chandelling related activities in Saudi ports once fulfilling all the conditions and requirements.")}
</p>
    </div>
      <div className={`${styles.mediaCenterContent}`}>
              <TabsUnstyled
                key={`tab-${selectQuery}`} 
                defaultValue={selectQuery}
                orientation="vertical"
                className={`${styles.mediaContain}`}>
                <div className={`${styles.sideBar}`}>
                  <TabsList>
                    {components.map((item, index) => (
                      <Tab key={index}>{item.title}</Tab>
                    ))}
                  </TabsList>
                </div>
                <div className={`${styles.tabContent}`}>
                  {components.map((item, index) => (
                    <TabPanel value={index} keyindex>
                      <item.component detail={licenses} />
                    </TabPanel>
                  ))}
                </div>
              </TabsUnstyled>
            </div>
</div>
  );
};

export default License;
