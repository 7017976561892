
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { useEffect, useState } from 'react';
import { biddings } from './eservice.constants';
import styles from './EServices.module.scss';
import UnderConstruction from './UnderConstruction';
import { useTranslation } from 'react-i18next';

const Biddings = () => {
  const Tab = styled(TabUnstyled)`
  cursor: pointer;
  padding: 9px 14px;
  border: 1px solid #bbb;
  font-family: "themixarabicPlain",sans-serif;
  border-radius: 45px;
  font-size: 17px;
  margin-right: 7px;
  margin-left: 7px;
  margin-bottom: 12px;
  text-transform: none;
  letter-spacing: 0;
  box-shadow: none;
  color: #000;
  transition: .2s;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #fff;
    background: #2D6A80;
    border-color: #2D6A80;
  
  }

  @media(max-width:767px) {
  font-size:16px;

  line-height:20px;
 
  }
  
  @media(max-width:639px) {
    
   
    }

  &.${tabUnstyledClasses.selected} {
    color: #fff;
  background: #2D6A80;
  border-color: #2D6A80;


   
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`

`;

const TabsList = styled(TabsListUnstyled)`
margin-top:20px;

`;
const [selectQuery,setSelectQuery] = useState(0);
const { t, i18n } = useTranslation();
useEffect(() => {
  var localStoreVar = localStorage.getItem('locale');
  i18n.changeLanguage(localStoreVar);
},[])
  return (
    <div className={styles.eServiceContent}>
      <h2>{t('Biddings')}</h2>
      <TabsUnstyled
                key={`tab-${selectQuery}`} 
                orientation='vertical'
                defaultValue={selectQuery}
                className="">
                <div className={styles.serviceContainer}>
                <div className={styles.buttonContainer}>
                  <TabsList>
                    {biddings.map((item, index) => (
                       <Tab key={index}>
                       {t(item.title)}
                     </Tab>
                    ))}
                  </TabsList>
                  </div>
                  </div>
                <div className={styles.contentContainer}>
                  {biddings.map((item, index) => (
                    <TabPanel value={index} keyindex>
                      <item.component />
                    </TabPanel>
                  ))}
                </div>
              </TabsUnstyled>
             
     
      {/* <div className="">{content}</div> */}
    </div>
  );
};
export default Biddings;
