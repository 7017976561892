import styles from './Error.module.scss';

import { useTranslation } from 'react-i18next';

export default function Error() {
    const { t } = useTranslation();

    return (
        <>
            <div className={`${styles.bgHeaderBackground}`}></div>
            <div className="container_max">
                <div className="page_wrapper">
                    <h1 className="page_title">{t('Not Found Page Title')}</h1>
                    <p className={`${styles.errorDescription}`}>
                        {t('Not Found Page Description')} <a href="/" className={styles.linkStyle}>{t("Not Found Back to Home")}</a>
                    </p>
                </div>
            </div>
        </>
    );
}
