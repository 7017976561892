import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import DatePicker from '../DatePicker';
import hijriCalendar from 'react-date-object/calendars/arabic';
import arabicCalendarLocale from '../DatePicker/arabic_ar';
import { Loader } from '../Loader';

import { useExternalContext } from 'contexts/ExternalContext';
import styles from './EServices.module.scss';

const TransactionTrackingService = () => {
  const { t, i18n } = useTranslation();
  const {
    apiLoading,
    getCaptcha,
    captchaData,
    getTransactionStatus,
    transactionStatusData,
  } = useExternalContext();
  const [documentDate, setDocumentDate] = useState(new Date());
  const [documentNumber, setDocumentNumber] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);

  const callGetTransactionStatus = async (ev) => {
    ev.preventDefault();
    setShowResults(false);
    await getTransactionStatus({
      DocumentNumber: documentNumber,
      HDate: documentDate,
      captcha,
    });
    setShowResults(true);
    getCaptcha();
    setCaptcha('');
  };

  return (
    <div className={styles.eServiceContent}>
      <h2>{t('Transaction Tracking Service')}</h2>
      {/* <h3>{t("Consume Service - Service")}</h3> */}
      <div classname={styles.serviceIntro}>
        <p>
          {t(
            'This e-service allows beneficiaries of Saudi Ports Authority’s services to inquire about the information and status of their transactions.',
          )}
        </p>

        <p>
          <strong>{t('Steps to inquire about transactions:')}</strong>
        </p>
        <div className={styles.serviceStepsFour}>
          <div className={styles.step}>
            <div className={styles.number}>{t('1')}</div>
            <div className={styles.title}>
              {t('Access Transactions Tracking System')}
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.number}>{t('2')}</div>
            <div className={styles.title}>
              {t('Enter transaction number and date')}
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.number}>{t('3')}</div>
            <div className={styles.title}>
              {t('Enter picture code and press search')}
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.number}>{t('4')}</div>
            <div className={styles.title}>
              {t('Transaction status appears')}
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={callGetTransactionStatus}>
        <div className={`${styles.splitFormCol}`}>
          <div className="form-field">
            <label>
              {t('Document Number')}
              <span className="require">*</span>
            </label>
            <input
              type="text"
              placeholder={t('Enter Document Number')}
              onChange={(e) => setDocumentNumber(e.target.value)}
              required
            />
          </div>
          <div className="form-field">
            <label>
              {t('Date')}
              <span className="require">*</span>
            </label>
            <DatePicker
              calendar={hijriCalendar}
              locale={arabicCalendarLocale}
              value={documentDate}
              onChange={(e) => setDocumentDate(e.toString())}
              format="D/MM/YYYY"
              required
            />
          </div>
          <div className="form-field">
            <label>
              {t('Captcha')}
              <span className="require">*</span>
            </label>
            <img
              src={captchaData}
              className={'pb-2'}
              alt="captcha"
              width={'150px'}
              height={'60px'}
            />
            <input
              type="text"
              placeholder={t('Enter Captcha')}
              value={captcha}
              onChange={(e) => setCaptcha(e.target.value)}
              required
            />
          </div>
        </div>
        <button
          className="button-default disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={apiLoading}>
          {t('Submit')}
        </button>
      </form>
      <br />
      {apiLoading && <Loader />}
      {showResults && !transactionStatusData?.isSuccess && (
        <div className="pb-4">
          <Alert severity="error">
            {t(transactionStatusData?.error || 'Something went wrong')}
          </Alert>
        </div>
      )}
      {showResults && transactionStatusData?.data && (
        <table className={`${styles.tablePlain} w-full`}>
          <tbody>
            <tr>
              <td>{t('Document Number')}</td>
              <td>{transactionStatusData?.data?.REFERENCE_NUMBER}</td>
            </tr>
            <tr>
              <td>{t('Document Date')}</td>
              <td>{transactionStatusData?.data?.DOCUMENT_DATE_HIJRI}</td>
            </tr>
            <tr>
              <td>{t('Subject')}</td>
              <td>{transactionStatusData?.data?.SUBJECT}</td>
            </tr>
            <tr>
              <td>{t('Category')}</td>
              <td>{transactionStatusData?.data?.CATEGORY}</td>
            </tr>
            <tr>
              <td>{t('Organization Name')}</td>
              <td>{transactionStatusData?.data?.DOCUMENT_FROM_ORG_NAME}</td>
            </tr>
            <tr>
              <td>{t('Directed To')}</td>
              <td>{transactionStatusData?.data?.DIRECTED_TO}</td>
            </tr>
            <tr>
              <td>{t('Status')}</td>
              <td>{transactionStatusData?.data?.STATUS}</td>
            </tr>
            <tr>
              <td>{t('Transfer Date')}</td>
              <td>{transactionStatusData?.data?.TRANSFER_DATE_HIJRI}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TransactionTrackingService;
