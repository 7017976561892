import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import styles from './PortEmployees.module.scss';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import http from '../../http';
import { useHistory } from 'react-router-dom';

const Tab = styled(TabUnstyled)`
  cursor: pointer;
  padding: 12px 16px 12px 0px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid;

  &:hover {
    font-family: 'themixarabicBold', sans-serif;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 767px) {
    text-align: center;
    padding: 9px 20px;
    border: solid 1px #bbb;
    font-family: 'themixarabicPlain', sans-serif;
    border-radius: 45px;
    font-size: 16px;
    margin-right: 15px;
    margin-bottom: 12px;
    text-transform: none;
    width: auto;
    letter-spacing: 0;
    box-shadow: none;
    color: #000;
  }

  &.${tabUnstyledClasses.selected} {
    color: #48a2b8;
    display: flex;

    font-family: 'themixarabicBold', sans-serif;

    @media (max-width: 767px) {
      border-color: #2d6a80;
      background: #2d6a80;
      color: #fff;
    }
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)``;

const TabsList = styled(TabsListUnstyled)`
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
`;

export default function MediaCenterComp() {
  const [selectQuery, setSelectQuery] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const [{ loading, error, data }, setPortsEmployees] = useState({
    loading: false,
    error: null,
    data: [],
  });

  useEffect(() => {
    const getAllPorts = async () => {
      try {
        setPortsEmployees((cur) => ({ ...cur, loading: true }));
        const req = await http
          .get('/api/port-employees?populate=*&locale=' + i18n.language)
          .catch((err) => new Error(err));
        setPortsEmployees((cur) => ({ ...cur, data: req?.data?.data }));
      } catch (error) {
        setPortsEmployees((cur) => ({ ...cur, error: error }));
      } finally {
        setPortsEmployees((cur) => ({ ...cur, loading: false }));
      }
    };
    getAllPorts();
  }, [i18n.language]);

  useEffect(() => {
    const tabTarget = history.location.hash.split('#')[1];
    if (!Number(tabTarget)) return;
    const idMatch = data.find((item) => item.id === Number(tabTarget));
    const matchIndex = data.indexOf(idMatch);
    setActiveTab(matchIndex);
    history.push({ hash: '' });
  }, [history.location.hash]);

  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <h1 className="page_title">{t('PORTS EMPLOYEES')}</h1>
          {data && data.length > 0 && (
            <div className={`${styles.mediaCenterContent}`}>
              <TabsUnstyled
                key={`tab-${selectQuery}`}
                defaultValue={selectQuery}
                orientation="vertical"
                value={activeTab}
                onChange={(e) => {
                  if (
                    Number(e.target.dataset.index) ||
                    Number(e.target.dataset.index) === 0
                  )
                    setActiveTab(Number(e.target.dataset.index));
                }}
                className={`${styles.mediaContain}`}>
                <div className={`${styles.sideBar}`}>
                  <TabsList>
                    {data?.map((tab, index) => (
                      <Tab key={index} data-index={index} val role={'tabpanel'}>
                        {tab?.attributes?.title}
                      </Tab>
                    ))}
                    p
                  </TabsList>
                </div>
                <div className={`${styles.tabContent}`}>
                  {data.map((tab, index) => (
                    <TabPanel value={index} keyindex>
                      <div
                        className={`${styles.markupContent}`}
                        dangerouslySetInnerHTML={{
                          __html: tab?.attributes.content,
                        }}
                      />
                    </TabPanel>
                  ))}
                </div>
              </TabsUnstyled>
            </div>
          )}{' '}
        </div>
      </div>
    </>
  );
}
