import { useState } from 'react';
import styles from './PopUpEServices.module.scss';
import { useTranslation } from 'react-i18next';
export default function PopUpEServices() {
    const { t, i18n } = useTranslation();
    const [isVisible, setIsVisible] = useState(true);
    const handleClose = () => setIsVisible(false);
    return (
        <div>
            {i18n.language == 'ar' && (
                <div className={isVisible ? `${styles.visible}` : `${styles.hidden}`}>
                    <div className={`${styles.content}`}>
                        <button className={`${styles.button}`} onClick={handleClose}>X</button>
                        <div className={`${styles.title}`}>
                            {t('PopUpTitle')}
                        </div>
                        <div className={`${styles.announcement}`}>
                            {t('PopUpEservicesAnnouncement')}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}