
import './AboutUs.module.scss';
import About from './About';
import Board from './Board';
import Org from './Org';
import Services from './Services';
import styles from './AboutUs.module.scss';
import info from '../../../src/assets/img/icons/info.svg'
import organo from '../../../src/assets/img/icons/organogram.svg'
import board from '../../../src/assets/img/icons/board.svg'
import service from '../../../src/assets/img/icons/service.svg'
import { FaGavel, FaSearch, FaRegFileAlt, FaFileInvoiceDollar, FaExternalLinkAlt,FaQuestionCircle, FaCompressAlt } from 'react-icons/fa';

export const components = [
  {
    icon: <img src={info} className={styles.tabInfoIcon}/>,
    title: <strong className={styles.tabTitle}>About Mawani</strong>,
    component: About,
  },
  {
    icon: <img src={board} className={styles.tabBoardIcon}/>,
    title: <strong className={styles.tabTitle}>Board of Directors</strong>,
    component: Board,
  },
  {
    icon: <img src={organo} className={styles.tabOrganoIcon}/>,
    title: <strong className={styles.tabTitle}>Organizational Chart</strong>,
    component: Org,
  },
  {
    icon: <img src={service} className={styles.tabServiceIcon}/>,
    title: <strong className={styles.tabTitle}>Services & Capabilities</strong>,
    component: Services,
  },
  // {
  //   icon: <img src={service} className={styles.tabServiceIcon}/>,
  //   title: <strong className={styles.tabTitle}>Manar</strong>,
  //   component: Services,
  // },
];
