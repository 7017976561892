import React, { createContext, useContext, useEffect, useState } from 'react';
import http from '../http';
import axios from 'axios';
const EmailContext = createContext();

export const useEmailContext = () => {
    return useContext(EmailContext);
};

export const EmailProvider = ({children}) => {
    const PostContactForm = async (data) =>{
            await http.post("/api/contact-forms",data);
    }

    async function FileUpload(file){
        const resp = await http.post("/api/upload", file).then(response => response.data[0].id);
        return resp;
        // await http.post("/api/upload", file)
        // .then((response) => {
        //     console.log(response.data[0])
        //     return response.data[0].id;
        // })
        // .catch((error) => {
        //     console.log(error);
        // })
    }
    
    const value = {
        PostContactForm,
        FileUpload,
    };
    
    // context provider
    return(
        <EmailContext.Provider value={value}>
            {children}
        </EmailContext.Provider>
    )
}; 