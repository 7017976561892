import React, { useState, useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './styles.css';
import first from './../../assets/img/first.jpg';
import second from './../../assets/img/second.jpeg';
import third from './../../assets/img/third.jpg';
import donut from './../../assets/img/Ellipse143.png';
import dot from './../../assets/img/Ellipse145.png';
import styles from './Home.module.scss';
import { useArticleContext } from 'contexts/ArticleContext';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from 'utils/constants';

export default function ImageCarousel() {
  const { t, i18n } = useTranslation();
  const { GetLatestPage, latestArticles } = useArticleContext();
  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }
  GetLatestPage(localStore);
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);
  const [count, setCount] = useState(0);
  const renderDotsItem = ({ isActive }) => {
    return isActive ? (
      <span>
        <img src={dot} />
      </span>
    ) : (
      <span>
        <img src={donut} />
      </span>
    );
  };
  return (
    <div className={`${styles.imageCarouselWrap}`}>
      <div className="home-image-carousel">
        <div className="container_max">
          <AliceCarousel
            className="carousel"
            infinite
            renderDotsItem={renderDotsItem}
            onSlideChanged={() => {
              setCount((count + 1) % 3);
            }}
            animationType="fadeout"
            // autoPlayDirection={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            disableButtonsControls
            autoPlay
            autoPlayInterval={5000}
            animationDuration={1000}>
            {latestArticles &&
              latestArticles
                .sort(
                  (a, b) =>
                    new Date(b?.attributes?.PublishDate || -1) -
                    new Date(a.attributes?.PublishDate || -1),
                )
                .slice(0, 6)
                .map((item, i) => {
                  return (
                    <div key={i} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                      <h2 className={styles.homeImageTitle}>
                        {item.attributes.Title}
                      </h2>
                      <div className={styles.customFlex}>
                        {item.attributes.Images != null &&
                          item.attributes.Images.data != null && (
                            <img
                            
                              className="sliderNewsImage"
                              alt=""
                              src={
                                BASE_URL +
                                (item?.attributes?.CardImage?.data?.attributes
                                  ?.url
                                  ? item?.attributes?.CardImage?.data
                                      ?.attributes?.url
                                  : item.attributes.Images.data[0].attributes
                                      .url)
                              }
                            />
                          )}
                        <div className={styles.sliderInfoNews}>
                          <p>{item.attributes.Summary}</p>
                          <a href={'/article?a=' + item.attributes.Slug}>
                            {t('Read More')}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </AliceCarousel>
        </div>
      </div>
    </div>
  );
}
