import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { ArticleProvider } from './contexts/ArticleContext';
import { VideoProvider } from './contexts/VideoContext';
import { PortProvider } from './contexts/PortContext';
import { LibraryProvider } from './contexts/LibraryContext';
import { AboutProvider } from './contexts/AboutContext';
import { ServiceProvider } from './contexts/eServiceContext';
import { FacilityProvider } from 'contexts/FacilityContext';
import { EmailProvider } from 'contexts/EmailContext';
import { ExternalProvider } from 'contexts/ExternalContext';
import { PolicyProvider } from 'contexts/PolicyContext';
import './i18n';
import { NewsletterProvider } from 'contexts/NewsletterContext';

ReactDOM.render(
  <React.StrictMode>
    <PolicyProvider>
      <ArticleProvider>
        <NewsletterProvider>
          <VideoProvider>
            <PortProvider>
              <LibraryProvider>
                <AboutProvider>
                  <ServiceProvider>
                    <FacilityProvider>
                      <EmailProvider>
                        <ExternalProvider>
                          <BrowserRouter>
                            <App />
                          </BrowserRouter>
                        </ExternalProvider>
                      </EmailProvider>
                    </FacilityProvider>
                  </ServiceProvider>
                </AboutProvider>
              </LibraryProvider>
            </PortProvider>
          </VideoProvider>
        </NewsletterProvider>
      </ArticleProvider>
    </PolicyProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
