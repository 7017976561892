export const sortBiddings = (biddings) =>{
    const nullDateBiddings = biddings?.filter(item => item.compFinalDateAr === null || item.compFinalDateAr?.trim()==="");
    const sortedBiddings = biddings?.filter(item => item.compFinalDateAr !== null && item.compFinalDateAr?.trim() !== "" );
    
    if(Array.isArray(sortedBiddings)){
        sortedBiddings?.sort(function (b1, b2) {
        if (new Date(b1.compFinalDateAr) > new Date(b2.compFinalDateAr)) {
            return -1;
        }
        if (new Date(b1.compFinalDateAr) < new Date(b2.compFinalDateAr)) {
            return 1;
        }
        return 0;
        });
    }
    
    return sortedBiddings?.concat(nullDateBiddings);
}