import { useEffect } from 'react';
import { Remarks } from './Remarks';

export const Chat = () => {
  const locale = localStorage.getItem('locale');
  useEffect(() => {
    const src =
      locale === 'en'
        ? 'https://chat.uniithra.com.sa/MAWANI/Generate/chatBot-en.js'
        : 'https://chat.uniithra.com.sa/MAWANI/Generate/chatBot.js';
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [document, locale]);

  return <Remarks />;
};
