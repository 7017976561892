export const navbarRoutes = [
  {
    title: 'ABOUT US',
    link: '/about-us',
    target: '_self',
  },
  // {
  //   title: 'Sea Services',
  //   link: '/sea-services',
  // },
  {
    title: 'Media Center',
    items: [
      { link: '/media-center?o=News', title: 'News', target: '_self' },
      { link: '/media-center?o=Video', title: 'Videos', target: '_self' },
      { link: '/media-center?o=Photos', title: 'PICTURES', target: '_self' },
      {
        link: '/media-center?o=Mawani-Newsletter',
        title: 'MAWANI NEWSLETTER',
        target: '_self',
      },
      {
        link: '/media-center?o=library',
        title: 'DIGITAL LIBRARY',
        target: '_self',
      },
    ],
  },
  {
    title: 'PORTS',
    link: '/ports',
    target: '_self',
  },
  // {
  //   title: 'DOING BUSINESS',
  //   link: '/doing-business',
  // },
  // {
  //   title: 'SAFETY & SECURITY',
  //   link: '/safety-security',
  // },
  {
    title: 'E-SERVICES',
    link: '/e-services',
    target: '_self',
  },
  {
    title: 'Regulations & Circulars',
    items: [
      { link: '/regulations', title: 'REGULATIONS', target: '_self' },
      { link: '/circulars', title: 'Circulars', target: '_self' },
    ],
  },
  // {
  //   title: 'Services & Capabilities',
  //   link: '/services-and-capabilities',
  // },
  // {
  //   title: 'TOOLS',
  //   link: '/tools',
  // },
  {
    title: 'Jobs',
    link: 'https://careers.mawani.gov.sa/',
    target: '_self',
  },
  {
    title: 'Ports Employees',
    link: '/port-employees',
    target: '_self',
  },
  {
    title: 'Contact',
    link: '/contact',
    target: '_self',
  },
  {
    title: 'Announcement',
    link: '/announcement',
    target: '_self',
  },
];

export const BASE_URL = process.env.REACT_APP_STRAPI_ASSETS_URL;

export const MAX_FILE_UPLOAD_SIZE = 5 * 1024 * 1024;
export const DEFAULT_LANGUAGE = 'ar';

export const GA_TAG = process.env.REACT_APP_GA_TAG;