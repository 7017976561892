import React, { createContext, useContext, useEffect, useState } from 'react';
import http from '../http';
import axios, { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANGUAGE } from 'utils/constants';
import { StrapiMetaPaginationType } from 'types';
import { SingleNewsletterType } from '../components/MediaCenter/Newsletters/newsletters.types';


// @ts-ignore
const NewsletterContext = createContext<{
  newsletters: SingleNewsletterType[];
  GetAllNewsletters: (locale: string) => void
}>();

export const useNewsletterContext = () => {
  return useContext(NewsletterContext);
};

export const NewsletterProvider = ({ children }: { children: React.ReactNode }) => {
  const [newsletters, setNewsletters] = useState<SingleNewsletterType[]>([]);
  const { i18n } = useTranslation()

  const GetAllNewsletters = (locale?: string) => {
    http
      .get(
        `/api/newsletters?populate=*&locale=${locale || i18n.language}`

      )
      .then((response: AxiosResponse<{
        data: SingleNewsletterType[];
        meta: StrapiMetaPaginationType;
      }>) => {
        setNewsletters(response.data.data);
      });
  };

  useEffect(() => {
    GetAllNewsletters(i18n.language || DEFAULT_LANGUAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const value = {
    newsletters,
    GetAllNewsletters,
  };

  // context provider
  return (
    <NewsletterContext.Provider value={value}>
      {children}
    </NewsletterContext.Provider>
  );
};
