import React, { createContext, useContext, useEffect, useState } from 'react';
import http from '../http';
import axios from 'axios';
const ArticleContext = createContext();

export const useArticleContext = () => {
  return useContext(ArticleContext);
};

export const ArticleProvider = ({ children }) => {
  const [articles, setArticles] = useState('');
  const [catArticles, setCatArticles] = useState('');
  const [allArticleCategories, setAllArticleCategories] = useState('');
  const [historyArticles, setHistoryArticles] = useState('');
  const [latestArticles, setLatestArticles] = useState('');
  const [pressArticles, setPressArticles] = useState('');
  const [slugArticle, setSlugArticle] = useState('');
  const [nav_value, set_nav_value] = useState('ArticleList');
  const [articleId, setArticleId] = useState('');
  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }
  // change navigation value
  const changeNavValue = (value) => {
    set_nav_value(value);
  };
  // get article id value
  const getArticleId = (id) => {
    setArticleId(id);
  };

  const GetByCategory = (value, locale) => {
    http
      .get(
        '/api/articles?pagination[limit]=-1&populate=*&filters[Category][Name][$eqi]=' +
          value +
          '&locale=' +
          locale,
      )
      .then((response) => {
        setCatArticles(response.data.data);
      });
  };
  const GetAllArticleCategories = () => {
    http.get('/api/categories').then((response) => {
      setAllArticleCategories(response.data.data);
    });
  };

  const GetByCategoryPage = (value, locale) => {
    useEffect(() => {
      http
        .get(
          '/api/articles?pagination[limit]=-1&populate=*&filters[Category][Name][$contains]=' +
            value +
            '&locale=' +
            locale,
        )
        .then((response) => {
          setCatArticles(response.data.data);
        });
    }, []);
  };
  const GetHistory = (locale) => {
    http
      .get(
        '/api/articles?pagination[limit]=-1&populate=*&filters[Category][Name][$eqi]=Mawani-Newsletter&locale=' +
          locale,
      )
      .then((response) => {
        setHistoryArticles(response.data.data);
      });
  };
  const GetLatest = (locale) => {
    http
      .get(
        '/api/articles?sort=PublishDate:desc&pagination[limit]=-1&populate=*&filters[Category][Name][$eqi]=News&locale=' +
          locale,
      )
      .then((response) => {
        setLatestArticles(response.data.data);
      });
  };
  const GetPress = (locale) => {
    http
      .get(
        '/api/articles?pagination[limit]=-1&populate=*&filters[Category][Name][$contains]=Photos&locale=' +
          locale,
      )
      .then((response) => {
        setPressArticles(response.data.data);
      });
  };
  const GetHistoryPage = (locale) => {
    useEffect(() => {
      http
        .get(
          '/api/articles?pagination[limit]=-1&populate=*&filters[Category][Name][$eqi]=Mawani-Newsletter&locale=' +
            locale,
        )
        .then((response) => {
          setHistoryArticles(response.data.data);
        });
    }, []);
  };
  const GetLatestPage = (locale) => {
    useEffect(() => {
      http
        .get(
          '/api/articles?sort=PublishDate:desc&pagination[limit]=6&populate=*&filters[Category][Name][$eqi]=News&locale=' +
            locale+'',
        )
        .then((response) => {
          setLatestArticles(response.data.data);
        });
    }, []);
  };
  const GetPressPage = (locale) => {
    useEffect(() => {
      http
        .get(
          '/api/articles?pagination[limit]=-1&populate=*&filters[Category][Name][$contains]=Photos&locale=' +
            locale,
        )
        .then((response) => {
          setPressArticles(response.data.data);
        });
    }, []);
  };
  const GetBySlug = (value, locale) => {
    http
      .get(
        '/api/articles?populate=*&filters[Slug][$eqi]=' +
          value +
          '&locale=' +
          locale,
      )
      .then((response) => {
        setSlugArticle(response.data.data);
      });
  };
  const GetAll = () => {
    http.get('/api/articles?locale=' + localStore).then((response) => {
      setArticles(response.data.data);
    });
  };

  useEffect(() => {
    http.get('/api/articles?locale=' + localStore).then((response) => {
      setArticles(response.data.data);
    });
  }, []);

  const value = {
    articles,
    GetAll,
    changeNavValue,
    nav_value,
    getArticleId,
    articleId,
    catArticles,
    GetByCategory,
    allArticleCategories,
    GetAllArticleCategories,
    GetBySlug,
    slugArticle,
    latestArticles,
    historyArticles,
    pressArticles,
    GetLatest,
    GetHistory,
    GetPress,
    GetLatestPage,
    GetHistoryPage,
    GetPressPage,
    GetByCategoryPage,
  };

  // context provider
  return (
    <ArticleContext.Provider value={value}>{children}</ArticleContext.Provider>
  );
};
