import styles from './EServices.module.scss';
import Enq from '../../assets/pdf/Enquiries.pdf';
import { useServiceContext } from 'contexts/eServiceContext';
import { useTranslation } from 'react-i18next';

export const Biddings2 = () => {
  const { qualificationBindings } = useServiceContext();
  const { t } = useTranslation();

  return (
    <>
      <h2>
        {t(
          'Qualification competition for maritime services contracts for ports',
        )}
      </h2>

      <p>
        {t(
          'Schedule for the competition for qualifying maritime services contracts for ports',
        )}
      </p>
      <table className={`${styles.tablePlain}`}>
        <tbody>
          {qualificationBindings &&
            qualificationBindings.map((item) => {
              return (
                <tr>
                  <td>{item.attributes.Label}</td>
                  <td>{item.attributes.Date}</td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.attributes.Content,
                      }}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <strong>
        {t("* List of Enquiries Received and Mawani's Response ,")}
        <a
          href={Enq}
          download
          className="font-normal underline text-same text-[#2D6A80]">
          {t('click here')}
        </a>
      </strong>
    </>
  );
};
