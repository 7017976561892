import { AxiosResponse } from 'axios';
import http from '../../../http';
import { Announcement } from './Announcement.types';

export const GetAnnouncements = (
  locale: string
) =>
  http
    .get(
      `/api/announcements?populate=*&locale=${locale}`,
    )
    .then(
      (
        response: AxiosResponse<{
          data: Announcement[];
        }>,
      ) => response.data,
    );
