import React, { useEffect } from 'react';
import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import Header from 'components/home/Header';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import { usePolicyContext } from 'contexts/PolicyContext';
import { useTranslation } from 'react-i18next';
import styles from "./Policy.module.scss";

function PolicyBlock() {
  const { policy } = usePolicyContext();
  const { t } = useTranslation();
  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }
  return (
    <div style={{}} className="container_max">
      <h2 className={{ fontWeight: 'bold', marginTop: '3rem' }}>
        {t('Privacy Policy')}
      </h2>
      <div
        dangerouslySetInnerHTML={{
          __html: policy?.attributes?.Content,
        }}
        className={styles.description}
      />
    </div>
  );
}

export default function Policy() {
  return (
    <>
      <Navbar />
      <PolicyBlock />
      <Information />
      <Subscribe />
      <DefaultFooter />
    </>
  );
}
