import React, { createContext, useContext, useEffect, useState } from 'react';
import http from '../http';
import axios from 'axios';
const FacilityContext = createContext();

export const useFacilityContext = () => {
    return useContext(FacilityContext);
};

export const FacilityProvider = ({children}) => {
    const [facilities, setFacilities] = useState("");
    var localStore = localStorage.getItem('locale');
    if(localStore == null || localStore == undefined){
        localStore = "ar";
    }
    const getFacility = (locale) =>{
        http.get("/api/facility-rentals?populate=*&locale="+locale)
        .then((response) => {
            setFacilities(response.data.data);
        })
    }
    useEffect(()=>{
        http.get("/api/facility-rentals?populate=*&locale="+localStore)
        .then((response) => {
            setFacilities(response.data.data);
        })
    }, []);

    const value = {
        facilities,
        getFacility
    };
    
    // context provider
    return(
        <FacilityContext.Provider value={value}>
            {children}
        </FacilityContext.Provider>
    )
}; 