import pdfIcon from "../../assets/img/pdfIcon.png";
import styles from "./Regulations.module.scss";

export default function RegulationsPdfSection({ heading,source }) {

  return (
      <div className={styles.squareWrap} onClick={()=> window.open(source, "_blank")}>
        <div className={styles.square}>
          <img src={pdfIcon} />
        </div>
       
          <p className={styles.pdfFileNames}>
            {heading}
          </p>
        
      </div>
  );
}
