import styles from './MediaCenter.module.scss';
import { useLibraryContext } from 'contexts/LibraryContext';
import React, { useState, Component } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const InfoBox = ({ title, description, pdf }) => {
  const { t } = useTranslation();

  return (
    <div
      className="px-6 pb-6 pt-1 rounded-xl text-white shadow-md flex flex-col justify-start"
      style={{ backgroundColor: '#419BB5' }}>
      <h3 className="text-2xl text-start">{title}</h3>
      <p
        className={clsx('mb-4', styles.libraryDescription)}
        style={{ textAlign: 'justify' }}>
        {description}
      </p>
      <div className="flex  text-start">
        {pdf && (
          <a
            href={pdf?.data?.attributes?.url || 'javascript:void(0)'}
            download="file_name"
            className="rounded-sm px-3 py-1"
            style={{ backgroundColor: '#006782' }}>
            {t('Download PDF')}
          </a>
        )}
      </div>
    </div>
  );
};

const Library = ({ limit = null }) => {
  const { t } = useTranslation();
  const { libraries, loadingCirculars } = useLibraryContext();
  const [state, setState] = useState({});
  return (
    <div>
      <div className={`${styles.mediaCenterGrid}`}>
        {loadingCirculars && <p>{t('Loading')}...</p>}
        {libraries &&
          !loadingCirculars &&
          libraries
            ?.sort(
              (a, b) =>
                new Date(b?.attributes?.PublishDate || -1) -
                new Date(a.attributes?.PublishDate || -1),
            )
            ?.slice(0, limit || libraries.length)
            .map(({ id, attributes: { Title, MediaFile, Description } }, i) => (
              <InfoBox
                key={Title}
                title={Title}
                description={Description}
                pdf={MediaFile}
              />
            ))}
      </div>
      <div
        id="myModal"
        tabIndex="-1"
        style={{ display: state.showModal ? 'block' : 'none' }}
        aria-hidden="true"
        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
        <div
          className={`${styles.libraryModal} relative p-4 w-full max-w-2xl h-full md:h-auto`}>
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <button
                onClick={() => setState({ showModal: false })}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="defaultModal">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
                </svg>
                <span className="sr-only">{t('Close modal')}</span>
              </button>
            </div>
            <div className="p-6 space-y-6">
              <img src={state.modalSrc} caption={state.caption} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Library;
